enum Role {
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER',
  MERCHANT = 'MERCHANT',
  SUPERVISOR_TIER_001 = 'SUPERVISOR_TIER_001',
  SUPERVISOR_TIER_002 = 'SUPERVISOR_TIER_002',
  SUPERVISOR_TIER_003 = 'SUPERVISOR_TIER_003'
}

export default Role;
