import React, { FC } from 'react';
import { makeStyles, Table, TableBody, TableHead, TableRow, TableCell, Paper, TableContainer, Typography, Divider } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { allTaskOneDriverType, formatCurrency } from 'utils';
import TableLoading from 'components/Table/TableLoading';
import TruckType from 'typings/enum/TruckType';

interface Props {
  job: JobDetail;
  isLoadingData: boolean;
}

const useStyles = makeStyles({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  }
});

const AdditionalTable: FC<Props> = ({ job, isLoadingData }) => {
  const classes = useStyles();
  const isDriverGst = job.JobTasks[0]?.isDriverGst;
  const gstItemPrice = (job.JobAdditionalItems && job.JobAdditionalItems[0]?.gstPercent) || 0;

  return allTaskOneDriverType.includes(job.truckType as TruckType) ? (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Additional Items
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <HeaderRow
              height={'0px'}
              headers={[
                { id: 'id', label: 'ID', pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap', width: '10%' } } },
                { id: 'name', label: 'Item Name', cellProps: { style: { whiteSpace: 'nowrap' } } },
                { id: 'description', label: 'Description' },
                {
                  id: 'qty',
                  label: 'QTY',
                  align: 'center',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                },
                {
                  id: 'price',
                  label: 'Unit Price',
                  align: 'right',
                  cellProps: { padding: 'checkbox', style: {  width: '10%', whiteSpace: 'nowrap' } }
                },
                {
                  id: 'totalPrice',
                  label: 'Total Price',
                  align: 'right',
                  cellProps: { padding: 'checkbox', style: { width: '10%', whiteSpace: 'nowrap' } }
                },
                {
                  id: 'emptyCell',
                  label: '',
                  cellProps: { padding: 'checkbox', style: { width: '5%', whiteSpace: 'nowrap' } }
                }
              ]}
            />
          </TableHead>

          <TableBody>
            {isLoadingData ? (
              <TableLoading col={7} />
            ) : job.JobAdditionalItems && job.JobAdditionalItems.length > 0 ? (
              job.JobAdditionalItems.map((value, index) => <BodyRow key={index} items={value} />).concat(
                <TableRow key={(job.JobAdditionalItems?.length || 0) + 1}>
                  <TableCell align='right' colSpan={5} style={{ paddingRight: 0 }}>
                    Total Additional Item {isDriverGst ? `+ GST ${gstItemPrice}%` : ''}
                  </TableCell>
                  <TableCell align='right' style={{ paddingRight: 0 }}>
                    <strong> {formatCurrency(job.JobAdditional?.totalItemPrice || 0)}</strong>
                  </TableCell>
                  <TableCell align='left'>
                    {isDriverGst ? (
                      <>
                        <strong>(+{formatCurrency(job.JobAdditional?.gstItemPrice || 0)})</strong>
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  Data Not Available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : null;
};

export default AdditionalTable;
