import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';

import TrxStatus from 'typings/enum/TransactionStatus';
import { ucwords } from 'utils';

interface Props {
  status: TrxStatus;
  label: string;
  minWidth?: string;
}

const useStyles = makeStyles({
  chip: {
    minWidth: (props: Props) => props.minWidth ?? '100px',
    color: '#FFFFFF'
  },
  settled: {
    color: '#4CAF50',
    backgroundColor: '#E7FBDE',
    borderRadius: '30px',
    border: '1px solid #4CAF50'
    
  },
  pending: {
    color: '#F6891F',
    backgroundColor: '#FEF1D2',
    borderRadius: '30px',
    border: '1px solid #F6891F'
  },
  expired: {
    backgroundColor: '#F4F4F4',
    color: '#848484',
    borderRadius: '30px',
    border: '1px solid #848484'
  },
  cancel: {
    backgroundColor: '#FEE9DE',
    color: '#EB5757',
    borderRadius: '30px',
    border: '1px solid #EB5757'
  },
  font: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.4px'
  },
  label: {
    padding: '5px 15px 5px 15px',
  }
});

export default function StatusChip(props: Props) {
  const classes = useStyles(props);
  const { status, label } = props;
  const chipColor = {
    [classes.settled]: status === TrxStatus.SETTLED,
    [classes.pending]: status === TrxStatus.PENDING,
    [classes.expired]: status === TrxStatus.EXPIRED,
    [classes.cancel]: status as string === "CANCELLED",
  }
  return (
    <Chip
      label={ucwords(label)}
      classes={{label: classes.label}}
      variant="outlined"
      className={clsx(classes.chip, chipColor, classes.font)}
    />
  )
}
