import React, { FC } from 'react';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { makeStyles, TableRow, Theme, Typography, Chip, Link, IconButton, TableCell, Menu, MenuItem } from '@material-ui/core';
import { snackCaseToSentence } from 'utils';
import JobPayment from 'components/JobPayment';
import { MoreVert } from '@material-ui/icons';

interface Props extends InvoiceList {
  onOpenInvoice: () => void;
  onOpenDo: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  chipType: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: theme.palette.black.A100,
    backgroundColor: '#F4F4F4',
    border: '1px solid #333333',
    borderRadius: '30px'
  },
  label: {
    padding: '4px 15px 4px 15px'
  },
  idLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  subTitle: {
    color: theme.palette.subtitle.main,
    marginBottom: 10,
    whiteSpace: 'nowrap',
    ...theme.typography.body2
  }
}));

const BodyRow: FC<Props> = ({ onOpenInvoice, onOpenDo, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (evt: () => void) => () => {
    setAnchorEl(null);
    evt();
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='h6'>{props.invoiceNo}</Typography>
          <Link component={RouterLink} to={`/job/${props.JobId}`} target='_blank'>
            #{String(props.JobId).padStart(3, '0')}
          </Link>
        </TableCell>

        <TableCell> {format(new Date(props.invoiceDate), 'dd MMMM yyyy')}</TableCell>

        <TableCell>
          <Typography variant='h6'>{props.companyName}</Typography>
          <Typography variant='h6' className={classes.subTitle}>
            {props.displayName}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='h6'>{props.fromCompanyName || props.toCompanyName || '-'}</Typography>
          <Typography variant='h6' className={classes.subTitle}>
            {props.fromUserName || props.toUserName || '-'}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='h6'>{props.address}</Typography>
        </TableCell>

        <TableCell align='right'>
          <NumberFormat
            value={props.totalAmount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </TableCell>
        <TableCell align='center'>{props.paymentMethod ? <JobPayment type={props.paymentMethod} /> : '-'}</TableCell>
        <TableCell align='center'>
          <Chip variant='outlined' classes={{ label: classes.label }} className={classes.chipType} label={snackCaseToSentence(props.type)} />
        </TableCell>

        <TableCell align='center'>
          <div>
            <IconButton color='primary' aria-controls='invoice-menu' aria-haspopup='true' onClick={handleClick}>
              <MoreVert />
            </IconButton>

            <Menu
              id='invoice-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <MenuItem onClick={handleClose(onOpenInvoice)}>View Invoice</MenuItem>
              <MenuItem onClick={handleClose(onOpenDo)}>View DO</MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BodyRow;
