import React, { FC } from 'react';
import { formatCurrency } from 'utils';
import { Theme, makeStyles, Table, TableBody, TableHead, TableRow, TableCell, Paper, TableContainer, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import TruckType from 'typings/enum/TruckType';

interface Props {
  job: JobDetail;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  },
  subtotal: {
    paddingRight: 0,
    width: '10%',
    whiteSpace: 'nowrap'
  },
  unitPrice: {
    width: '20%'
  },
  qty: {
    width: '15%',
    paddingRight: 0
  },
  disabledBorderBottom: {
    borderBottom: 'none'
  },
  orangeTitle: {
    color: theme.palette.primary.main
  }
}));

const PriceTable: FC<Props> = ({ job }) => {
  const classes = useStyles();
  const priceDetails = [];
  const hasItems = job.JobAdditional?.totalItemPrice || 0;

  priceDetails.push({
    type: 'data',
    qty: job.totalTask,
    subtotal: formatCurrency(job.DriverSummary?.totalOriginalTask || 0),
    price: `${formatCurrency(job.DriverSummary?.originalTask || 0)}`,
    totalGst: `(${formatCurrency(job.DriverSummary?.totalOriginalGst || 0)})`,
    description: `Total Task`
  });

  if (!!job.JobAdditionalHours?.length) {
    job.JobAdditionalHours.forEach(value => {
      priceDetails.push({
        type: 'data',
        qty: `${value.hours / 60} hour(s)`,
        subtotal: formatCurrency(value.totalPrice),
        price: `${formatCurrency(value.hourPrice)}`,
        totalGst: `(${formatCurrency(value.gstTotalPrice)})`,
        description: value.hours > 0 ? [TruckType.LORRY_CRANE, TruckType.TRAILER].includes(job.truckType as TruckType) ? 'Overtime(s)' : 'Add Hour(s)' : 'Reduce Hour(s)'

      });
    });
  }

  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Price Detail
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component='th'>Description</TableCell>
              <TableCell component='th' align='right' className={classes.unitPrice}>
                Unit Price
              </TableCell>
              <TableCell component='th' align='right' className={classes.qty}>
                QTY
              </TableCell>
              <TableCell component='th' align='right' className={classes.subtotal}>
                Subtotal
              </TableCell>
              <TableCell component='th' style={{ maxWidth: '2%', width: '5%' }}>
                GST {job.gstPercent || 0}%
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {priceDetails.map((v, i) =>
              v.type === 'title' ? (
                <TableRow key={i + '-key'}>
                  <TableCell size='small' colSpan={5} style={{ borderBottom: 0 }}>
                    {v.description}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={i + '-key'}>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                  >
                    {v.description}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                    align='right'
                  >
                    {v.price}
                  </TableCell>

                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: 0
                    }}
                    align='right'
                  >
                    {v.qty}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                    align='right'
                    className={classes.subtotal}
                  >
                    {v.subtotal}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                  >
                    {v.totalGst}
                  </TableCell>
                </TableRow>
              )
            )}

            {/* <TableRow>
              <TableCell className={clsx(classes.disabledBorderBottom)}>&nbsp;</TableCell>
              <TableCell colSpan={2} size='small' align='right' style={{ paddingRight: 0 }}>
                Subtotal
              </TableCell>
              <TableCell align='right' size='small' className={clsx(classes.orangeTitle, classes.subtotal)}>
                <strong>{formatCurrency((job.DriverSummary?.totalTaskEarn || 0) - hasItems)}</strong>
              </TableCell>
              <TableCell align='left' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.orangeTitle)}>
                <strong>({formatCurrency((job.DriverSummary?.totalGstTaskEarn || 0) - (job.JobAdditional?.gstItemPrice || 0))})</strong>
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell className={clsx(classes.disabledBorderBottom)}>&nbsp;</TableCell>
              <TableCell colSpan={2} align='right' style={{ paddingRight: 0 }}>
                Platform Fee ({job.DriverSummary?.percentAppFee || 0})%
              </TableCell>
              <TableCell align='right' size='small' className={clsx(classes.subtotal)} style={{ color: 'red' }}>
                <strong>-{formatCurrency(job.DriverSummary?.totalAppFee || 0)}</strong>
              </TableCell>
              <TableCell align='left' size='small' style={{ whiteSpace: 'nowrap' }}></TableCell>
            </TableRow>

            {!!hasItems ? (
              <TableRow>
                <TableCell className={clsx(classes.disabledBorderBottom)}>&nbsp;</TableCell>
                <TableCell colSpan={2} size='small' align='right' style={{ paddingRight: 0 }}>
                  Total Additional Items
                </TableCell>
                <TableCell align='right' size='small' className={clsx(classes.orangeTitle, classes.subtotal)}>
                  <strong>{formatCurrency(job.JobAdditional?.totalItemPrice || 0)}</strong>
                </TableCell>
                <TableCell align='left' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.orangeTitle)}>
                  <strong>({formatCurrency(job.JobAdditional?.gstItemPrice || 0)})</strong>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell className={clsx(classes.disabledBorderBottom)}>&nbsp;</TableCell>
              <TableCell colSpan={2} size='small' align='right' className={clsx(classes.disabledBorderBottom)} style={{ paddingRight: 0 }}>
                Net Earn
              </TableCell>
              <TableCell
                align='right'
                size='small'
                className={clsx(classes.disabledBorderBottom, { [classes.orangeTitle]: job.truckType !== TruckType.LORRY_CRANE }, classes.subtotal)}
              >
                <strong>{formatCurrency(job.DriverSummary?.totalEarn || 0)}</strong>
              </TableCell>
              <TableCell align='left' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.orangeTitle)}>
                <strong>({formatCurrency(job.DriverSummary?.totalGstTaskEarn || 0)})</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PriceTable;
