import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField
} from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import DangerIcon from '@material-ui/icons/DeleteForever';
import ErrorIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import { orange } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

interface Props {
  open: boolean;
  variant: string;
  message: string;
  titleMessage?: string;
  loading?: boolean;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  noCancelButton?: boolean;
  confirmButtonText?: string;
  useRemarks?: boolean;
  remarksPromp?: string;
  remarksPrompLabel?: string;
  handleRemarksPromp?: React.Dispatch<React.SetStateAction<string>>; 
  handleClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  successAvatarIcon: {
    fontSize: 60,
    color: green[500]
  },
  warnigAvatarIcon: {
    fontSize: 60,
    color: orange[500]
  },
  errorAvatarIcon: {
    fontSize: 60,
    color: red[500]
  },
  dangerAvatarIcon: {
    fontSize: 60,
    color: '#FF0909'
  },
  cancelButton: (props: Props) => ({
    marginRight: theme.spacing(props.noCancelButton ? 0 : 3)
  }),
  okButton: {},
  dialogActions: {
    marginBottom: theme.spacing(2)
  },
  titleText: {
    paddingBottom: 4
  },
  messageText: {
    paddingBottom: theme.spacing(4)
  },
  messageRemarksText: {
    paddingBottom: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export const StandardConfirmationDialog: FC<Props> = props => {
  const classes = useStyles(props);
  const { variant, titleMessage, message, open, loading, handleClose, onConfirm, noCancelButton, confirmButtonText } = props;

  const [remarks, setRemarks] = useState<string>(props.remarksPromp ?? '');
  useEffect(() => {
    if (!props.useRemarks) {
      return;
    }

    if (props.useRemarks && props.handleRemarksPromp) {
      props.handleRemarksPromp(remarks);
    }
  }, [remarks, props])

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container justify='center' alignItems='center'>
          {variant === 'success' && <SuccessIcon className={classes.successAvatarIcon} />}
          {variant === 'warning' && <WarningIcon className={classes.warnigAvatarIcon} />}
          {variant === 'danger' && <DangerIcon className={classes.dangerAvatarIcon} />}
          {variant === 'error' && <ErrorIcon className={classes.errorAvatarIcon} />}
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.titleText}>
        <Typography variant='h3' align={'center'}>
          {titleMessage}
        </Typography>
      </DialogContent>
      <DialogContent className={classes.messageText}>
        <Typography variant='h6' align={'center'}>
          {message}
        </Typography>
        {props.useRemarks ? (
          <>
            <DialogContentText className={classes.messageRemarksText}>
              <Typography variant='body1' align={'center'}>
                {props.remarksPrompLabel}
              </Typography>
            </DialogContentText>
            <TextField
              autoFocus
              multiline
              fullWidth
              variant='outlined'
              margin='dense'
              type='text'
              id='remarks'
              label='Remarks'
              rows={4}
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
            />
          </>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container justify='center' alignItems='center'>
          <Button
            disabled={loading}
            variant={noCancelButton ? 'contained' : 'outlined'}
            color='primary'
            className={classes.cancelButton}
            onClick={handleClose}
            disableElevation
          >
            {noCancelButton ? 'Ok' : 'Cancel'}
          </Button>
          {!noCancelButton && (
            <div className={classes.wrapper}>
              <Button variant='contained' color='primary' disableElevation disabled={loading} className={classes.okButton} onClick={onConfirm}>
                {confirmButtonText ? confirmButtonText : 'Ok'}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
