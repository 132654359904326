import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, InputAdornment, IconButton, TableContainer } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';

import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import TruckType from 'typings/enum/TruckType';
import { getGroupJobTypeSelect, jobTypeLabel, truckTypeLabel } from 'utils';
import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';

interface Props extends UseTablePagination<JobList> {
  dateRange: string;
  completeDateRange: string;
  handleCalendar: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCompleteCalendar: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const truckTypes: Select[] = [
  { id: TruckType.TIPPER, name: truckTypeLabel(TruckType.TIPPER) },
  { id: TruckType.TMA, name: truckTypeLabel(TruckType.TMA) },
  { id: TruckType.LORRY_CRANE, name: truckTypeLabel(TruckType.LORRY_CRANE) },
  { id: TruckType.TRAILER, name: truckTypeLabel(TruckType.TRAILER) }
];

const jobStatus: Select[] = [
  { id: JobStatus.DRAFT, name: JobStatus.DRAFT },
  { id: JobStatus.AVAILABLE, name: JobStatus.AVAILABLE },
  { id: JobStatus.ASSIGNED, name: JobStatus.ASSIGNED },
  { id: JobStatus.IN_PROGRESS, name: JobStatus.IN_PROGRESS },
  { id: JobStatus.COMPLETED, name: JobStatus.COMPLETED },
  { id: JobStatus.CANCELLED, name: JobStatus.CANCELLED },
  { id: JobStatus.EXPIRED, name: JobStatus.EXPIRED }
];

const selectPayment: Select[] = [
  { id: TransactionPaymentMethod.JUSTGO_WALLET, name: 'Justgo Wallet' },
  { id: TransactionPaymentMethod.CREDIT_WALLET, name: 'Credit' }
];

const ow = [JobType.OU_WORKS_DISPOSAL, JobType.OU_WORKS_PURCHASE];

const JobTable: FC<Props> = ({ dateRange, completeDateRange, handleCalendar, handleCompleteCalendar, ...props }) => {
  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={[
              {
                id: 'id',
                label: 'ID',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '105px',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'jobOwner',
                label: 'Job Title/Remark',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap',
                    width: '25%',
                    minWidth: '40%'
                  }
                }
              },

              {
                id: 'jobType',
                label: 'Job Type',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'truckType',
                label: 'Vehicle Type',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'jobTime',
                label: 'Project Date & Time',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'completeDate',
                label: 'Date Completed',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'jobStatus',
                label: 'Job Status',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'price',
                label: 'Total Price',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'paymentMethod',
                label: 'Payment Method',
                sort: true,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findId',
                isInputText: true,
                value: props.query('id'),
                handleInputChange: value => props.setQuery({ id: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findJobOwner',
                isInputText: true,
                value: props.query('jobTitle'),
                handleInputChange: value => props.setQuery({ jobTitle: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findJobType',
                label: 'Select Type',
                value: props.query('jobType') === JobType.OU_WORKS ? `${JobType.OU_WORKS}_${props.query('workType')}` : props.query('jobType'),
                isGroupSelect: true,
                selectOption: getGroupJobTypeSelect(),
                handleOptionChange: (value: any) => {
                  if (ow.includes(value)) {
                    const [_, wt] = value.split('OU_WORKS_');
                    props.setQuery({ jobType: JobType.OU_WORKS, workType: wt });
                  } else {
                    props.setQuery({ jobType: value, workType: null });
                  }
                },
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },

              {
                id: 'findTruckType',
                label: 'Select Type',
                value: props.query('truckType'),
                isSelect: true,
                selectOption: truckTypes,
                handleOptionChange: value => props.setQuery({ truckType: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },
              {
                id: 'findJobTime',
                isInputText: true,
                value: dateRange,
                readOnly: true,
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: { minWidth: '200px' }
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendar}>
                      <DateRangeIcon fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>
                )
              },
              {
                id: 'findCompleteDate',
                isInputText: true,
                value: completeDateRange,
                readOnly: true,
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: { minWidth: '200px' }
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle date-time-picker-range' onClick={handleCompleteCalendar}>
                      <DateRangeIcon fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>
                )
              },
              {
                id: 'findJobStatus',
                isSelect: true,
                label: 'Select Status',
                value: props.query('jobStatus'),
                selectOption: jobStatus,
                handleOptionChange: value => props.setQuery({ jobStatus: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },
              {
                id: 'findTotalPrice',
                isInputText: true,
                value: props.query('price'),
                handleInputChange: value => props.setQuery({ price: value }),
                pT: '0px',
                pB: '0px',
                cellProps: {
                  style: { minWidth: '175px' }
                }
              },
              {
                id: 'findPaymentMethod',
                isSelect: true,
                label: 'Select Payment',
                value: props.query('paymentMethod'),
                selectOption: selectPayment,
                handleOptionChange: value => props.setQuery({ paymentMethod: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              }
            ]}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={9} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={9}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((job, index) => <BodyRow key={index} {...job} />)
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default JobTable;
