import React, { FC, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Theme, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, makeStyles } from '@material-ui/core';

import useRouter from 'hooks/useRouter';

interface Props {
  Icon: ComponentType<SvgIconProps>;
  path: string;
  label: string;
  menuActive: string;
  setMenuActive: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  itemIcon: {
    margin: '0px 7px'
  },
  whiteIcon: {
    color: '#FFF'
  }
}));

const DrawerItem: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const { Icon, path, label, menuActive, setMenuActive } = props;

  const onClickHandler: React.MouseEventHandler = event => {
    event.preventDefault();
    setMenuActive(label);
    history.push(path);
  };

  return (
    <Tooltip title={label} placement='right'>
      <ListItem button onClick={onClickHandler}>
        <ListItemIcon>
          <Icon
            className={menuActive === label ? classes.itemIcon : `${classes.itemIcon} ${classes.whiteIcon}`}
            color={menuActive === label ? 'primary' : 'inherit'}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography style={{ fontSize: '14px' }} color={menuActive === label ? 'primary' : 'initial'}>
              {label}
            </Typography>
          }
        />
      </ListItem>
    </Tooltip>
  );
};

export default DrawerItem;
