import { useState, useEffect, useCallback, SetStateAction } from 'react';
import axios, { CancelTokenSource } from 'axios';

function removeEmptyString(obj: any) {
  let newObj: any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      newObj[key] = value;
    }
  }
  return newObj;
}

function useFetch(
  url: string,
  accessor: string,
  filters: any,
  currentPage: number,
  rowsPerPage: number,
  setCount: React.Dispatch<SetStateAction<number>>,
  sortBy: string,
  sortDir: 'asc' | 'desc',
) {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoading(true);

    const params = {
      s: currentPage * rowsPerPage,
      l: rowsPerPage,
      sort: sortBy,
      order: sortDir,
      ...removeEmptyString(filters),
    };

    try {
      const { data: axiosData } = await axios.get(
        url,
        {
          params,
          cancelToken: cancelTokenSource.token
        }
      );
      setData(axiosData[accessor]);
      setCount(axiosData.count);
    } catch (error) {
      console.error('err: ', error);
    } finally {
      setIsLoading(false);
    }
  }, [url, accessor, filters, setCount, currentPage, rowsPerPage, sortBy, sortDir]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
    fetchData,
  };
}

export default useFetch;
