import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { formatCurrency, formatId } from 'utils';
import BodyCell from 'components/BodyCell';

interface Props {
  items: {
    id: number;
    gstPercent: number;
    gstItemPrice: number;
    requestRemark: string;
    price: number;
    totalPrice: number;
    name: string;
    description: string;
    totalItem: number;
  };
}

const BodyRow: FC<Props> = ({ items }) => {
  return (
    <TableRow hover>
      <BodyCell>{formatId(items.id)}</BodyCell>
      <BodyCell>{items.name}</BodyCell>
      <BodyCell>{items.description}</BodyCell>
      <BodyCell align='center'>{items.totalItem}</BodyCell>
      <TableCell align='right' style={{ paddingRight: 0 }}>
        {formatCurrency(items.price)}
      </TableCell>
      <TableCell align='right' style={{ paddingRight: 0 }}>
        {formatCurrency(items.totalPrice)}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default BodyRow;
