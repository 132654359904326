enum TransactionType {
  TOPUP = 'TOPUP',
  WITHDRAW = 'WITHDRAW',
  JOB_CREATED = 'JOB_CREATED',
  JOB_EXPIRED = 'JOB_EXPIRED',
  JOB_CANCELLED = 'JOB_CANCELLED',
  JOB_UNPUBLISHED = 'JOB_UNPUBLISHED',
  JOB_CONFIRM_ADDITIONAL = 'JOB_CONFIRM_ADDITIONAL',
  GST_JOB_CONFIRM_ADDITIONAL = 'GST_JOB_CONFIRM_ADDITIONAL',
  GST_JOB_CREATED = 'GST_JOB_CREATED',
  GST_JOB_EXPIRED = 'GST_JOB_EXPIRED',
  GST_JOB_CANCELLED = 'GST_JOB_CANCELLED',
  GST_JOB_UNPUBLISHED = 'GST_JOB_UNPUBLISHED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_CANCELLED = 'TASK_CANCELLED',
  TASK_EXPIRED = 'TASK_EXPIRED',
  GST_TASK_COMPLETED = 'GST_TASK_COMPLETED',
  GST_TASK_CANCELLED = 'GST_TASK_CANCELLED',
  GST_TASK_EXPIRED = 'GST_TASK_EXPIRED',
  IN_EDIT_TASK_HOUR = 'IN_EDIT_TASK_HOUR',
  OUT_EDIT_TASK_HOUR = 'OUT_EDIT_TASK_HOUR',
  CANCEL_EDIT_TASK_HOUR = 'CANCEL_EDIT_TASK_HOUR',
  REFUND_EDIT_TASK_HOUR = 'REFUND_EDIT_TASK_HOUR',
  REVERT_EDIT_TASK_HOUR = 'REVERT_EDIT_TASK_HOUR',
  PLATFORM_FEE = 'PLATFORM_FEE'
}

export default TransactionType;
