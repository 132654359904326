import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Theme, Typography } from '@material-ui/core';
import { Switch, Route } from 'react-router';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles } from '@material-ui/styles';

import ConditionalRoute from 'components/ConditionalRoute';
import AppHeader from 'components/AppHeader';
import AppDrawer from 'components/AppDrawer';

import LoginPage from 'pages/LoginPage';
import NotFoundPage from 'pages/NotFoundPage';
import ThankYouPage from 'pages/ThankYouPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import AccountVerificationPage from 'pages/AccountVerifcationPage';
import DriverDetailPage from 'pages/DriversPage/DriverDetailPage';
import JobDriverDetailPage from 'pages/JobsPage/JobDriverDetailPage';

import SettingPage from 'pages/SettingPage';
import JobDetailPage from 'pages/JobsPage/JobDetailPage';
import TransactionsPage from 'pages/TransactionsPage';
import InvoicesPage from 'pages/InvoicesPage';
import JobPostedPage from 'pages/JobsPage/JobPostedPage';
import JobDriverPage from 'pages/JobsPage/JobDriverPage';

import { CurrentUserProvider } from 'contexts/CurrentUserContext';
import { isUserAuthenticated } from 'selectors';
import { attachTokenToHeader, detachTokenFromHeader } from 'utils/AxiosUtils';
import { GET_CURRENT_USER_URL } from 'constants/url';
import { CurrentPageProvider } from 'contexts/CurrentPageContext';
import { CurrentJobTabProvider } from 'contexts/CurrentJobTabContext';
import { CurrentTrxTabProvider } from 'contexts/CurrentTrxTabContext';
import { AppProvider } from 'contexts/AppContext';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '95vh',
    overflow: 'auto',
    background: '#F7F9FC'
  },
  contentLogin: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: '#F7F9FC'
  },
  footerPaddingIsLoggedIn: {
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}));

const App: React.FC = () => {
  const classes = useStyles();
  const [currentPageTitle, setCurrentPageTitle] = useState<string>('');
  const [currentJobTab, setCurrentJobTab] = useState<number>(1);
  const [currentTrxTab, setCurrentTrxTab] = useState<number>(0);
  const [CurrentUserData, setCurrentUserData] = useState<CurrentUser>();
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const isLoggedIn = isUserAuthenticated(CurrentUserData);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const setCurrentUser = (currentUser: CurrentUser, token: string): void => {
    localStorage.setItem('token', token);
    attachTokenToHeader(token);

    setCurrentUserData(currentUser);
  };

  const unsetCurrentUser = (): void => {
    localStorage.removeItem('token');
    detachTokenFromHeader();

    setCurrentUserData(undefined);
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const getCurrentUserData = async () => {
      setAuthenticating(true);
      const token = getPersistedToken();
      if (token) {
        try {
          const response = await axios.get(GET_CURRENT_USER_URL, {
            headers: { Authorization: `Bearer ${token}` },
            cancelToken: cancelTokenSource.token
          });
          const currentUser: CurrentUser = response.data;
          setForceUpdate(!!currentUser.isNew);
          setCurrentUser(currentUser, token);
        } catch (err) {
          unsetCurrentUser();
        }
      }
      setAuthenticating(false);
    };
    getCurrentUserData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return isAuthenticating ? null : (
    <div>
      <CurrentUserProvider
        value={{
          currentUser: CurrentUserData,
          setCurrentUser,
          unsetCurrentUser
        }}
      >
        <CurrentPageProvider
          value={{
            currentPageTitle,
            setCurrentPageTitle
          }}
        >
          <CurrentJobTabProvider
            value={{
              currentJobTab,
              setCurrentJobTab
            }}
          >
            <CurrentTrxTabProvider
              value={{
                currentTrxTab,
                setCurrentTrxTab
              }}
            >
              <AppProvider>
                <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                  <div className={classes.root}>
                    {isLoggedIn && (
                      <>
                        <AppHeader
                          open={openDrawer}
                          forceUpdate={forceUpdate}
                          handleDrawerOpen={handleDrawerOpen}
                          handleDrawerClose={handleDrawerClose}
                        />
                        <AppDrawer openDrawer={openDrawer} />
                      </>
                    )}
                    <main className={isLoggedIn ? classes.contentLogin : classes.content}>
                      {isLoggedIn && <div className={classes.appBarSpacer} />}
                      <Switch>
                        <ConditionalRoute exact={true} path='/' routeCondition={!isLoggedIn} component={LoginPage} redirectTo='/jobs' />
                        <ConditionalRoute
                          exact={true}
                          path='/forgotpassword'
                          routeCondition={!isLoggedIn}
                          component={ForgotPasswordPage}
                          redirectTo='/thankyou'
                        />
                        <ConditionalRoute exact={true} path='/thankyou' routeCondition={!isLoggedIn} component={ThankYouPage} redirectTo='/' />
                        <ConditionalRoute
                          exact={true}
                          path='/resetpassword'
                          routeCondition={!isLoggedIn}
                          component={ResetPasswordPage}
                          redirectTo='/'
                        />
                        <ConditionalRoute
                          exact={true}
                          path='/activation'
                          routeCondition={!isLoggedIn}
                          component={AccountVerificationPage}
                          redirectTo='/'
                        />
                        <ConditionalRoute exact={true} path='/driver/:id' routeCondition={isLoggedIn} component={DriverDetailPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/settings' routeCondition={isLoggedIn} component={SettingPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/jobs' routeCondition={isLoggedIn} component={JobPostedPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/jobs/driver-jobs' routeCondition={isLoggedIn} component={JobDriverPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/job/:id/driver' routeCondition={isLoggedIn} component={JobDriverDetailPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/job/:id' routeCondition={isLoggedIn} component={JobDetailPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/invoices' routeCondition={isLoggedIn} component={InvoicesPage} redirectTo='/' />
                        <ConditionalRoute exact={true} path='/transactions' routeCondition={isLoggedIn} component={TransactionsPage} redirectTo='/' />
                        <Route component={NotFoundPage} />
                      </Switch>
                    </main>
                  </div>
                </SnackbarProvider>
              </AppProvider>
            </CurrentTrxTabProvider>
          </CurrentJobTabProvider>
        </CurrentPageProvider>
      </CurrentUserProvider>
      {!isLoggedIn && (
        <Typography
          variant={'h6'}
          color='textSecondary'
          align={!isLoggedIn ? 'center' : 'right'}
          className={clsx({ [classes.footerPaddingIsLoggedIn]: isLoggedIn })}
        >
          {'© ' + new Date().getFullYear() + ' JustGO All Rights Reserved'}
        </Typography>
      )}
    </div>
  );
};

export default App;
