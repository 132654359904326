import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

interface UseTablePaginationProps extends AxiosRequestConfig {
  selector: (data: any) => any;
  sort?: {
    order: string;
    orderBy: string;
  };
  defaultQuery?: { [key: string]: any };
  getData?: (data: any) => void;
}

export type UseTablePagination<T> = {
  loading: boolean;
  count: number;
  total: number;
  page: number;
  perPage: number;
  data: T[];
  order: 'asc' | 'desc';
  orderBy: string;
  isEmpty: boolean;
  query: (key?: string) => any;
  clear: (cb?: () => void, cbp?: ((key: { [key: string]: any }) => any) | undefined) => void;
  setQuery: (value: { [key: string]: any }) => void;
  onOrder: (key: string) => void;
  refresh: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onRowsChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetPage: (val?: number) => void;
};

const useTablePagination = <T>(
  route: string,
  { selector, sort, defaultQuery, getData, ...config }: UseTablePaginationProps
): UseTablePagination<T> => {
  let abort = axios.CancelToken.source();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const arrayParams = Array.from(queryParams.entries()).filter(([key]) => !['page', 'perPage', 'order', 'orderBy'].includes(key));
  const defQuery = {};

  if (defQuery) {
    Object.assign(defQuery, defaultQuery);
  }

  Object.assign(defQuery, Object.fromEntries(arrayParams));

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<{ [key: string]: any }>(defQuery);
  const [data, setData] = useState<T[]>([]);
  const [isClear, setClear] = useState(false);
  const [isRefresh, setRefresh] = useState(false);
  const [order, setOrder] = useState<{
    order: string;
    orderBy: string;
  }>({
    order: queryParams.get('order') || sort?.order || 'asc',
    orderBy: queryParams.get('orderBy') || sort?.orderBy || 'id'
  });
  const [meta, setMeta] = useState({
    total: 0,
    count: 0,
    page: +(queryParams.get('page') || 0),
    offset: 0,
    perPage: +(queryParams.get('perPage') || 10)
  });

  const setQuery = (value: { [key: string]: any }) => {
    setParams({ ...params, ...value });
    setMeta(state => ({ ...state, page: 0 }));
  };

  const onOrder = (key: string) => {
    setOrder(state => ({
      orderBy: key,
      order: state.orderBy === key && state.order === 'desc' ? 'asc' : 'desc'
    }));
  };

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setMeta(state => ({
      ...state,
      page: page
    }));
  };

  const onRowsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setMeta(state => ({ ...state, perPage: +event.target.value || 10 }));
  };

  const resetPage = (val?: number) => {
    setMeta(prev => ({ ...prev, page: val || 0 }));
  };

  const get = async () => {
    try {
      setLoading(true);
      const { params: paramsProps, ...conf } = config;
      let combine = {
        ...paramsProps,
        ...order,
        page: meta.page,
        perPage: meta.perPage
      };

      const fileteredParams = Object.fromEntries<any>(Object.entries(params).filter(([_, v]) => !!v));
      if (Object.keys(fileteredParams).length > 0) {
        combine = { ...combine, ...fileteredParams };
      }

      const { data } = await axios.get(route, {
        ...conf,
        cancelToken: abort.token,
        params: combine
      });

      setData(selector(data));
      setMeta(state => ({
        ...state,
        total: data.count,
        count: data.count
      }));

      url.search = new URLSearchParams(combine).toString();
      window.history.replaceState(null, '', url.href);

      if (getData) {
        getData(selector(data));
      }
    } catch (error) {
      console.log('err', error);
    } finally {
      setLoading(false);
    }
  };

  const clear = (cb?: () => void, cbp?: ((key: { [key: string]: any }) => any) | undefined) => {
    setClear(state => !state);
    setParams(prev => ({ ...(cbp ? cbp(prev) : {}) }));
    setMeta({
      total: 0,
      count: 0,
      page: 0,
      offset: 0,
      perPage: 10
    });
    if (cb) cb();
  };

  const refresh = (cb?: (key: { [key: string]: any }) => any) => {
    setRefresh(state => !state);
    if (cb) {
      setParams(prev => ({ ...cb(prev) }));
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      get();
      clearTimeout(debounce);
    }, 200);

    return () => {
      abort.cancel('cancel in useEffect');
      clearTimeout(debounce);
    };
    // eslint-disable-next-line
  }, [params, order.order, meta.perPage, meta.page, isClear, isRefresh]);

  return {
    loading,
    count: meta.count,
    total: meta.total,
    page: meta.page,
    perPage: meta.perPage,
    data,
    order: order.order as 'asc' | 'desc',
    orderBy: order.orderBy,
    isEmpty: data.length === 0,
    refresh,
    query: (key?: string) => {
      if (!key) return params;
      return params[key] || '';
    },
    setQuery,
    clear,
    onOrder,
    onPageChange,
    onRowsChange,
    resetPage
  };
};

export default useTablePagination;
