import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: '24px'
  },
  button: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.25px',
    textTransform: 'none'
  },
  icon: {
    marginRight: '8px',
    width: '24px',
    height: '24px'
  },
  seeDocument: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.25px',
    textTransform: 'none',
    padding: 0
  }
}));

export default useStyles;
