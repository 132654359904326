import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    gap: '24px'
  },
  uploadBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  fileBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '8px'
  },
  buttonFileBox: {
  },
  noteFileBox: {
    flex: 1,
    maxWidth: '50%',
    '& .fileName': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  }
}));

export default useStyles;
