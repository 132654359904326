import React, { FC, useState, useCallback, useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';

import { CircularProgress } from '@material-ui/core';
import { dummyJobDetail } from 'constants/dummy';
import { INDIVIDUAL_JOB_URL, EXPORT_JOB_URL } from 'constants/url';

import InfoCard from './components/InfoCard';
import JobInformation from './components/JobInfomation';
import TripsDetail from './components/TripsDetail';

import MainTemplate from 'components/Template/MainTemplate';
import AdditionalTable from './components/AdditionalTable';
import PriceTable from './components/PriceTable';
import { reformatJob } from 'utils';
import { GetApp } from '@material-ui/icons';

const JobDetailPage: FC = () => {
  const { match } = useRouter();
  const id = Number(match.params.id);
  const [job, setJob] = useState(dummyJobDetail);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleExportClick = async () => {
    setIsExporting(true);
    try {
      const response = await axios({
        url: EXPORT_JOB_URL(id),
        method: 'GET',
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'job.pdf');
      document.body.appendChild(link);
      link.click();
      setIsExporting(false);
    } catch (err) {
      console.log(err);
      setIsExporting(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (!id) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(INDIVIDUAL_JOB_URL(id), { cancelToken: cancelTokenSource.token });
      setJob(reformatJob(data));
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [id]);

  const refreshPage = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return (
    <MainTemplate
      title='Job Details'
      breadcrumb={true}
      refreshButtonProps={{
        onClick: refreshPage
      }}
      primaryButtonProps={{
        onClick: handleExportClick,
        children: !isExporting ? (
          <>
            <GetApp fontSize='small' style={{ marginRight: '13px' }} /> <span>Export Job</span>
          </>
        ) : (
          <CircularProgress size={24} />
        )
      }}
      links={[{ click: true, name: 'All Jobs', links: 'goback' }, { click: false, name: `#${id}`, links: '' }]}
    >
      <InfoCard job={job} isLoading={isLoadingData} />
      <JobInformation loading={isLoadingData} job={job} />
      <AdditionalTable isLoadingData={isLoadingData} job={job} />
      <PriceTable job={job} />
      <TripsDetail tasks={job.JobTasks.sort((a, b) => a.id - b.id)} isLoadingData={isLoadingData} />
    </MainTemplate>
  );
};

export default JobDetailPage;
