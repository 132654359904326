import React, { forwardRef } from 'react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

interface Props {
  data: JobCSVModel[];
}

const JobCSVLink = forwardRef<any, Props>(({ data }, ref) => {
  return (
    <CSVLink
      headers={[
        { label: 'ID', key: 'id' },
        { label: 'Payment Method', key: 'paymentMethod' },
        { label: 'Date', key: 'date' },
        { label: 'Job Owner', key: 'jobOwner' },
        { label: 'Job Type', key: 'jobType' },
        { label: 'Job Status', key: 'jobStatus' },
        { label: 'Project Date & Time', key: 'jobStartTime' },
        { label: 'Date Completed', key: 'completedDate' },
        { label: 'Duration', key: 'duration' },
        { label: 'Start Location', key: 'startLocation' },
        { label: 'Destination Location', key: 'destinationLocation' },
        { label: 'Material', key: 'material' },
        { label: 'Vehicle Type', key: 'truckType' },
        { label: 'Vehicle No.', key: 'vehicleNo' },
        { label: 'Drivers', key: 'driverNames' },
        { label: 'Supervisors', key: 'supervisorNames' },
        { label: 'Price per task', key: 'price' },
        { label: 'Qty', key: 'noOfTasks' },
        { label: 'Total Price', key: 'totalPrice' },
        { label: 'GST Amount', key: 'gstAmount' },
        { label: 'GST Refund', key: 'gstTaskRefund' },
        { label: 'Grand Total', key: 'grandTotal' }
      ]}
      filename={`posted jobs ${format(new Date(), 'd-MM-Y')}.csv`}
      data={data}
      ref={ref}
    />
  );
});

export default JobCSVLink;
