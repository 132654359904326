import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';

interface Props {
  label: string;
  minWidth?: string;
}

const useStyles = makeStyles({
  chip: {
    minWidth: (props: Props) => props.minWidth || '96px',
    backgroundColor: '#F4F4F4',
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',
    border: `1px solid #333333`,
    height: 'auto'
  },
  label: {
    padding: '5px 15px 5px 8px'
  },
  icon: {
    paddingLeft: '11px'
  }
});

export default function TruckChip(props: Props) {
  const classes = useStyles(props);

  return <Chip size='small' variant='outlined' label={props.label} classes={{ label: classes.label }} className={clsx(classes.chip)} />;
}
