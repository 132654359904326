import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface ModalProps extends DialogProps {
  title?: string;
  children?: React.ReactNode;
  loading?: boolean;
  disableConfirm?: boolean;
  isNew?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const Modal = ({ title, loading, onConfirm, onCancel, onClose, isNew, disableConfirm, children, ...props }: ModalProps) => {
  return (
    <Dialog {...props}>
      <DialogTitle>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>{title}</Typography>
          <IconButton size='small' onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} variant='outlined' color='primary' disableElevation onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={loading || disableConfirm} variant='contained' color='primary' disableElevation onClick={onConfirm}>
          {loading ? <CircularProgress size={24} /> : isNew ? 'Create New' : 'Save Change'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

Modal.defaultProps = {
  isNew: true
};
