import React, { FC, useState } from 'react';

import SupervisorTable from './SupervisorTable';
import SupervisorDetailModal from './SupervisorDetailModal';
import axios from 'axios';
import { SUPERVISORS_BASE_URL, SUPERVISORS_VALIDATION_BASE_URL } from 'constants/url';
import SupervisorModalForm, { SupervisorForm } from './SupervisorForm/SupervisorModalForm';
import useForm from 'hooks/useForm';
import useValidation from 'hooks/useValidation';
import { camelCaseToString } from 'utils';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import useTablePagination from 'hooks/useTablePagination';

interface Props {
  open: boolean;
  onOpen: () => void;
  onCloseModal: (callback?: () => void) => () => void;
}

const SupervisorsPage: FC<Props> = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSupervisor, setSelectedSupervisor] = useState<SupervisorModel>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    process: false,
    email: false,
    contactNumber: false,
    emailMessage: '',
    contactNumberMessage: ''
  });

  const table = useTablePagination<SupervisorModel>(SUPERVISORS_BASE_URL, {
    selector: data => data.supervisors,
    sort: {
      order: 'desc',
      orderBy: 'id'
    }
  });

  const form = useForm<SupervisorForm>({
    defaultValue: {
      id: 0,
      displayName: '',
      contactNumber: '',
      role: '',
      email: ''
    }
  });

  const validation = useValidation({
    data: form.data,
    schema: yup =>
      yup.object().shape({
        role: yup.string().required(),
        displayName: yup.string().required(),
        contactNumber: yup
          .string()
          .required()
          .min(8)
          .max(8),
        email: yup
          .string()
          .optional()
          .email()
      })
  });

  const handleClickDetail = (supervisor: SupervisorModel) => {
    setSelectedSupervisor(supervisor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const validate = async (key: 'contactNumber' | 'email') => {
    try {
      setErrors(state => ({ ...state, process: true, [key]: false, [`${key}Message`]: '' }));
      await axios.post(SUPERVISORS_VALIDATION_BASE_URL(key), { value: form.data[key], id: form.data.id });
      setErrors(state => ({ ...state, [key]: false }));
    } catch (err) {
      setErrors(state => ({ ...state, [key]: true, [`${key}Message`]: `${camelCaseToString(key)} has been used.` }));
    } finally {
      setErrors(state => ({ ...state, process: false }));
    }
  };

  const clearServerError = () => {
    setErrors({ process: false, email: false, contactNumber: false, emailMessage: '', contactNumberMessage: '' });
  };

  const onConfirm = async () => {
    try {
      await form[form.data.id === 0 ? 'post' : 'put'](SUPERVISORS_BASE_URL, { validation: validation.validation });
      table.refresh();
      props.onCloseModal(clear)();
      enqueueSnackbar(form.data.id === 0 ? 'New Supervisor added successfully' : `Supervisor updated successfully`, {
        variant: 'success'
      });
    } catch (e) {
      console.log(e);
    }
  };

  const clear = () => {
    validation.clear();
    form.clearData();
    clearServerError();
  };

  const hasServerError = Object.values(_.pick(errors, ['email', 'contactNumber'])).includes(true);
  const hasError = !Object.entries(validation.errors).every(([k, v]) => !v.invalid);
  const hasValue = Object.values(_.omit(form.data, ['id', 'email'])).some(ev => ev === '');

  return (
    <>
      <SupervisorTable
        table={table}
        handleDetailClick={handleClickDetail}
        onEdit={(id: number) => () => {
          const supervisor = table.data.find(val => val.id === id);
          if (supervisor) {
            props.onOpen();
            form.setData({
              id: supervisor.id,
              displayName: supervisor.User.displayName,
              email: supervisor.User.email,
              contactNumber: supervisor.User.contactNumber,
              role: supervisor.User.role
            });
          }
        }}
      />
      <SupervisorModalForm
        disabledConfirm={hasError || hasServerError || form.processing || hasValue}
        open={props.open}
        form={form}
        serverError={errors}
        validation={validation}
        serverValidate={validate}
        onConfirm={onConfirm}
        onCancel={props.onCloseModal(() => {
          validation.clear();
          form.clearData();
          clearServerError();
        })}
      />
      <SupervisorDetailModal supervisor={selectedSupervisor} open={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default SupervisorsPage;
