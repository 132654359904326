import React from 'react';
import { Box, Container, makeStyles, Theme, Typography, Tabs, Tab, Paper } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
export interface JobTemplateProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  rightHead?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '32px'
  },
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  content: {
    marginTop: '20px'
  },
  rightHead: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  subtitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    letter: '0.25px',
    color: theme.palette.black[50]
  },
  alert: {
    marginTop: '20px'
  },
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.black.A100
  }
}));

const JobTemplate = ({ title, subtitle, rightHead, ...props }: JobTemplateProps) => {
  const classes = useStyles();
  const { history } = useRouter();
  document.title = title;
  return (
    <Container className={classes.container} maxWidth={false}>
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <Box display={'flex'} flexDirection='row' justifyContent='space-between' alignContent='center'>
          <Box>
            <Typography variant='h1' gutterBottom>
              {title}
            </Typography>

            <Typography variant='inherit' className={classes.subtitle}>
              {subtitle || ''}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.content}>
          <Paper className={classes.wrapper}>
            <Box className={classes.rightHead}>
              <Tabs
                indicatorColor='primary'
                value={history.location.pathname}
                onChange={(e, value) => {
                  history.push(value);
                }}
              >
                <Tab label='Posted Job' value={'/jobs'} className={classes.tab} />
                <Tab label='Driver Job' value={'/jobs/driver-jobs'} className={classes.tab} />
              </Tabs>

              {rightHead || null}
            </Box>

            {props.children}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default JobTemplate;

JobTemplate.defaultProps = {
  title: '',
  subtitle: '',
  breadcrumb: false,
  refreshButton: true,
  primaryButton: true
};
