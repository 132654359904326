import React, { FC, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Theme,
  makeStyles,
  TableRow,
  Typography,
  IconButton,
  TableCell,
  Box,
  ListItemText,
  Button,
  Menu,
  MenuItem
} from '@material-ui/core';
import JobStatus from 'typings/enum/JobStatus';
import JobNoteModal from './JobNoteModal';
import JobHistoryModal from './JobHistoryModal';
import StatusChip from 'components/StatusChip';
import { format } from 'date-fns';
import { formatId, ucwords } from 'utils';
import { MoreVert } from '@material-ui/icons';

export interface Props {
  task: {
    id: number;
    DriverId?: number;
    taskEarn: number;
    jobTaskStatus: string;
    completedDate: string;
    completedTime: string;
    driverName: string;
    vehicleNumber: string;
    createdAt: string;
    companyName: string;
    supervisorName: string;
    supervisorRole: string;
    JobNotes: { notes: string; imageUrl: string }[];
    JobTaskHistories: { notes: string; createdAt: string; createdBy: string; role: string }[];
    label?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  chipType: {
    minWidth: '150px'
  },
  mainText: {
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  subText: {
    color: theme.palette.black[50],
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  disableText: {
    textStyle: 'italic',
    color: '#7c7d7e'
  },
  clickableAction: {
    color: '#F6891F',
    cursor: 'pointer'
  },
  nonClickableAction: {
    color: '#DADADA',
    cursor: 'not-allowed'
  }
}));

const BodyRow: FC<Props> = ({ task }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isModalNoteOpen, setisModalNoteOpen] = useState<boolean>(false);
  const [selectedNotes, setSelectedNotes] = useState<Props['task']['JobNotes']>([]);
  const [isModalHistoryOpen, setisModalHistoryOpen] = useState<boolean>(false);
  const [selectedHistories, setSelectedHistories] = useState<Props['task']['JobTaskHistories']>([]);

  const openNotesModal = (jobNotes: typeof selectedNotes) => () => {
    setSelectedNotes(jobNotes);
    setisModalNoteOpen(prev => !prev);
  };

  const openHistoryModal = (taskHistories: typeof selectedHistories) => () => {
    setSelectedHistories(taskHistories);
    setisModalHistoryOpen(prev => !prev);
  };

  const handleClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    // setTempTask(index);
  };

  const handleClose = (evt: () => void) => () => {
    setAnchorEl(null);
    // setTempTask(-1);
    evt();
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Box display={'flex'} flexDirection={'column'}>
            <Box>{formatId(task.id)}</Box>
            <Box className={classes.subText}>{task.label || 'N/A'}</Box>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <NumberFormat value={task.taskEarn} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        </TableCell>
        <TableCell>
          {task.DriverId ? (
            <>
              <Typography variant='body1' className={classes.mainText}>
                {task.driverName}
              </Typography>
              <Typography variant='body1' className={classes.subText}>
                {task.companyName}
              </Typography>
            </>
          ) : (
            <span className={classes.disableText}>Not Assigned</span>
          )}
        </TableCell>
        <TableCell>{task.DriverId ? task.vehicleNumber : <span className={classes.disableText}>Not Assigned</span>}</TableCell>
        <TableCell>
          {task.supervisorName ? (
            <div>
              <Typography variant='h6' className={classes.mainText}>
                {task.supervisorName}
              </Typography>
              <Typography variant='h6' className={classes.subText}>
                ({ucwords(task.supervisorRole)})
              </Typography>
            </div>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align='center'>
          <StatusChip status={task.jobTaskStatus as JobStatus} label={ucwords(task.jobTaskStatus)} minWidth='110px' />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={task.completedDate ? format(new Date(`${task.completedDate} ${task.completedTime}`), 'dd/MM/yyyy, hh:mm aa') : '-'}
            secondary={
              <Button
                disabled={task.JobNotes?.length === 0}
                variant='text'
                size='small'
                disableElevation
                color='primary'
                onClick={openNotesModal(task.JobNotes)}
              >
                View Job Note
              </Button>
            }
          />
        </TableCell>

        <TableCell padding='checkbox'>
          <IconButton size='small' aria-controls={`action-menu-${task.id}`} aria-haspopup='true' onClick={handleClick(0)}>
            <MoreVert />
          </IconButton>

          <Menu
            id={`action-menu-${task.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem onClick={handleClose(openHistoryModal(task.JobTaskHistories))}>View History</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>

      <JobNoteModal open={isModalNoteOpen} onClose={openNotesModal([])} jobNotes={selectedNotes} />
      <JobHistoryModal open={isModalHistoryOpen} onClose={openHistoryModal([])} taskHistories={selectedHistories} />
    </>
  );
};

export default BodyRow;
