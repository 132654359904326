import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles, Theme } from '@material-ui/core';

interface SelectOptions {
  label: string;
  value: any;
  render?: (key: string) => any;
}

interface Props {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  options: SelectOptions[];
  width?: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: (props: Props) => props.width || '90%'
  }
}));

export default function SelectFilter(props: Props) {
  const classes = useStyles(props);
  const { label, value, onChange, options } = props;
  return (
    <FormControl variant='outlined' margin='dense' className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {options.map((option, index) =>
          option.render ? (
            option.render(`opt-${label}-${index}`)
          ) : (
            <MenuItem key={`opt-${label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
