import React, { useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  makeStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { JOBS_EXPORT_BASE_URL } from 'constants/url';
import { formatCurrency, getJobStatusSelect, getJobTypeSelect, getPaymentMethodSelect, getVehicleTypeSelect, listMonths, listYears } from 'utils';
import { format, max } from 'date-fns';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import JobCSVLink from './JobCSVLink';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';

interface ExportJobProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      paddingTop: '8px'
    }
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: '16px'
  },
  content: {
    width: '500px',
    padding: '24px 26px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  actions: {
    padding: '8px 24px 16px 24px'
  },
  rightButton: {
    textTransform: 'none'
  },
  box: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    gap: '16px'
  }
});

const currentDate = new Date();
const dummyParams = {
  month: currentDate.getMonth() + 1,
  year: currentDate.getFullYear(),
  paymentMethod: 'ALL',
  jobStatus: 'ALL',
  vehicleType: 'ALL',
  jobType: 'ALL',
  dateType: 'jobDate'
};

const ExportJob = ({ open, onClose }: ExportJobProps) => {
  const classes = useStyles();
  const { openSnacbar, snackbar } = useSnackbar();
  const cancelToken: CancelTokenSource = axios.CancelToken.source();

  const [params, setParams] = useState(dummyParams);
  const [loading, setLoading] = useState(false);

  const [csv, setCsv] = useState<JobCSVModel[]>([]);
  const csvInstance = useRef<any | null>(null);



  const onSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(JOBS_EXPORT_BASE_URL, {
        params: { ...params, month: String(params.month) },
        cancelToken: cancelToken.token
      });
      const csvData: JobCSVModel[] = data.map((job: JobModel) => {
        let maxDate = 'N/A';
        let gstTaskRefund = 0;
        let totalAmount = 0;
        let totalGstAmount = 0;

        if (!!job.JobTasks?.length) {
          if (job.JobTasks.some(v => !!v.completedDate)) {
            const getMAx = max(
              job.JobTasks.filter(v => !!v.completedDate).map(value => new Date(`${value.completedDate!.replace(/-/g, '/')} ${value.completedTime}`))
            );
            maxDate = format(getMAx, 'dd/MMM/yyyy hh:mm a');
          }

          totalAmount += job.JobTasks.reduce(
            (p, n) => p + ((n.originalEarn || 0) + (n.additionalStatus === 'CONFIRM' ? n.additionalAmount || 0 : 0)),
            0
          );
          totalGstAmount += job.JobTasks.reduce(
            (p, n) => p + ((n.originalGstTask || 0) + (n.additionalStatus === 'CONFIRM' ? n.additionalGstAmount || 0 : 0)),
            0
          );
        }

        if (!!(job as any).Transactions.length) {
          gstTaskRefund = (job as any).Transactions.reduce((p: any, n: { amount: any; }) => p + n.amount, 0);
        }

        if (!!job.JobAdditionals?.length) {
          totalAmount += job.JobAdditionals.reduce((p, n) => p + (n.totalPrice + n.totalItemPrice), 0);
          totalGstAmount += job.JobAdditionals.reduce((p, n) => p + (n.gstTotalPrice + n.gstItemPrice), 0);
        }

        return {
          id: job.id,
          paymentMethod: job.useCredit ? TransactionPaymentMethod.CREDIT_WALLET : TransactionPaymentMethod.JUSTGO_WALLET,
          date: format(new Date(job.createdAt), 'MM/dd/yyyy'),
          jobStartTime: `${format(new Date(`${job.jobDate.replace(/-/g, '/')} ${job.jobTime}`), 'MM/dd/yyyy hh:mm a')}`,
          completedDate: maxDate,
          jobOwner: job.Merchant.companyName,
          jobType: `${job.jobType}${job.workType ? '_' + job.workType: ''}`,
          jobStatus: job.jobStatus,
          duration: `${job.hours / 60} hour(s)`,
          startLocation: job.siteAddress || job.streetName,
          destinationLocation: job.siteAddressDestination || job.streetNameDestination,
          material: job.equipment || job.materials,
          truckType: job.truckType,
          vehicleNo:
            job.JobTasks?.reduce(
              (p, n) => {
                if (n.vehicleNumber) {
                  const exist = p.some(v => v === n.vehicleNumber);
                  if (!exist) {
                    p.push(n.vehicleNumber || '');
                  }
                }
                return p;
              },
              [] as string[]
            )
              .map(task => task)
              .join('| ') || '',
          driverNames:
            job.JobTasks?.reduce(
              (p, n, _, arr) => {
                if (n.driverName) {
                  const exist = p.some(v => v.name === n.driverName);
                  if (!exist) {
                    p.push({ name: n.driverName || '', total: arr.filter(v => v.driverName === n.driverName).length });
                  }
                }
                return p;
              },
              [] as any[]
            )
              .filter(v => !!v.name)
              .map(task => `${task.name}(${task.total})`)
              .join('| ') || '',
          supervisorNames: job.Supervisors?.map(spv => spv.User.displayName).join('| ') || '',
          price: formatCurrency(job.price),
          noOfTasks: (job.JobTasks || []).length,
          totalPrice: formatCurrency(totalAmount),
          gstAmount: formatCurrency(totalGstAmount),
          gstTaskRefund: `-${formatCurrency(gstTaskRefund)}`,
          grandTotal: formatCurrency(totalAmount + totalGstAmount - gstTaskRefund)
        };
      });

      setCsv(!!data?.length ? csvData : []);
      const timer = setTimeout(() => {
        csvInstance.current.link.click();
        setLoading(false);
        clearTimeout(timer);
      }, 1000);
    } catch (err) {
      setLoading(false);
      openSnacbar(err.message, { variant: 'error' });
    }
  };

  const onCloseDialog = () => {
    onClose();
    setParams(dummyParams);
    cancelToken.cancel();
  };

  return (
    <Dialog open={open} maxWidth='md' className={classes.dialog} disableEscapeKeyDown>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Export Job
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
   
        <div className={classes.box}>
          <TextField
            select
            fullWidth
            variant='outlined'
            size='small'
            value={params.jobType}
            label='Job Type'
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              const value = e.target.value;
              if (value) {
                setParams(p => ({ ...p, jobType: value }));
              }
            }}
          >
            <MenuItem value='ALL'>All Job</MenuItem>
            {getJobTypeSelect().map(v => (
              <MenuItem key={v.id} value={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            variant='outlined'
            size='small'
            value={params.vehicleType}
            label='Vehicle Type'
            InputLabelProps={{ shrink: true }}
            onChange={e => {
              const value = e.target.value;
              if (value) {
                setParams(p => ({ ...p, vehicleType: value }));
              }
            }}
          >
            <MenuItem value='ALL'>All Vehicle</MenuItem>
            {getVehicleTypeSelect().map(v => (
              <MenuItem key={v.id} value={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Date Type</FormLabel>
          <RadioGroup
            color='primary'
            aria-label='date-type'
            name='dateType'
            value={params.dateType}
            onChange={e => {
              const value = e.target.value;
              if (value) {
                setParams(p => ({ ...p, dateType: value }));
              }
            }}
            row
          >
            <FormControlLabel value='jobDate' control={<Radio />} label='Job Date' />
            <FormControlLabel value='completeDate' control={<Radio />} label='Complete Date' />
          </RadioGroup>
        </FormControl>
        <div className={classes.box}>
          <TextField
            variant='outlined'
            size='small'
            InputLabelProps={{ shrink: true }}
            label='Select Month'
            select
            fullWidth
            value={params.month}
            onChange={e => setParams(p => ({ ...p, month: +e.target.value }))}
            style={{ flexGrow: 1 }}
          >
            {listMonths.map(v => (
              <MenuItem key={v.month} value={v.month}>
                {v.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant='outlined'
            size='small'
            InputLabelProps={{ shrink: true }}
            label='Select Year'
            select
            value={params.year}
            fullWidth
            onChange={e => setParams(p => ({ ...p, year: +e.target.value }))}
            style={{ minWidth: '30%' }}
          >
            {listYears().map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          select
          fullWidth
          variant='outlined'
          size='small'
          value={params.jobStatus}
          label='Job Status'
          InputLabelProps={{ shrink: true }}
          onChange={e => {
            const value = e.target.value;
            if (value) {
              setParams(p => ({ ...p, jobStatus: value }));
            }
          }}
        >
          <MenuItem value='ALL'>All Job Status</MenuItem>
          {getJobStatusSelect().map(v => (
            <MenuItem key={v.id} value={v.id}>
              {v.name}
            </MenuItem>
          ))}
        </TextField>

        <FormControl component='fieldset'>
          <FormLabel component='legend'>Paymen Method</FormLabel>
          <RadioGroup
            color='primary'
            aria-label='payment-method'
            value={params.paymentMethod}
            name='paymentMethod'
            onChange={e => {
              const value = e.target.value;
              if (value) {
                setParams(p => ({ ...p, paymentMethod: value }));
              }
            }}
            row
          >
            <FormControlLabel value={'ALL'} control={<Radio />} label={'All'} />
            {getPaymentMethodSelect().map(v => (
              <FormControlLabel key={v.id} value={v.id} control={<Radio />} label={v.name} />
            ))}
          </RadioGroup>
        </FormControl>
        <JobCSVLink data={csv} ref={csvInstance} />
        <Snackbar {...snackbar} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant={'text'} color='primary' disabled={loading} disableElevation onClick={onCloseDialog}>
          Cancel
        </Button>

        <Button disabled={loading} variant='contained' color='primary' disableElevation className={classes.rightButton} onClick={onSubmit}>
          {loading ? <CircularProgress color='inherit' size={24} /> : 'Download'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportJob;
