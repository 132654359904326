import React, { FC, useState } from 'react';
import axios from 'axios';
import { DRIVERS_BASE_URL, DRIVERS_PUT_VEHICLE_BASE_URL, DRIVERS_VALIDATION_BASE_URL } from 'constants/url';
import { camelCaseToString } from 'utils';
import { useSnackbar } from 'notistack';
import DriverModalForm, { DriverForm } from './DriverForm/DriverFormModal';
import useForm from 'hooks/useForm';
import useValidation from 'hooks/useValidation';
import DriverTable from './DriverTable';
import _ from 'lodash';
import useTablePagination from 'hooks/useTablePagination';

interface Props {
  open: boolean;
  onOpen: () => void;
  onCloseModal: (callback?: () => void) => () => void;
}

const DriversPage: FC<Props> = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState<{ file?: File; fileName?: string; originalName?: string; extension?: string; currentFile?: string }>({
    file: undefined,
    fileName: '',
    originalName: '',
    extension: '',
    currentFile: ''
  });

  const [errors, setErrors] = useState({
    process: false,
    email: false,
    emailMessage: '',
    contactNumber: false,
    contactNumberMessage: ''
  });

  const table = useTablePagination<DriverModel>(DRIVERS_BASE_URL, {
    selector: data => data.Drivers,
    sort: {
      order: 'desc',
      orderBy: 'id'
    }
  });

  const form = useForm<DriverForm>({
    defaultValue: {
      id: 0,
      displayName: '',
      contactNumber: '',
      email: '',
      vehicleLogCard: '',
      vehicleNumber: '',
      isManager: false
    }
  });

  const validation = useValidation({
    data: form.data,
    schema: yup =>
      yup.object().shape({
        displayName: yup.string().required(),
        contactNumber: yup
          .string()
          .required()
          .min(8)
          .max(8),
        vehicleNumber: yup.string().required(),
        email: yup
          .string()
          .required()
          .email()
      })
  });

  const validate = async (key: 'contactNumber' | 'email' | 'vehicleNumber') => {
    try {
      setErrors(state => ({ ...state, process: true, [key]: false, [`${key}Message`]: '' }));
      await axios.post(DRIVERS_VALIDATION_BASE_URL(key), {
        value: form.data[key],
        id: form.data.id
      });
      setErrors(state => ({ ...state, [key]: false, [`${key}Message`]: '' }));
    } catch (err) {
      setErrors(state => ({ ...state, [key]: true, [`${key}Message`]: `${camelCaseToString(key)} has been used.` }));
    } finally {
      setErrors(state => ({ ...state, process: false }));
    }
  };

  const clearServerError = () => {
    setErrors({
      process: false,
      email: false,
      contactNumber: false,
      emailMessage: '',
      contactNumberMessage: ''
    });
  };

  const onEdit = (id: number) => () => {
    const driver = table.data.find(val => val.id === id);
    if (driver) {
      setImageFile(state => ({ ...state, originalName: driver.vehicleLogCard, currentFile: driver.vehicleLogCard }));

      form.setData({
        id: driver.id,
        displayName: driver.User.displayName,
        email: driver.User.email,
        contactNumber: driver.User.contactNumber,
        vehicleNumber: driver.vehicleNumber,
        isManager: driver.isManager,
        vehicleLogCard: driver.vehicleLogCard
      });

      props.onOpen();
    }
  };

  const onConfirm = async () => {
    try {
      if (imageFile.file) {
        const {
          data: { vehicleLogCardFile }
        } = await axios.get(DRIVERS_PUT_VEHICLE_BASE_URL(imageFile.fileName as string));

        const headers = new Headers();
        if (imageFile.extension === 'pdf') {
          headers.append('Content-Type', `application/${imageFile.extension}`);
        } else {
          headers.append('Content-Type', `image/${imageFile.extension}`);
        }
        const req = await fetch(vehicleLogCardFile, {
          method: 'PUT',
          headers: headers,
          body: imageFile.file
        });

        if (req.ok) {
          enqueueSnackbar('Image upload success', {
            variant: 'success'
          });
        } else {
          enqueueSnackbar('Image upload failed', {
            variant: 'error'
          });
        }
      }

      await form[form.data.id === 0 ? 'post' : 'put'](DRIVERS_BASE_URL, { validation: validation.validation });
      table.refresh();
      props.onCloseModal(clear)();
      enqueueSnackbar(form.data.id === 0 ? 'New driver added successfully' : `Driver updated successfully`, {
        variant: 'success'
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const extention = file.name!.split('.').pop();
      const filename = `${Date.now()}.${extention}`;
      setImageFile(state => ({ ...state, file: file, fileName: filename, originalName: file.name, extension: extention }));
      form.setData({ vehicleLogCard: filename });
      e.target.value = null as any;
    }
  };

  const onRemove = () => {
    setImageFile(state => ({ ...state, file: undefined, fileName: '', originalName: '', extension: '' }));
    form.setData({ vehicleLogCard: '' });
  };

  const onUndo = () => {
    setImageFile(state => ({ ...state, file: undefined, fileName: '', extension: '', originalName: imageFile.currentFile }));
    form.setData({ vehicleLogCard: imageFile.currentFile });
  };

  const clear = () => {
    validation.clear();
    form.clearData();
    setImageFile({ file: undefined, fileName: '', originalName: '', extension: '', currentFile: '' });
    clearServerError();
  };

  const hasServerError = Object.values(_.pick(errors, ['email', 'contactNumber'])).includes(true);
  const hasError = !Object.entries(validation.errors).every(([k, v]) => !v.invalid);
  const hasValue = Object.values(
    _.omit(form.data, ['id', 'isManager'].concat(form.data.isManager ? ['vehicleLogCard', 'vehicleNumber'] : ['vehicleLogCard']))
  ).some(ev => ev === '');

  return (
    <>
      <DriverTable table={table} onEdit={onEdit} />
      <DriverModalForm
        disabledConfirm={hasError || hasServerError || form.processing || hasValue}
        open={props.open}
        form={form}
        serverError={errors}
        validation={validation}
        imageFile={imageFile}
        serverValidate={validate}
        onConfirm={onConfirm}
        onCancel={props.onCloseModal(clear)}
        onUpload={onUpload}
        onRemove={onRemove}
        onUndo={onUndo}
      />
    </>
  );
};

export default DriversPage;
