import { format } from 'date-fns';
import TransactionType from 'typings/enum/TransactionType';
import _ from 'lodash';
import TruckType from 'typings/enum/TruckType';
import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';
import WorkType from 'typings/enum/WorkType';

export const isValidEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const isStrongPassword = (password: string): boolean => {
  // eslint-disable-next-line
  const regularExpression = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=,.:;?<>|~{}`\[\]/])/;

  return regularExpression.test(String(password));
};

export const ucwords = (str?: string) => {
  if (str) {
    return (str + '')
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^([a-z])|\s+([a-z])/g, function($1) {
        return $1.toUpperCase();
      });
  } else {
    return '';
  }
};

export const staticString = (str: string) => {
  return str ? str.toUpperCase().replace(/ /g, '_') : '';
};

export const min = format(new Date(), 'yyyy-MM-dd') + 'T00:00';

export const ucWords = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export function formatId(id: number) {
  return '#' + String(id).padStart(3, '0');
}

export const formatCurrency = (number: number) => {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD'
  }).format(number);
};

export const getPercent = (discount: number, original: number): number => {
  if (discount === 0 || original === 0) {
    return 0;
  }
  return Math.round(Number((discount / original).toFixed(2)) * 100);
};

export const incomingType = [
  TransactionType.TOPUP,
  TransactionType.TASK_COMPLETED,
  TransactionType.GST_TASK_COMPLETED,

  TransactionType.TASK_EXPIRED,
  TransactionType.GST_TASK_EXPIRED,

  TransactionType.TASK_CANCELLED,
  TransactionType.GST_TASK_CANCELLED,

  TransactionType.JOB_UNPUBLISHED,
  TransactionType.GST_JOB_UNPUBLISHED,

  TransactionType.JOB_CANCELLED,
  TransactionType.GST_JOB_CANCELLED,

  TransactionType.JOB_EXPIRED,
  TransactionType.GST_JOB_EXPIRED,

  TransactionType.CANCEL_EDIT_TASK_HOUR,
  TransactionType.REFUND_EDIT_TASK_HOUR,
  TransactionType.IN_EDIT_TASK_HOUR
];

export const outgoingType = [
  TransactionType.WITHDRAW,
  TransactionType.JOB_CREATED,
  TransactionType.GST_JOB_CREATED,

  TransactionType.OUT_EDIT_TASK_HOUR,
  TransactionType.REVERT_EDIT_TASK_HOUR,
  TransactionType.JOB_CONFIRM_ADDITIONAL,
  TransactionType.GST_JOB_CONFIRM_ADDITIONAL
];

export const snackCaseToString = (text: string) => {
  return text
    .split('_')
    .map(v => v)
    .join(' ');
};

export const camelCaseToString = (text: string) => {
  if (text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result[0].toUpperCase() + result.substring(1).toLowerCase();
  }
  return '';
};

export const removeEmpty = (obj: any) => {
  // eslint-disable-next-line
  return Object.fromEntries(Object.entries(obj).filter(([{}, v]) => !_.isEmpty(v)));
};

export const jobTypeLabel = (label: string) => {
  return (
    {
      [JobType.DAYWORKS]: 'Dayworks',
      [JobType.OU_WORKS]: 'OU Works',
      [JobType.TRIPWORKS]: 'Tripworks'
    }[label] || '-'
  );
};

export const truckTypeLabel = (label: string) => {
  return (
    {
      [TruckType.LORRY_CRANE]: 'Lorry Crane',
      [TruckType.TIPPER]: 'Tipper',
      [TruckType.TMA]: 'TMA',
      [TruckType.TRAILER]: 'Trailer'
    }[label] || 'Tipper'
  );
};

function limitToScale(numStr: string, scale: number, fixedDecimalScale?: boolean) {
  var str = '';
  var filler = fixedDecimalScale ? '0' : '';

  for (var i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler;
  }

  return str;
}

function splitDecimal(numStr: any) {
  var allowNegative = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var hasNagation = numStr[0] === '-';
  var addNegation = hasNagation && allowNegative;
  numStr = numStr.replace('-', '');
  var parts = numStr.split('.');
  var beforeDecimal = parts[0];
  var afterDecimal = parts[1] || '';
  return {
    beforeDecimal: beforeDecimal,
    afterDecimal: afterDecimal,
    hasNagation: hasNagation,
    addNegation: addNegation
  };
}

export const roundToPrecision = (numStr: string, scale: number, fixedDecimalScale?: boolean) => {
  //if number is empty don't do anything return empty string
  if (['', '-'].indexOf(numStr) !== -1) return numStr;
  var shoudHaveDecimalSeparator = numStr.indexOf('.') !== -1 && scale;

  var _splitDecimal = splitDecimal(numStr),
    beforeDecimal = _splitDecimal.beforeDecimal,
    afterDecimal = _splitDecimal.afterDecimal,
    hasNagation = _splitDecimal.hasNagation;

  var roundedDecimalParts = parseFloat('0.'.concat(afterDecimal || '0'))
    .toFixed(scale)
    .split('.');
  var intPart = beforeDecimal
    .split('')
    .reverse()
    .reduce(function(roundedStr: any, current: any, idx: any) {
      if (roundedStr.length > idx) {
        return (Number(roundedStr[0]) + Number(current)).toString() + roundedStr.substring(1, roundedStr.length);
      }

      return current + roundedStr;
    }, roundedDecimalParts[0]);
  var decimalPart = limitToScale(roundedDecimalParts[1] || '', Math.min(scale, afterDecimal.length), fixedDecimalScale);
  var negation = hasNagation ? '-' : '';
  var decimalSeparator = shoudHaveDecimalSeparator ? '.' : '';
  return ''
    .concat(negation)
    .concat(intPart)
    .concat(decimalSeparator)
    .concat(decimalPart);
};

export const allTaskOneDriverType = [TruckType.LORRY_CRANE, TruckType.TRAILER];

export const getHours = (minutes?: number) => {
  if (!minutes) return 0;
  return minutes / 60;
};

export const getAppFee = (total: number, percent: number): number => {
  return total * (percent / 100);
};

export const rounding = (number: number): number => {
  return String(number).split('.').length > 1 ? Math.floor(number * 100) / 100 : number;
};

export const reformatJob = (job: JobDetail) => {
  job.taskType = job.jobType === JobType.DAYWORKS ? `Vehicles` : 'Trips';
  job.taskRequired = job.jobType === JobType.DAYWORKS ? `Vehicles Required/${job.hours} hours` : 'Trips Required';
  job.taskPriceLabel = `/${job.jobType === JobType.DAYWORKS ? `hours/vehicle` : 'trip'}`;
  return job;
};

export const listMonths = [
  { month: 1, name: 'January' },
  { month: 2, name: 'February' },
  { month: 3, name: 'March' },
  { month: 4, name: 'April' },
  { month: 5, name: 'May' },
  { month: 6, name: 'June' },
  { month: 7, name: 'July' },
  { month: 8, name: 'August' },
  { month: 9, name: 'September' },
  { month: 10, name: 'October' },
  { month: 11, name: 'November' },
  { month: 12, name: 'December' }
];

export const listYears = (): number[] => {
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const years: number[] = [];

  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
};

export const snackCaseToSentence = (text: string) => {
  return text
    .split('_')
    .map(v => ucWords(v))
    .join(' ');
};

export const newLabelTrx = (type: TransactionType): string => {
  const newType: { [key: string]: any } = {
    [TransactionType.CANCEL_EDIT_TASK_HOUR]: 'Cancel Add Hour',
    [TransactionType.OUT_EDIT_TASK_HOUR]: 'Add Hour',
    [TransactionType.IN_EDIT_TASK_HOUR]: 'Reduce Hour',
    [TransactionType.JOB_CONFIRM_ADDITIONAL]: 'Overtime and Item',
    [TransactionType.GST_JOB_CONFIRM_ADDITIONAL]: 'GST Overtime and Item'
  };
  return newType[type] || type;
};

export const getJobTypeSelect = () => {
  const jobType = [
    { id: JobType.DAYWORKS, name: jobTypeLabel(JobType.DAYWORKS) },
    { id: JobType.TRIPWORKS, name: jobTypeLabel(JobType.TRIPWORKS) },
    { id: JobType.OU_WORKS, name: jobTypeLabel(JobType.OU_WORKS) }
  ];

  return jobType;
};

export const getVehicleTypeSelect = () => {
  const truckTypes = [
    { id: TruckType.TIPPER, name: truckTypeLabel(TruckType.TIPPER) },
    { id: TruckType.TMA, name: truckTypeLabel(TruckType.TMA) },
    { id: TruckType.LORRY_CRANE, name: truckTypeLabel(TruckType.LORRY_CRANE) },
    { id: TruckType.TRAILER, name: truckTypeLabel(TruckType.TRAILER) }
  ];

  return truckTypes;
};

export const getJobStatusSelect = () => {
  const jobStatus = [
    { id: JobStatus.DRAFT, name: JobStatus.DRAFT },
    { id: JobStatus.AVAILABLE, name: JobStatus.AVAILABLE },
    { id: JobStatus.ASSIGNED, name: JobStatus.ASSIGNED },
    { id: JobStatus.IN_PROGRESS, name: 'IN PROGRESS' },
    { id: JobStatus.COMPLETED, name: JobStatus.COMPLETED },
    { id: JobStatus.CANCELLED, name: JobStatus.CANCELLED },
    { id: JobStatus.EXPIRED, name: JobStatus.EXPIRED }
  ];

  return jobStatus;
};

export const getPaymentMethodSelect = () => {
  const selectPayment = [
    { id: TransactionPaymentMethod.JUSTGO_WALLET, name: 'Justgo Wallet' },
    { id: TransactionPaymentMethod.CREDIT_WALLET, name: 'Credit' }
  ];

  return selectPayment;
};

export const workTypeLabel = (label: string) => {
  return (
    {
      [WorkType.DISPOSAL]: 'Disposal',
      [WorkType.PURCHASE]: 'Purchase'
    }[label] || 'N/A'
  );
};

export const getWorkTypeSelect = () => {
  const truckTypes = [
    { id: WorkType.DISPOSAL, name: workTypeLabel(WorkType.DISPOSAL) },
    { id: WorkType.PURCHASE, name: workTypeLabel(WorkType.PURCHASE) }
  ];

  return truckTypes;
};

export const getGroupJobTypeSelect = () => {
  const jobType = [
    { id: JobType.DAYWORKS, name: jobTypeLabel(JobType.DAYWORKS) },
    { id: JobType.TRIPWORKS, name: jobTypeLabel(JobType.TRIPWORKS) },
    { id: JobType.OU_WORKS, name: jobTypeLabel(JobType.OU_WORKS), isHead: true },
    { id: `${JobType.OU_WORKS}_${WorkType.DISPOSAL}`, name: `${jobTypeLabel(JobType.OU_WORKS)} ${workTypeLabel(WorkType.DISPOSAL)}` },
    { id: `${JobType.OU_WORKS}_${WorkType.PURCHASE}`, name: `${jobTypeLabel(JobType.OU_WORKS)} ${workTypeLabel(WorkType.PURCHASE)}` }
  ];

  return jobType;
};
