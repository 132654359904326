import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, Paper, TableContainer, InputAdornment, IconButton } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import InvoiceType from 'typings/enum/InvoiceType';
import BodyRow from './components/BodyRow';
import { DateRange, Search } from '@material-ui/icons';
import { UseTablePagination } from 'hooks/useTablePagination';
import TableLoading from 'components/Table/TableLoading';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';

interface Props extends UseTablePagination<InvoiceList> {
  dateRange: string;
  handleCalendar: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onOpenInvoice: (id: number, no: string, gst: boolean) => () => void;
  onOpenDo: (id: number, no: string) => () => void;
}

const invoiceType: Select[] = [
  { id: InvoiceType.TO_MERCHANT, name: InvoiceType.TO_MERCHANT },
  { id: InvoiceType.TO_DRIVER, name: InvoiceType.TO_DRIVER }
];

const selectPayment: Select[] = [
  { id: TransactionPaymentMethod.JUSTGO_WALLET, name: 'Justgo Wallet' },
  { id: TransactionPaymentMethod.CREDIT_WALLET, name: 'Credit' }
];

const InvoiceTable: FC<Props> = ({ onOpenInvoice, onOpenDo, dateRange, handleCalendar, ...props }) => {
  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(e, property) => props.onOrder(property)}
            height={'unset'}
            headers={[
              {
                id: 'no',
                label: 'Invoice No. & Job ID',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'invoiceDate',
                label: 'Invoice Date',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'merchant',
                label: 'Job Owner',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'driver',
                label: 'Driver Company Name',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'address',
                label: 'Job Address',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap',
                    minWidth: '250px'
                  }
                }
              },
              {
                id: 'amount',
                label: 'Total Amount',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'paymentMethod',
                label: 'Payment Method',
                sort: false,
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    width: '10%',
                    minWidth: '10%',
                    whiteSpace: 'nowrap'
                  }
                }
              },
              { id: 'type', label: 'Type', sort: false, pT: '16px', pB: '16px' },
              { id: 'action', label: 'Action', sort: false, pT: '16px', pB: '16px', cellProps: { padding: 'checkbox' } }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findNumber',
                isInputText: true,
                value: props.query('number'),
                handleInputChange: value => props.setQuery({ number: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findInvoiceDate',
                isInputText: true,
                value: dateRange,
                readOnly: true,
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: { minWidth: '200px' }
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendar}>
                      <DateRange fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>
                )
              },
              {
                id: 'findMerchant'
                // isInputText: true,
                // value: props.query('merchant'),
                // handleInputChange: value => props.setQuery({ merchant: value }),
                // pT: '0px',
                // pB: '0px',
                // pL: '16px',
                // pR: '8px',
                // endAdornment: (
                //   <InputAdornment position='start'>
                //     <Search fontSize='small' color='disabled' />
                //   </InputAdornment>
                // )
              },
              {
                id: 'findDriver',
                isInputText: true,
                value: props.query('driver'),
                handleInputChange: value => props.setQuery({ driver: value }),
                pT: '0px',
                pB: '0px',
                pL: '0px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findAddress',
                isInputText: true,
                value: props.query('address'),
                handleInputChange: value => props.setQuery({ address: value }),
                pT: '0px',
                pB: '0px',
                pL: '0px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findAmount',
                isInputText: false,
                pT: '0px',
                pB: '0px',
                pL: '0px',
                pR: '8px'
              },
              {
                id: 'findPaymentMethod',
                isSelect: true,
                label: 'Select Payment',
                value: props.query('paymentMethod'),
                selectOption: selectPayment,
                handleOptionChange: value => props.setQuery({ paymentMethod: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },
              {
                id: 'findInvoiceType',
                label: 'Select Type',
                isSelect: true,
                value: props.query('type'),
                selectOption: invoiceType,
                handleOptionChange: value => props.setQuery({ type: value }),
                cellProps: {
                  style: {
                    minWidth: '150px'
                  }
                },
                pT: '0px',
                pB: '0px',
                pL: '0px',
                pR: '8px'
              },
              { id: 'findAction' }
            ]}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={9} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={9}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((value, index) => (
              <BodyRow
                key={index}
                {...value}
                onOpenInvoice={onOpenInvoice(value.id, value.invoiceNo, value.isGst)}
                onOpenDo={onOpenDo(value.id, value.invoiceNo)}
              />
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
