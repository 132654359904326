import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

export interface TableEmptyProps {
  text?: string;
  col: number;
}

const TableEmpty = ({ text, col }: TableEmptyProps) => {
  return (
    <TableRow>
      <TableCell colSpan={col} size='small' align='center'>
        {text || 'Data Not Available.'}
      </TableCell>
    </TableRow>
  );
};

export default TableEmpty;
