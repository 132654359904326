import { Divider, Drawer, List, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import React, { FC, useState, useEffect } from 'react';
import fullIcon from 'images/icon/full-logo.svg';
import smallIcon from 'images/icon/small-logo.svg';
import DrawerItem from './components/DrawerItem';
import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
interface Props {
  openDrawer: boolean;
}

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    backgroundColor: '#29696A',
    color: '#fff',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: +REACT_APP_DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  logoContainer: {
    textAlign: 'center'
  },
  logo: {
    width: '50%',
    margin: `0px ${theme.spacing(8)}px`
  },
  smallLogo: {
    width: '40%',
    margin: `0px ${theme.spacing(5)}px`
  }
}));

const AppDrawer: FC<Props> = props => {
  const classes = useStyles();
  const { openDrawer } = props;

  const [menuActive, setMenuActive] = useState<string>('');

  useEffect(() => {
    if (menuActive === '') {
      const pathname = window.location.pathname;
      if (pathname.includes('/owners')) {
        setMenuActive('Owners');
      } else if (pathname.includes('/drivers')) {
        setMenuActive('Drivers');
      } else if (pathname.includes('/jobs')) {
        setMenuActive('Jobs');
      } else if (pathname.includes('/transactions')) {
        setMenuActive('Transactions');
      } else if (pathname.includes('/settings')) {
        setMenuActive('Settings');
      }
    }
  }, [menuActive]);

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose)
      }}
      open={openDrawer}
      color='secondary'
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.logoContainer}>
          <img src={!openDrawer ? smallIcon : fullIcon} alt='' className={!openDrawer ? classes.smallLogo : classes.logo} />
        </div>
      </div>
      <Divider />
      <List>
        <DrawerItem Icon={WorkIcon} path='/jobs' label='Jobs' menuActive={menuActive} setMenuActive={setMenuActive} />
        <DrawerItem Icon={DescriptionIcon} path='/invoices' label='Invoice' menuActive={menuActive} setMenuActive={setMenuActive} />
        <DrawerItem
          Icon={MonetizationOnOutlinedIcon}
          path='/transactions'
          label='Transactions'
          menuActive={menuActive}
          setMenuActive={setMenuActive}
        />
        <DrawerItem Icon={SettingsIcon} path='/settings' label='Settings' menuActive={menuActive} setMenuActive={setMenuActive} />
      </List>
    </Drawer>
  );
};

export default AppDrawer;
