import React from 'react';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { TableRow, Link, Typography } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import JobType from 'typings/enum/JobType';
import BodyCell from 'components/BodyCell';
import JobStatus from 'typings/enum/JobStatus';
import StatusChip from 'components/StatusChip';
import JobChip from 'components/JobChip';
import { ucwords, formatId, truckTypeLabel } from 'utils';
import JobPayment from 'components/JobPayment';

interface Props extends JobList {}

const BodyRow = (props: Props) => {
  const {
    id,
    jobType,
    jobTitle,
    remarks,
    jobStatus,
    jobDate,
    jobTime,
    totalAmount,
    truckType,
    hasRequestQuote,
    completedDate,
    completedTime,
    paymentMethod,
    workType
  } = props;
  const dateTimeFormatted = `${format(jobDate ? new Date(`${jobDate.replace(/-/g, '/')} ${jobTime}`) : new Date(), 'dd/MM/yyyy, hh:mm a')}`;
  return (
    <TableRow hover selected={hasRequestQuote}>
      <BodyCell pT={'16px'} pB={'16px'}>
        <Link component={RouterLink} to={`/job/${id}`} style={{ whiteSpace: 'nowrap' }}>
          {formatId(id)}
        </Link>
      </BodyCell>
      <BodyCell cellWidth='30%' noWrap>
        <Typography noWrap>{(jobTitle || '-').substring(0, 35).concat(jobTitle.length > 35 ? '...' : '')}</Typography>
        <Typography noWrap>{(remarks || '-').substring(0, 35).concat(remarks.length > 35 ? '...' : '')}</Typography>
      </BodyCell>
      <BodyCell align='center'>
        <JobChip type={jobType as JobType} workType={workType} />
      </BodyCell>
      <BodyCell align='center'>{truckTypeLabel(truckType)}</BodyCell>
      <BodyCell align='center'>{dateTimeFormatted}</BodyCell>
      <BodyCell align='center'>{!!completedDate ? `${completedDate}, ${completedTime}` : 'N/A'}</BodyCell>
      <BodyCell align='center'>
        <StatusChip label={ucwords(jobStatus)} status={jobStatus as JobStatus} />
      </BodyCell>
      <BodyCell align='right'>
        <NumberFormat value={totalAmount} displayType='text' thousandSeparator={true} prefix='$' decimalScale={2} fixedDecimalScale={true} />
      </BodyCell>
      <BodyCell align='center'>{paymentMethod ? <JobPayment type={paymentMethod} /> : '-'}</BodyCell>
    </TableRow>
  );
};

export default BodyRow;
