import { SnackbarCloseReason } from '@material-ui/core';
import { Props } from 'components/Snackbar';
import React from 'react';

const useSnackbar = () => {
  const [snackbar, setSnackbar] = React.useState<Props>({
    open: false,
    message: '',
    variant: 'success'
  });

  return {
    openSnacbar: (message: string, options?: Omit<Props, 'message'>) => {
      setSnackbar(state => ({ ...state, open: true, message, ...options }));
    },
    snackbar: {
      open: snackbar.open,
      onClose: (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        setSnackbar(state => ({ ...state, open: false, variant: 'success' }));
      },
      message: snackbar.message,
      variant: snackbar.variant
    }
  };
};

export default useSnackbar;
