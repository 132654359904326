import React, { FC, useState, useCallback, useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';

import { dummyJobDetail } from 'constants/dummy';
import { INDIVIDUAL_JOB_URL } from 'constants/url';

import InfoCard from './components/InfoCard';
import JobInformation from './components/JobInfomation';
import TripsDetail from './components/TripsDetail';

import MainTemplate from 'components/Template/MainTemplate';
import AdditionalTable from './components/AdditionalTable';
import PriceTable from './components/PriceTable';
import { reformatJob } from 'utils';

const JobDriverDetailPage: FC = () => {
  const { match } = useRouter();
  const id = Number(match.params.id);
  const [job, setJob] = useState(dummyJobDetail);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (!id) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${INDIVIDUAL_JOB_URL(id)}/driver`, { params: { tab: 'driver' }, cancelToken: cancelTokenSource.token });
      setJob(reformatJob(data));
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [id]);

  const refreshPage = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return (
    <MainTemplate
      title='Job Details'
      breadcrumb={true}
      refreshButtonProps={{
        onClick: refreshPage
      }}
      primaryButton={false}
      links={[{ click: true, name: 'All Jobs', links: 'goback' }, { click: false, name: `#${id}`, links: '' }]}
    >
      <InfoCard job={job} isLoading={isLoadingData} />
      <JobInformation loading={isLoadingData} job={job} />
      <AdditionalTable isLoadingData={isLoadingData} job={job} />
      <PriceTable job={job} />
      <TripsDetail tasks={job.JobTasks.sort((a, b) => a.id - b.id)} isLoadingData={isLoadingData} />
    </MainTemplate>
  );
};

export default JobDriverDetailPage;
