import React, { FC, useState } from 'react';
import { Paper, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';

import JobType from 'typings/enum/JobType';
import MaterialImageCarousel from './MaterialImageCarousel';
import CardContent, { ListColumnProps } from 'components/CardContent';
import TruckType from 'typings/enum/TruckType';
import { allTaskOneDriverType, formatCurrency, ucwords } from 'utils';
import { ErrorRounded } from '@material-ui/icons';

interface Props {
  job: JobDetail;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  jobInformation: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '16px',
    marginTop: '16px'
  },
  materialImages: {
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 5,
    cursor: 'pointer'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    paddingLeft: 0
  },
  value: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    paddingLeft: 0,
    color: theme.palette.black.A100
  }
}));

export const truckTypeInformation = (job: JobDetail): ListColumnProps<JobDetail>[][] => {
  const list: ListColumnProps<JobDetail>[][] = [[{ title: 'Request Quote', value: data => `${data.hasRequestQuote ? 'Yes' : 'No'}` }], [], []];

  if (allTaskOneDriverType.includes(job.truckType as TruckType)) {
    if (job.truckType === TruckType.LORRY_CRANE) {
      list[0].splice(0, 0, {
        title: 'Total Cargo Weight',
        value: data => `${(data.materialWeight || 0).toLocaleString('en-sg')} ${ucwords(data.materialWeightType)}`
      });
      list[0].splice(1, 0, {
        title: 'Lifting distance',
        value: data => `${data.liftingDistance || 0} m`
      });
      list[0].splice(2, 0, { title: 'Require Hoisting on Site', value: data => `${!!data.hoistHeight ? 'Yes' : 'No'}` });
      list[1].push({
        title: 'Dimension of cargo',
        value: data => `${data.lengthCargo || 0}m (L) x ${data.widthCargo || 0}m (W) x ${data.heightCargo}m (H)`
      });
      list[1].push({ title: 'Estimated Height of Hoisting', value: data => `${data.hoistHeight} m` });
      list[2].push({
        title: 'Number Of Pieces',
        value: data => `${data.numberPiece || 0} Pcs`
      });
      list[2].push({
        title: 'Tonnage of lorry crane',
        value: data => `${data.tonnageName ? `${data.tonnageName} with Winch, ${formatCurrency(data.price)}` : '-'}`
      });
    }

    if (job.truckType === TruckType.TRAILER) {
      list[0].splice(0, 0, {
        title: job.equipment ? 'Total Equipment Weight' : 'Total Cargo Weight',
        value: data =>
          `${(data.equipmentWeight || data.materialWeight || 0).toLocaleString('en-sg')} ${ucwords(
            data.equipmentWeightType || data.materialWeightType
          )}`
      });
      list[1].push({
        title: 'Number Of Pieces',
        value: data => `${data.numberPiece || 0} Pcs`
      });
      list[2].push({
        title: 'Trailer Types',
        value: data => `${data.trailerTypeLabel || '-'}`
      });
    }
  }
  return list;
};

const JobInformation: FC<Props> = props => {
  const classes = useStyles();
  const [isShowCarousel, setIsShowCarousel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const showCarousel = (imageIndex: number) => {
    setSelectedImage(imageIndex);
    setIsShowCarousel(true);
  };

  const hideCarousel = () => {
    setIsShowCarousel(false);
  };

  const { job, loading } = props;
  const { jobDate, jobTime, jobType } = job;
  const dateTimeFormatted = `${format(jobDate ? new Date(`${jobDate.replace(/-/g, '/')} ${jobTime}`) : new Date(), 'dd/MM/yyyy, hh:mm a')}`;
  const materialImageUrls = Object.entries(job.MaterialImagesBucket || {})
    .filter(([k, v]) => !!v)
    .map(([k, v]) => v);

  const address1 = [
    {
      title: job.lampPost ? 'Lamp Post' : 'Postal Code',
      value: (data: JobDetail) => data.lampPost as any
    }
  ];

  if (job.isSiteManual) {
    address1.push({
      title: '',
      value: data => (
        <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', color: '#F6B91E' }}>
          <span>
            <ErrorRounded fontSize='small' style={{ color: '#F6B91E' }} />
          </span>
          <span>Please take note! Road name/Lamp post not found.</span>
        </span>
      )
    });
  }

  return (
    <Paper className={classes.jobInformation}>
      <CardContent
        loading={loading}
        title='General Information'
        divider={true}
        data={job}
        column={[
          [
            { title: 'Project Date & Time', value: data => dateTimeFormatted },
            { title: 'Pass Required', value: data => (!!data.pass ? 'Yes' : 'No') },
            { title: 'Transporting Equipment', value: data => (!!data.equipment ? 'Yes' : 'No'), visibility: !(job.truckType === TruckType.TRAILER) },
            { title: 'Type of OU Works', value: data => ucwords(data.workType || '-'), visibility: !(job.jobType === JobType.OU_WORKS) }
          ],
          [
            { title: 'Project Title/Remarks', value: data => data.jobTitle, style: { whiteSpace: 'pre-line' } },
            { title: 'One Day Finished', value: data => (data.oneDayFinish ? 'Yes' : 'No') },
            { title: 'Require Transport', value: data => (data.useTransport ? 'Yes' : 'No'), visibility: !(job.jobType === JobType.OU_WORKS) }
          ],
          [
            {
              title: 'Supervisor Assigned',
              value: data => (data.JobSupervisors || []).map(spv => spv.displayName).join(', ')
            },
            {
              title: !!job.equipment ? 'Equipment' : 'Material',
              value: data => (
                <>
                  {data.equipment || data.materials || '-'}
                  <div style={{ paddingTop: '10px' }}>
                    {Object.entries(data.MaterialImagesBucket || {}).map(([key, val], i) =>
                      val ? (
                        <img
                          src={val}
                          key={i}
                          className={classes.materialImages}
                          alt={`Material #${i + 1}`}
                          onClick={() => {
                            showCarousel(i);
                          }}
                        />
                      ) : null
                    )}
                  </div>
                </>
              )
            }
          ]
        ]}
      />

      <CardContent
        visible={allTaskOneDriverType.includes(job.truckType as TruckType)}
        loading={loading}
        title={job.truckType === TruckType.LORRY_CRANE ? 'Lorry Crane Detail' : 'Trailer Detail'}
        divider={true}
        data={job}
        column={truckTypeInformation(job)}
      />

      <CardContent
        loading={loading}
        title='Job Address'
        divider={jobType === JobType.TRIPWORKS}
        data={job}
        column={[
          address1,
          [{ title: 'Street Name', value: data => data.streetName }],
          [
            {
              title: 'Remarks',
              value: data => data.remarks
            }
          ]
        ]}
      />

      <CardContent
        visible={jobType === JobType.TRIPWORKS}
        loading={loading}
        title='Destination'
        data={job}
        column={[
          [{ title: 'Lamp Post', value: data => data.lampPostDestination }],
          [{ title: 'Street Name', value: data => data.lampPostDestination }],
          [
            {
              title: 'Remarks',
              value: data => data.remarksDestination
            }
          ]
        ]}
      />

      <MaterialImageCarousel onClose={hideCarousel} open={isShowCarousel} imageUrls={materialImageUrls} selectedItem={selectedImage} />
    </Paper>
  );
};

export default JobInformation;
