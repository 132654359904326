import React, { FC } from 'react';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/styles';
import { Fade, Paper, Theme } from '@material-ui/core';

interface Props {
  // this props to open and close popper component
  open: boolean;
  anchorEl: HTMLElement | null;
  placement: any;
  containerWidth: number;
  fadeTransition: number;
  mT?: number;
  mR?: number;
  mB?: number;
  mL?: number;
  keepMounted?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  popper: (props: Props) => ({
    marginTop: theme.spacing(props.mT !== undefined ? props.mT : 0),
    marginRight: theme.spacing(props.mR !== undefined ? props.mR : 0),
    marginBottom: theme.spacing(props.mB !== undefined ? props.mB : 0),
    marginLeft: theme.spacing(props.mL !== undefined ? props.mL : 0),
    width: props.containerWidth,
    zIndex: 99
  }),
  paper: (props: Props) => ({
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: 0
  }),
  clearButton: {
    color: '#89BED3',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#53A0BE'
    },
    padding: theme.spacing(0)
  },
  textFieldFont: {
    fontSize: '12px',
    height: 18
  }
}));

const PositionedPopper: FC<Props> = props => {
  const classes = useStyles(props);
  const { open, anchorEl, placement, fadeTransition, keepMounted = false } = props;

  const { children } = props;

  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} className={classes.popper} transition disablePortal keepMounted={keepMounted}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={fadeTransition}>
          <Paper className={classes.paper}>{children}</Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default PositionedPopper;
