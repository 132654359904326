import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatDistanceToNow } from 'date-fns';
import { Paper, makeStyles, Theme, Grid, Typography } from '@material-ui/core';

import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import { formatCurrency, jobTypeLabel, truckTypeLabel, ucwords } from 'utils';
import JobChip from 'components/JobChip';
import StatusChip from 'components/StatusChip';
import TruckChip from 'components/TruckChip';

interface Props {
  job: JobDetail;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    height: '130px',
    padding: '16px'
  },
  bodyInfo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  subTitle: {
    color: theme.palette.subtitle.main,
    ...theme.typography.body2
  },
  chipDiv: {
    float: 'right'
  },
  chipType: {
    minWidth: '110px'
  },
  otTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letter: '0.4px',
    marginLeft: 4
  }
}));

const InfoCard: FC<Props> = ({ job, isLoading }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Total Earn
            <span className={classes.chipDiv}>
              <JobChip type={job.jobType as JobType} minWidth='110px' workType={job.workType}/>
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? <Skeleton width={100} /> : formatCurrency(job.DriverSummary?.totalEarn || 0)}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Total Driver
            <span className={classes.chipDiv}>
              <TruckChip label={truckTypeLabel(job.truckType)} minWidth='110px' />
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? 0 : job.totalDriver} Driver
          </Typography>
          <Typography variant='body2' className={classes.subTitle}></Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Job Progress
            <span className={classes.chipDiv}>
              <StatusChip status={job.jobStatus as JobStatus} label={ucwords(job.jobStatus)} minWidth='110px' />
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {job.totalCompletedTask} {`${job.taskType} Completed`}
          </Typography>
          <Typography variant='body2' className={classes.subTitle}>
            {job.totalTask} {job.taskRequired}
            <span className={classes.otTitle} style={{ display: !!job.totalHours ? 'inline-block' : 'none' }}>
              + {job.totalHours} Hour(s) Over Time{' '}
            </span>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>Driver Cancelled</Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? 0 : job.totalDriverCancel} Drivers
          </Typography>
          <Typography variant='body2' className={classes.subTitle}>
            {job.updatedAt ? `Updated on ${formatDistanceToNow(new Date(job.updatedAt))} ago` : ''}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InfoCard;
