import React from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import Dialog from 'components/Dialog';
import FileModal from 'components/FileModal';
import logo_empty from 'images/justgo_logo_empty.png';
import { useState } from 'react';

interface Props {
  open: boolean;
  jobNotes?: JobDetail['JobTasks'][0]['JobNotes'];
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  divNote: {
    marginTop: 25,
    border: '1px grey',
    borderRaduis: 5
  },
  divImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginRight: 20
  },
  image: {
    maxHeight: 150,
    maxWidth: 200,
    cursor: 'pointer'
  }
}));

export default function JobNoteModal(props: Props) {
  const classes = useStyles();
  const { open, onClose, jobNotes } = props;

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [currentFileUrl, setCurrentFileUrl] = useState<string>('');

  const handleOpenDetailFile = (url: string) => {
    if (url) {
      setIsDetailOpen(true);
      setCurrentFileUrl(url);
    }
  };

  return (
    <Dialog title='All Job Notes' fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <Grid container justify='space-between'>
        {jobNotes &&
          jobNotes.length > 0 &&
          jobNotes.map((jobNote, index) => (
            <Grid key={index} container direction='row' justify='flex-end' item xs={12} sm={12} md={12} lg={12} className={classes.divNote}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className={classes.divImage}>
                  {jobNote.imageUrl ? (
                    <img onClick={e => handleOpenDetailFile(jobNote.imageUrl!)} src={jobNote.imageUrl} className={classes.image} alt='note img' />
                  ) : (
                    <div>
                      <img src={logo_empty} className={classes.image} alt='note img' />
                      <p style={{ textAlign: 'center' }}>sorry, there is no image available</p>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <span>{jobNote.notes}</span>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <FileModal title='Job Note Image' open={isDetailOpen} setOpen={setIsDetailOpen} fileUrl={currentFileUrl} fileName={''} direct={true} />
    </Dialog>
  );
}
