import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { TRANSACTIONS_BASE_URL } from 'constants/url';
import { format, utcToZonedTime } from 'date-fns-tz';
import axios from 'axios';

const AppContext = createContext({
  activeTopup: false,
  accessTopup: {
    currentId: 0,
    merchantId: 0,
    expireAt: '',
    step: 0,
    token: '',
    key: ''
  },
  setAccessTopup: (value: any) => {},
  clearAccessTopup: () => {},
  clearSuccess: () => {},
  date: (date: Date | string = new Date(), pattern = 'dd/MM/yyyy HH:mm a') => {
    const zonedDate = utcToZonedTime(date, 'Asia/Singapore');
    return format(zonedDate, pattern, { timeZone: 'Asia/Singapore' });
  }
});

const AppProvider = (props: any) => {
  const currentTopup = localStorage.getItem('topup');
  const [topup, setTopup] = useState({ expireAt: '', currentId: 0, merchantId: 0, step: 0, token: '', key: '' });
  const [activeTopup, setActiveTopup] = useState(false);

  const cancelTopup = async (trxId: number) => {
    try {
      if (trxId > 0) {
        await axios.post(`${TRANSACTIONS_BASE_URL}/cancel/${trxId}/topup`);
      }
    } catch (e) {
      console.log('err', e);
    }
  };

  const clearAccessTopup = () => {
    cancelTopup(topup.currentId);
    setActiveTopup(false);
    setTopup({ expireAt: '', step: 0, token: '', currentId: 0, merchantId: 0, key: '' });
    localStorage.removeItem('topup');
  };

  const clearSuccess = () => {
    setTopup({ expireAt: '', step: 0, token: '', currentId: 0, merchantId: 0, key: '' });
    setActiveTopup(false);
    localStorage.removeItem('topup');
  };

  useEffect(() => {
    if (!currentTopup) return;
    const _topUp = JSON.parse(currentTopup);
    const _active = _topUp.expireAt >= new Date().toString();

    if (!_active) {
      cancelTopup(_topUp.currentId);
      localStorage.removeItem('topup');
    }
    setActiveTopup(_active);
  }, [currentTopup]);

  return (
    <AppContext.Provider
      value={{
        activeTopup,
        accessTopup: topup,
        setAccessTopup: value => {
          setTopup(state => ({ ...state, ...value }));
          const currentTopup = localStorage.getItem('topup');
          if (currentTopup) {
            localStorage.setItem('topup', JSON.stringify({ ...JSON.parse(currentTopup), ...value }));
          } else {
            localStorage.setItem('topup', JSON.stringify({ ...topup, ...value }));
          }
        },
        clearAccessTopup,
        clearSuccess,
        date: (date: Date | string = new Date(), pattern = 'dd/MM/yyyy hh:mm a') => {
          const zonedDate = utcToZonedTime(date, 'Asia/Singapore');
          return format(zonedDate, pattern, { timeZone: 'Asia/Singapore' });
        }
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useApp };
