import React, { FC, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { dummyDriver } from 'constants/dummy';
import { INDIVIDUAL_DRIVER_URL } from 'constants/url';
import DriverInformation from './DriverInformation';
import MainTemplate from 'components/Template/MainTemplate';
import TabPanel, { a11yProps } from 'components/TabPanel';
import useStyles from './style';
import JobDriverTable from './JobDriverTable';

const DriverDetailPage: FC = () => {
  const classes = useStyles();
  const { match } = useRouter();
  const id = Number(match.params.id);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const [value, setValue] = useState(0);
  const [driver, setDriver] = useState<DriverModel>(dummyDriver);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(INDIVIDUAL_DRIVER_URL(id), { cancelToken: cancelTokenSource.token });
      setDriver(data);
    } catch (err) {
      console.error('err: ', err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
    return () => cancelTokenSource.cancel();
    // eslint-disable-next-line
  }, []);

  return (
    <MainTemplate title='Driver Detail' subtitle={driver.Merchant.User.displayName} breadcrumb={true} refreshButton={false} primaryButton={false}>
      <Paper elevation={0} className={classes.cardTab}>
        <Tabs indicatorColor='primary' value={value} onChange={handleChangeTab} className={classes.tabs}>
          <Tab label='General Information' {...a11yProps(0)} className={classes.tab} />
          <Tab label='Jobs List' {...a11yProps(1)} className={classes.tab} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className={classes.tabPanel}>
            <DriverInformation driver={driver} isLoadingData={isLoadingData} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.tabPanelList}>
            <JobDriverTable  driverId={driver.id} />
          </div>
        </TabPanel>
      </Paper>
    </MainTemplate>
  );
};

export default DriverDetailPage;
