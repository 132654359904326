import React, { forwardRef } from 'react';
import { CSVLink as ReactCSVLink } from 'react-csv';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
interface Props {
  headers: LabelKeyObject[];
  data: any[];
  filename?: string;
}

const CSVLink = forwardRef<any, Props>(({ data, headers, filename }, ref) => {
  return <ReactCSVLink headers={headers} filename={filename} data={data} ref={ref} />;
});

export default CSVLink;
