import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '8px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  badgePending: {
    width: '24px',
    height: '24Px',
    top: '-0.4em',
    right: '-1.0em',
    borderRadius: '100px',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: theme.palette.error.main
  },
  iconButton: {
    padding: '8px',
    borderRadius: '5px',
    minWidth: 'unset',
    border: `1.5px solid ${theme.palette.primary.main}`,
    marginRight: '8px'
  },
  boxButtonRight: {
    padding: '8px'
  }
}));

export default useStyles;
