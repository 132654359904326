import React from 'react';
import Modal from 'components/Modal';
import { Box, IconButton, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { UseForm } from 'hooks/useForm';
import { UseValidation } from 'hooks/useValidation';
import { CloudUpload, Delete, Undo } from '@material-ui/icons';
import useStyles from './style';

export type DriverForm = {
  id: number;
  displayName: string;
  contactNumber: string;
  email: string;
  vehicleNumber: string;
  vehicleLogCard: string;
  vehicleFileUrl?: string;
  isManager?: boolean;
};
export interface DriverFormModalProps {
  open: boolean;
  form: UseForm<DriverForm>;
  serverError: {
    process: boolean;
    email: boolean;
    contactNumber: boolean;
    emailMessage: string;
    contactNumberMessage: string;
  };
  validation: UseValidation<DriverForm>;
  imageFile: {
    file?: File;
    fileName?: string;
    originalName?: string;
    currentFile?: string;
  };
  disabledConfirm?: boolean;
  serverValidate: (key: 'contactNumber' | 'email' | 'vehicleNumber') => Promise<void>;
  onConfirm: () => void;
  onCancel: () => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
  onUndo: () => void;
}

const DriverModalForm = ({
  open,
  form,
  serverError,
  imageFile,
  validation,
  disabledConfirm,
  serverValidate,
  onConfirm,
  onCancel,
  onUpload,
  onRemove,
  onUndo
}: DriverFormModalProps) => {
  const classes = useStyles();
  const isNew = form.data.id === 0;

  return (
    <Modal
      title={isNew ? 'Create New Driver' : 'Driver'}
      open={open}
      loading={form.processing}
      maxWidth='xs'
      fullWidth
      disableConfirm={disabledConfirm}
      isNew={isNew}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onClose={onCancel}
    >
      <Box display='flex' flexDirection='column' className={classes.box}>
        <TextField
          variant='outlined'
          label='Driver Name'
          value={form.data.displayName || ''}
          onChange={e => form.setData({ displayName: e.currentTarget.value })}
          error={validation.errors.displayName.invalid}
          helperText={validation.errors.displayName.message}
        />
        <TextField
          id={'phoneNumber'}
          variant='outlined'
          label='Phone No'
          required
          value={form.data.contactNumber || ''}
          onChange={e => {
           if(Number.isInteger(+e.currentTarget.value)) {
            form.setData({ contactNumber: e.currentTarget.value })
           } 
          } }
          onBlur={async () => {
            const check = await validation.validate('contactNumber');
            if (check) {
              serverValidate('contactNumber');
            }
          }}
          InputProps={{
            inputProps: {
              maxLength: 8
            }
          }}
          error={serverError.contactNumber || validation.errors.contactNumber.invalid}
          helperText={serverError.contactNumberMessage || validation.errors.contactNumber.message}
        />
        <TextField
          id='email'
          variant='outlined'
          label='Email'
          required
          value={form.data.email || ''}
          onChange={e => form.setData({ email: e.currentTarget.value })}
          onBlur={async () => {
            const check = await validation.validate('email');
            if (check) {
              serverValidate('email');
            }
          }}
          error={serverError.email || validation.errors.email.invalid}
          helperText={serverError.emailMessage || validation.errors.email.message}
        />

        <TextField
          variant='outlined'
          label='Vehicle No'
          required={!form.data.isManager}
          value={form.data.vehicleNumber || ''}
          onChange={e => form.setData({ vehicleNumber: e.currentTarget.value })}
          error={validation.errors.vehicleNumber?.invalid}
          helperText={validation.errors.vehicleNumber?.message}
          onBlur={async () => await validation.validate('vehicleNumber')}
        />

        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='subtitle1'>Set as Driver Manager?</Typography>
          <div>
            <Switch
              color='primary'
              value={form.data.isManager}
              checked={form.data.isManager}
              onChange={(e, checked) => {
                form.setData({ isManager: checked });
                validation.omit(checked ? ['vehicleNumber'] : []);
              }}
            />
          </div>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' flexGrow={1}>
            <div className={classes.buttonFileBox}>
              <label htmlFor='button-file'>
                <span className={classes.uploadBox}>
                  <span>
                    <CloudUpload fontSize='small' />
                  </span>
                  <span>Upload Document</span>
                </span>
                <input id='button-file' type='file' name='file' style={{ display: 'none' }} onChange={onUpload} />
              </label>
            </div>

            {imageFile.originalName && (
              <div className={classes.noteFileBox}>
                <div className={classes.fileBox}>
                  <div className='fileName'>{imageFile.originalName || ''}</div>
                  <div>
                    <IconButton size='small' onClick={onRemove}>
                      <Delete fontSize='small' />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
          </Box>

          {imageFile.currentFile && (
            <Tooltip title='Undo'>
              <IconButton size='small' onClick={onUndo}>
                <Undo fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DriverModalForm;
