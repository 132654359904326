import React, { FC } from 'react';
import { TableRow, Link, Chip, IconButton, Tooltip } from '@material-ui/core';
import { formatId } from 'utils';
import { Edit } from '@material-ui/icons';

import BodyCell from 'components/BodyCell';
import useStyles from './style';
interface Props {
  index: number;
  driver: DriverModel;
  handleOpenDetailClick: (id: number) => void;
  onEdit: () => void;
}

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { driver, handleOpenDetailClick, onEdit } = props;
  const { id, vehicleNumber, User, isManager } = driver;
  const { displayName, contactNumber, email } = User;

  return (
    <TableRow hover>
      <BodyCell cellWidth='20%'>{formatId(id)}</BodyCell>
      <BodyCell cellWidth='20%'>
        <div className={classes.idLink} onClick={() => handleOpenDetailClick(id)}>
          {displayName}&nbsp;
          {isManager ? (
            <Chip
              size='small'
              label='Manager'
              color='primary'
              classes={{ outlinedPrimary: classes.managerChipRoot, labelSmall: classes.managerChipLabel }}
            />
          ) : null}
        </div>
      </BodyCell>
      <BodyCell cellWidth='20%'>{email}</BodyCell>
      <BodyCell cellWidth='20%'>
        <Link href={`https://api.whatsapp.com/send?phone=65${contactNumber}`} target='_blank' className={classes.idLink}>
          {contactNumber}
        </Link>
      </BodyCell>
      <BodyCell cellWidth='20%'>{vehicleNumber}</BodyCell>
      <BodyCell cellWidth='20%' align='center'>
        <Tooltip title='Edit'>
          <IconButton size='small' onClick={onEdit}>
            <Edit fontSize='small' />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
