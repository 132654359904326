import React from 'react';
import {
  TableCell,
  TableRow
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

interface LoadingRowsProps {
  headersLength: number;
  rowsPerPage: number;
}

const LoadingRows = ({ headersLength, rowsPerPage }: LoadingRowsProps) => {
  const rows = [...Array.from({ length: rowsPerPage }).keys()];
  const columns = [...Array.from({ length: headersLength }).keys()];
  return (
    <>
      {rows.map((key) => (
        <TableRow key={`loading-row-${key}`}>
          {columns.map((col) => (
            <TableCell key={`loading-col-${col}`}>
              <Skeleton width={80} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default LoadingRows;
