import React, { forwardRef } from 'react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

interface Props {
  data: any[];
}

export const headerDriverJob = [
  { label: 'Task ID', key: 'id' },
  { label: 'Job ID', key: 'JobId' },
  { label: 'Date', key: 'jobDate' },
  { label: 'Job Owner', key: 'companyName' },
  { label: 'Job Type', key: 'jobType' },
  { label: 'Job Status', key: 'jobTaskStatus' },
  { label: 'Project Date & Time', key: 'jobTime' },
  { label: 'Date Completed', key: 'completedDate' },
  { label: 'Duration', key: 'hours' },
  { label: 'Start Location', key: 'streetName' },
  { label: 'Destination Location', key: 'streetNameDestination' },
  { label: 'Material', key: 'materials' },
  { label: 'Vehicle Type', key: 'truckType' },
  { label: 'Vehicle No.', key: 'vehicleNumber' },
  { label: 'Drivers', key: 'driverName' },
  { label: 'Price per task', key: 'price' },
  { label: 'Qty', key: 'qty' },
  { label: 'Total Price', key: 'taskEarn' },
  { label: 'App Fee', key: 'appFee' },
  { label: 'Grand Total', key: 'netEarn' },
  { label: 'GST Amount', key: 'gstTaskAmount' }
];

const JobCSVLink = forwardRef<any, Props>(({ data }, ref) => {
  return <CSVLink headers={headerDriverJob} filename={`driver jobs ${format(new Date(), 'd-MM-Y')}.csv`} data={data} ref={ref} />;
});

export default JobCSVLink;
