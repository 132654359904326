import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { formatCurrency } from 'utils';
import useStyles from './styles';
import axios, { CancelTokenSource } from 'axios';
import cardBase from 'images/icon/card-base.svg';
import { GET_DETAIL_MERCHANTS_BASE_URL } from 'constants/url';

const MerchantCardTotalBalance = () => {
  const abort: CancelTokenSource = axios.CancelToken.source();
  const classes = useStyles();

  const [merchant, setMerchant] = useState({
    balance: 0,
  });

  const getMerchant = async () => {
    try {
      const { data } = await axios.get(GET_DETAIL_MERCHANTS_BASE_URL, { cancelToken: abort.token });
      setMerchant({balance: data.balance || 0});
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getMerchant();
    return () => {
      abort.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.cardBalance}>
        <div>
          <img src={cardBase} alt='card base' />
        </div>
        <Box flexGrow={1}>
          <Typography variant='h5' gutterBottom>
          Total Account Balance
          </Typography>

          <Typography variant='h1'>{formatCurrency(merchant.balance)}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default MerchantCardTotalBalance;
