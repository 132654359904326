import React, { forwardRef, useState, useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';

import { TRANSACTIONS_BASE_URL } from 'constants/url';
import { ExpandMore } from '@material-ui/icons';
import MerchantDownloadEearning from '../MerchantDownloadEarning';
import MerchantDownloadSpending from '../MerchantDownloadSpending';
import { removeEmpty } from 'utils';
import { format } from 'date-fns';
import CSVLink from 'components/CSVLink';

interface Props {
  filters?: any;
  sortBy?: string;
  sortDir?: string;
  className?: string;
}

const currentListHeaders = [
  { label: 'Trx ID', key: 'id' },
  { label: 'Date Time', key: 'dateTime' },
  { label: 'Description', key: 'description' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Amount', key: 'amount' },
  { label: 'Status', key: 'status' }
];
const allTrxHeaders = [
  { label: 'Job ID', key: 'jobId' },
  { label: 'Owner', key: 'jobOwner' },
  { label: 'Transaction Type', key: 'trxType' },
  { label: 'Date', key: 'trxDate' },
  { label: 'Job Start Time', key: 'jobTime' },
  { label: 'Job End Time', key: 'jobEndTime' },
  { label: 'Duration', key: 'duration' },
  { label: 'Start Location', key: 'startLocation' },
  { label: 'Destination Location', key: 'destinationLocation' },
  { label: 'Material', key: 'material' },
  { label: 'Vehicle no.', key: 'vehicleNo' },
  { label: 'Driver Name', key: 'driverName' },
  { label: 'Supervisor Name', key: 'supervisorName' },
  { label: 'Price per Task', key: 'pricePerTask' },
  { label: 'Qty (no. of tasks)', key: 'qty' },
  { label: 'Total Price', key: 'totalPrice' },
  { label: 'GST Amount', key: 'gstAmount' },
  { label: 'Grand Total', key: 'grandTotal' }
];
const allBalanceHeaders = [
  { label: 'Merchant ID', key: 'id' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Current Balance', key: 'balance' }
];

const TransactionExportButton = forwardRef<any, Props>((props, ref) => {
  const { filters, sortBy, sortDir, className } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [data, setData] = useState<TransactionCSVModel[] | AllTransactionCSVModel[] | AllMerchantBalanceCSVModel[]>([]);
  const [exportType, setExportType] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDownloadEarning, setOpenDownloadEarning] = useState(false);
  const [openDownloadSpending, setOpenDownloadSpending] = useState(false);
  const [filename, setFilename] = useState('current trx.csv');
  const csvInstance = useRef<any | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrentListExport = async () => {
    try {
      setAnchorEl(null);
      setExportType('current');
      setLoading(true);
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      let currentFilter = filters;

      if (!currentFilter.startDate && !currentFilter.endDate) {
        const today = new Date();
        currentFilter = { ...currentFilter, startDate: format(new Date(today.getFullYear(), today.getMonth(), 1), 'yyyy-MM-dd'), endDate: format(new Date(today.getFullYear(), today.getMonth() + 1, 0), 'yyyy-MM-dd') };
      }
      setFilename(`current trx ${currentFilter.startDate} until ${currentFilter.endDate}.csv`);
      const { data } = await axios.get(TRANSACTIONS_BASE_URL, {
        params: { ...removeEmpty(currentFilter), sort: sortBy, order: sortDir },
        cancelToken: cancelTokenSource.token
      });

      let csvData: TransactionCSVModel[] = [];
      if (data.transactions && data.transactions.length > 0) {
        await Promise.all(
          data.transactions.map(async (value: any) => {
            let description = '';
            if (value.type === 'TOPUP') {
              description += ' - ';
              description += value.PaidMerchant?.companyName;
            } else if (value.type === 'WITHDRAW') {
              description += ' - ';
              description += value.PayingMerchant?.companyName;
            }
            csvData.push({
              id: value.id,
              dateTime: value.createdAt,
              description: `${value.type}${description}`,
              paymentMethod: value.paymentMethod,
              amount: value.amount,
              status: value.status
            });
          })
        );
      }
      setData(csvData);
      const timer = setTimeout(() => {
        csvInstance.current.link.click();
        setLoading(false);
        clearTimeout(timer);
      }, 1000);
    } catch (e) {
      setLoading(false);
    }
  };

  const headers = exportType === 'current' ? currentListHeaders : exportType === 'all' ? allTrxHeaders : allBalanceHeaders;
  return (
    <>
      <Button
        disabled={loading}
        aria-controls='export-menu'
        variant='contained'
        disableElevation
        aria-haspopup='true'
        color='primary'
        onClick={handleClick}
        className={className}
        ref={ref}
      >
        {loading && <CircularProgress color='inherit' size={24} />}

        {!loading && (
          <>
            Export Transactions <ExpandMore fontSize='small' style={{ marginLeft: '8px' }} />
          </>
        )}
      </Button>
      <Menu id='export-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleCurrentListExport}>Export Current List</MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDownloadEarning(true);
            handleClose();
          }}
        >
          Export Earning Summary
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDownloadSpending(true);
            handleClose();
          }}
        >
          Export Spending Summary
        </MenuItem>
        {/* <MenuItem onClick={handleAllTrxExport}>Export All Transactions</MenuItem> */}
      </Menu>
      <CSVLink filename={filename} headers={headers} data={data} ref={csvInstance} />

      <MerchantDownloadEearning
        open={openDownloadEarning}
        onClose={() => {
          setOpenDownloadEarning(false);
        }}
      />

      <MerchantDownloadSpending
        open={openDownloadSpending}
        onClose={() => {
          setOpenDownloadSpending(false);
        }}
      />
    </>
  );
});

export default TransactionExportButton;
