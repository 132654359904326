import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, InputAdornment, TableContainer } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';
import Role from 'typings/enum/Role';
import TableLoading from 'components/Table/TableLoading';
import TableEmpty from 'components/Table/TableEmpty';
import { UseTablePagination } from 'hooks/useTablePagination';

interface Props {
  table: UseTablePagination<SupervisorModel>;
  handleDetailClick: (supervisor: SupervisorModel) => void;
  onEdit: (id: number) => () => void;
}

const supervisorType: Select[] = [
  { id: Role.SUPERVISOR_TIER_001, name: Role.SUPERVISOR_TIER_001 },
  { id: Role.SUPERVISOR_TIER_002, name: Role.SUPERVISOR_TIER_002 },
  { id: Role.SUPERVISOR_TIER_003, name: Role.SUPERVISOR_TIER_003 }
];

const SupervisorTable: FC<Props> = props => {
  const { onEdit, table, handleDetailClick } = props;
  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={table.order}
            orderBy={table.orderBy}
            onRequestSort={(event, property) => table.onOrder(property)}
            height={'unset'}
            headers={[
              { id: 'id', label: 'ID', sort: true },
              { id: 'displayName', label: 'Display Name', sort: true },
              { id: 'type', label: 'Account Type', sort: true, align: 'center' },
              { id: 'email', label: 'Email', sort: true },
              { id: 'contactNumber', label: 'Phone', sort: true },
              { id: 'action', label: 'Action', align: 'center' }
            ]}
          />

          <HeaderRow
            height='0px'
            headers={[
              {
                id: 'findId',
                isInputText: true,
                value: table.query('queryId'),
                handleInputChange: value => table.setQuery({ queryId: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findDisplayName',
                isInputText: true,
                value: table.query('queryName'),
                handleInputChange: value => table.setQuery({ queryName: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findType',
                label: 'Select Type',
                isSelect: true,
                value: table.query('type'),
                selectOption: supervisorType,
                handleOptionChange: select => table.setQuery({ type: select }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findEmail',
                isInputText: true,
                value: table.query('queryEmail'),
                handleInputChange: value => table.setQuery({ queryEmail: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findContactNumber',
                isInputText: true,
                value: table.query('queryContact'),
                handleInputChange: value => table.setQuery({ queryContact: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              { id: 'action' }
            ]}
          />
        </TableHead>
        <TableBody>
          {table.loading ? (
            <TableLoading col={6} />
          ) : table.data.length > 0 ? (
            table.data.map((value, index) => (
              <BodyRow index={index} key={index} supervisor={value} handleDetailClick={handleDetailClick} onEdit={onEdit(value.id)} />
            ))
          ) : (
            <TableEmpty col={6} />
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={table.count}
            rowsPerPage={table.perPage}
            page={table.page}
            onChangePage={table.onPageChange}
            onChangeRowsPerPage={table.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SupervisorTable;
