import { useState } from 'react';

function usePagination() {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);

  const changePage = (event: unknown, newPage: number) => { setCurrentPage(newPage); };
  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    setRowsPerPage(+event.target.value);
  };

  return {
    currentPage,
    rowsPerPage,
    count,
    changePage,
    changeRowsPerPage,
    setCount,
    setCurrentPage
  };
}

export default usePagination;
