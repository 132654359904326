import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import clsx from 'clsx';
import { TRANSACTIONS_BASE_URL } from 'constants/url';
import { useState } from 'react';
import { listMonths, listYears } from 'utils';
import { format } from 'date-fns';
import useStyles from './styles';


interface MerchantDownloadEearningProps {
  open: boolean;
  onClose: () => void;
}

const MerchantDownloadEearning = ({ open, onClose }: MerchantDownloadEearningProps) => {
  const classes = useStyles();
  const currentDate = new Date();
  const [params, setParams] = useState({ month: currentDate.getMonth() + 1, year: currentDate.getFullYear() });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setSuccess(false);
    try {
      const { data } = await axios.get(`${TRANSACTIONS_BASE_URL}/earn-summary`, {
        params: { ...params, month: String(params.month).padStart(2, '0') },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Earn Summary 01-${String(params.month).padStart(2, '0')}-${params.year} until ${format(
          new Date(params.year, params.month, 0),
          'dd-MM-yyyy'
        )}.pdf`
      );
      document.body.appendChild(link);
      link.click();

      setSuccess(data.success);
    } catch (error) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const onCloseDialog = () => {
    onClose();
    setSuccess(false);
    setParams({ month: currentDate.getMonth() + 1, year: currentDate.getFullYear() });
  };

  return (
    <Dialog open={open} maxWidth='md' className={classes.dialog} disableEscapeKeyDown disableBackdropClick>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography align='left' variant='h5' style={{ flexGrow: 1 }}>
          Download Earning Summary
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        {success ? (
          <Alert variant='outlined' severity={success ? 'success' : 'error'} style={{ width: '100%' }}>
            <AlertTitle>{success ? 'Success' : 'Error'}</AlertTitle>
            {success ? 'Pelase check email' : 'Please contact Developer'}
          </Alert>
        ) : (
          <>
            <TextField
              variant='outlined'
              size='small'
              InputLabelProps={{ shrink: true }}
              label='Select Month'
              select
              value={params.month}
              onChange={e => setParams(p => ({ ...p, month: +e.target.value }))}
              style={{ flexGrow: 1 }}
            >
              {listMonths.map(v => (
                <MenuItem key={v.month} value={v.month}>
                  {v.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              variant='outlined'
              size='small'
              InputLabelProps={{ shrink: true }}
              label='Select Year'
              select
              value={params.year}
              onChange={e => setParams(p => ({ ...p, year: +e.target.value }))}
              style={{ minWidth: '30%' }}
            >
              {listYears().map(v => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant={success ? 'text' : 'outlined'}
          color='primary'
          fullWidth={success}
          disabled={loading}
          disableElevation
          className={clsx({ [classes.leftButton]: !success })}
          onClick={onCloseDialog}
        >
          {success ? 'Close' : 'Cancel'}
        </Button>

        {success ? null : (
          <Button disabled={loading} variant='contained' color='primary' disableElevation className={classes.rightButton} onClick={onSubmit}>
            {loading ? <CircularProgress color='inherit' size={24} /> : 'Download'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantDownloadEearning;
