import React, { useState } from 'react';
import { TableCell, TableRow, Collapse, Box, IconButton, Checkbox, makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface Props {
  data: any;
  headers: Array<HeaderProps>;
  subRow?: (row: any) => React.ReactNode;
  isSelectable?: boolean;
  onSelectItem?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  selectedItems?: any[];
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    minHeight: '0px'
  },
  cellRoot: {
    '&.MuiTableCell-root': {
      padding: '8px 16px 8px 16px'
    }
  }
}));

function DataRow({ data, headers, subRow, isSelectable, onSelectItem, selectedItems }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const toggleSubRow = () => setOpen(!open);

  const isSelected = (name: string) => selectedItems && selectedItems.indexOf(name) !== -1;

  return (
    <>
      <TableRow hover>
        {isSelectable && (
          <TableCell className={classes.cellRoot} padding='checkbox'>
            <Checkbox
              checked={isSelected(data.id)}
              onChange={event => {
                onSelectItem && onSelectItem(event, data.id);
              }}
            />
          </TableCell>
        )}
        {subRow ? (
          <TableCell className={classes.cellRoot}>
            <IconButton aria-label='expand row' size='small' onClick={toggleSubRow}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        {headers.map(({ id, render, align = 'inherit', width }) => (
          <TableCell className={classes.cellRoot} key={`body-cell-${data.id}-${id}`} align={align} style={{ width }}>
            {render ? render(data) : data[id]}
          </TableCell>
        ))}
      </TableRow>
      {subRow ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={1}>{subRow(data)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

export default DataRow;
