import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
  Theme,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  supervisor?: SupervisorModel;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  itemText: {
    color: '#828282',
    fontSize: '13px',
    paddingLeft: 0
  },
  itemSecondary: {
    fontWeight: 600,
    fontSize: '13px'
  },
  linkText: {
    color: theme.palette.link.main
  },
  gutters: {
    paddingLeft: 0
  },
}));

const dialogStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function SupervisorDetailModal(props: Props) {
  const classes = useStyles();
  const { open, onClose, supervisor } = props;
  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <DialogTitle id='dialogTitle' onClose={onClose}>
        <Typography variant='h4'>Supervisor Details</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='h5' gutterBottom>
          Personal Information
        </Typography>
        <List component='div' dense={true}>
          <ListItem classes={{gutters: classes.gutters}}>
            <ListItemText className={classes.itemText}>Tier Number</ListItemText>
            <ListItemSecondaryAction className={classes.itemSecondary}>{supervisor?.User?.role}</ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{gutters: classes.gutters}}>
            <ListItemText className={classes.itemText}>Name</ListItemText>
            <ListItemSecondaryAction className={classes.itemSecondary}>{supervisor?.User?.displayName}</ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{gutters: classes.gutters}}>
            <ListItemText className={classes.itemText}>Email</ListItemText>
            <ListItemSecondaryAction className={classes.itemSecondary}>{supervisor?.User?.email}</ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{gutters: classes.gutters}}>
            <ListItemText className={classes.itemText}>Phone No.</ListItemText>
            <ListItemSecondaryAction className={classes.itemSecondary}>+65 {supervisor?.User?.contactNumber}</ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}
