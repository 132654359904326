import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Typography,
  Checkbox,
  TableContainerProps
} from '@material-ui/core';

import LoadingRows from './LoadingRows';
import DataRow from './DataRow';

interface Props {
  isLoading: boolean;
  data: any[];
  headers: HeaderProps[];
  count: number;
  rowsPerPage: number;
  currentPage: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortBy: string;
  sortDir: 'asc' | 'desc';
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  subRow?: (row: any) => React.ReactNode;
  isSelectable?: boolean;
  onSelectAll?: (selectedItems: any[]) => void;
  onSelectItem?: (selectedItems: any[]) => void;
  showFilterRow?: boolean;
  containerProps?: TableContainerProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    headerCell: {
      fontSize: '13px',
      fontWeight: 600,
      color: '#99A7B7'
    },
    rowTable: {
      cursor: 'pointer'
    },
    filterCell: {
      padding: '6px 0 6px 6px'
    },
    tableCell: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.5px',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
);

function DataTable({
  isLoading,
  data,
  headers,
  sortBy,
  sortDir,
  onRequestSort,
  count,
  rowsPerPage,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  subRow,
  isSelectable,
  onSelectAll,
  onSelectItem,
  showFilterRow = true,
  containerProps
}: Props) {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>([]);
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map(item => item.id);
      setSelected(newSelected);
      if (onSelectAll) {
        onSelectAll(newSelected);
      }
      return;
    }
    setSelected([]);
    if (onSelectAll) {
      onSelectAll([]);
    }
  };
  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      const newSelected = [...selected, id];
      setSelected(newSelected);
      if (onSelectItem) {
        onSelectItem(newSelected);
      }
      return;
    }
    const newSelected = selected.filter(item => item !== id);
    setSelected(newSelected);
    if (onSelectItem) {
      onSelectItem(newSelected);
    }
  };
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const renderRows = () => {
    if (isLoading) {
      return <LoadingRows headersLength={headers.length} rowsPerPage={rowsPerPage} />;
    }

    if (data.length === 0) {
      return (
        <TableRow>
          <TableCell align='center' colSpan={100}>
            Data Not Available.
          </TableCell>
        </TableRow>
      );
    }

    return data.map(d => (
      <DataRow
        key={`data-row-${d.id}`}
        data={d}
        headers={headers}
        subRow={subRow}
        isSelectable={isSelectable}
        onSelectItem={handleSelectItem}
        selectedItems={selected}
      />
    ));
  };

  return (
    <TableContainer {...containerProps}>
      <Table aria-label='data table'>
        <TableHead>
          <TableRow>
            {isSelectable && (
              <TableCell padding='checkbox'>
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < data.length}
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={handleSelectAll}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              </TableCell>
            )}
            {subRow ? <TableCell /> : null}
            {headers.map(({ id, label, sortable }) => (
              <TableCell key={`header-cell-${id}`} align='center' padding='default' sortDirection={sortBy === id ? sortDir : false}>
                {sortable ? (
                  <TableSortLabel active={sortBy === id} direction={sortBy === id ? sortDir : 'asc'} onClick={createSortHandler(id)}>
                    <Typography variant='body1' className={classes.tableCell}>
                      {label}
                    </Typography>
                    {sortBy === id ? (
                      <span className={classes.visuallyHidden}>{sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Typography variant='body1' className={classes.tableCell}>
                    {label}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>

          {showFilterRow && (
            <TableRow>
              {isSelectable && <TableCell />}
              {subRow ? <TableCell /> : null}
              {headers.map(({ filter }, index) => (
                <TableCell key={`filter-cell-${index}`} align='left' className={classes.filterCell}>
                  {filter}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>

        <TableBody>{renderRows()}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default DataTable;
