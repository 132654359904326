import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export interface TableLoadingProps {
  col: number;
}

const TableLoading = ({ col }: TableLoadingProps) => {
  return (
    <>
      <TableRow>
        {[...Array(col)].map((_, i) => (
          <TableCell key={'cell-loading-1_' + i} size='small'>
            <Skeleton width='100%' />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {[...Array(col)].map((_, i) => (
          <TableCell key={'cell-loading-2_' + i} size='small'>
            <Skeleton width='100%' />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {[...Array(col)].map((_, i) => (
          <TableCell key={'cell-loading-3_' + i} size='small'>
            <Skeleton width='100%' />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {[...Array(col)].map((_, i) => (
          <TableCell key={'cell-loading-4_' + i} size='small'>
            <Skeleton width='100%' />
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default TableLoading;
