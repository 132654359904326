import React, { FC } from 'react';
import { formatCurrency} from 'utils';
import { Theme, makeStyles, Table, TableBody, TableHead, TableRow, TableCell, Paper, TableContainer, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import TruckType from 'typings/enum/TruckType';

interface Props {
  job: JobDetail;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  },
  subtotal: {
    width: '15%'
  },
  unitPrice: {
    width: '15%'
  },
  qty: {
    width: '15%'
  },
  disabledBorderBottom: {
    borderBottom: 'none'
  },
  orangeTitle: {
    color: theme.palette.primary.main
  }
}));

export const priceAdditional = (job: JobDetail) => {
  const prices = [];

  prices.push({
    type: 'data',
    qty: job.totalTask,
    subtotal: formatCurrency(job.DriverSummary?.totalOriginalTask || 0),
    price: formatCurrency(job.DriverSummary?.originalTask || 0),
    gst: formatCurrency(job.DriverSummary?.totalOriginalGst || 0),
    description: 'Total Task'
  });

  (job.JobAdditionalHours || []).forEach(value => {
    prices.push({
      type: 'data',
      qty: `${Math.abs(value.hours / 60)} hour(s)`,
      subtotal: formatCurrency(Math.abs(value.totalPrice)),
      price: formatCurrency(Math.abs(value.hourPrice)),
      gst: formatCurrency(value.gstTotalPrice),
      description: value.hours > 0 ? [TruckType.LORRY_CRANE, TruckType.TRAILER].includes(job.truckType as TruckType) ? 'Overtime(s)' : 'Add Hour(s)' : 'Reduce Hour(s)'
    })
  })

  return prices;
};

const PriceTable: FC<Props> = ({ job }) => {
  const classes = useStyles();
  const priceDetails = priceAdditional(job);
  
  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Price Detail
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component='th'>Description</TableCell>
              
              <TableCell component='th' align='right' className={classes.unitPrice}>
                Unit Price
              </TableCell>

              <TableCell component='th' align='center' className={classes.qty}>
                QTY
              </TableCell>
              
              <TableCell component='th' align='right' className={classes.subtotal}>
                Subtotal
              </TableCell>

              <TableCell component='th' align='right' style={{ maxWidth: '2%', width: '5%' }}>
                GST({job.gstPercent}%)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {priceDetails.map((v, i) =>
              v.type === 'title' ? (
                <TableRow key={i + '-key'}>
                  <TableCell size='small' colSpan={4} style={{ borderBottom: '0px' }}>
                    {v.description}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={i + '-key'}>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}) }}
                  >
                    {v.description}
                  </TableCell>
                  
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}) }}
                    align='right'
                  >
                    {v.price}
                  </TableCell>
                
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}) }}
                    align='center'
                  >
                    {v.qty}
                  </TableCell>

                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0, } : {paddingRight: 0}) }}
                    className={classes.subtotal}
                    align='right'
                  >
                    {v.subtotal}
                  </TableCell>

                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {  whiteSpace: 'nowrap'}) }}
                    align='right'
                  >
                    {v.gst}
                  </TableCell>
                </TableRow>
              )
            )}


            <TableRow>
              <TableCell  className={classes.disabledBorderBottom}></TableCell>
              <TableCell colSpan={2} align='right' >
                Total Price
              </TableCell>
              <TableCell align='right' className={clsx(classes.subtotal)} style={{paddingRight: 0}}>
                <strong>{formatCurrency(Math.abs(job.DriverSummary?.totalOriginalTask || 0) + (job.JobAdditional?.totalPrice || 0))}</strong>
              </TableCell>
              <TableCell align='right' >
              <strong>
                  ({formatCurrency((job.DriverSummary?.totalOriginalGst ||0) + (job.JobAdditional?.gstTotalPrice||0))})
                </strong>
              </TableCell>
            </TableRow>

          

        
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PriceTable;
