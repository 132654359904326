import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      paddingTop: '8px'
    }
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  content: {
    width:"500px",
    padding: '24px 26px 16px 24px',
    display: "flex",
    flex: 1,
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    columnGap: 8
  },
  box: {
    paddingBottom: '24px'
  },
  iconButton: {
    marginLeft: "20px"
  },
  actions: {
    padding: '8px 24px 16px 24px'
  },
  rightButton: {
    textTransform: 'none'
  },
  leftButton: {
    border: `1.5px solid ${theme.palette.orange['500']}`,
    borderRadius: '5px',
    textTransform: 'none'
  }
}));

export default useStyles;
