import React, { FC } from 'react';
import { Theme, makeStyles, TableRow, Link, Tooltip, IconButton } from '@material-ui/core';
import { ucwords, formatId } from 'utils';
import BodyCell from 'components/BodyCell';
import SupervisorChip from 'components/SupervisorChip';
import { Edit } from '@material-ui/icons';

interface Props {
  index: number;
  supervisor: SupervisorModel;
  handleDetailClick: (supervisor: SupervisorModel) => void;
  onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  idLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { supervisor, handleDetailClick, onEdit } = props;
  const { id, User } = supervisor;
  const { displayName, contactNumber, email, role } = User;

  return (
    <TableRow hover>
      <BodyCell cellWidth='15%'>{formatId(id)}</BodyCell>
      <BodyCell cellWidth='20%'>
        <div className={classes.idLink} onClick={() => handleDetailClick(supervisor)}>
          {displayName}
        </div>
      </BodyCell>
      <BodyCell cellWidth='20%' align='center'>
        {<SupervisorChip label={ucwords(role)} />}
      </BodyCell>
      <BodyCell cellWidth='20%'>{email}</BodyCell>
      <BodyCell cellWidth='20%'>
        <Link href={`https://api.whatsapp.com/send?phone=65${contactNumber}`} target='_blank' className={classes.idLink}>
          {contactNumber}
        </Link>
      </BodyCell>

      <BodyCell cellWidth='20%'>
        <Tooltip title='Edit'>
          <IconButton size='small' onClick={onEdit}>
            <Edit fontSize='small' />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
