import React from 'react';
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
  Typography,
  DialogActionsProps
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface DialogProps extends MuiDialogProps {
  title?: string;
  children?: React.ReactNode;
  loading?: boolean;
  action?: DialogActionsProps;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const Dialog = ({ title, loading, onConfirm, onCancel, onClose, children, action, ...props }: DialogProps) => {
  return (
    <MuiDialog {...props}>
      <DialogTitle>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>{title}</Typography>
          <IconButton size='small' onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {action ? <DialogActions {...action} /> : null}
    </MuiDialog>
  );
};

export default Dialog;
