import React from 'react';

export interface CurrentTrxTabContextData {
  currentTrxTab: number;
  setCurrentTrxTab(tab: number): void;
}

export const CurrentTrxTabContext = React.createContext<CurrentTrxTabContextData>({ currentTrxTab: 0, setCurrentTrxTab: tab => {} });

export const CurrentTrxTabProvider = CurrentTrxTabContext.Provider;
export const CurrentTrxTabConsumer = CurrentTrxTabContext.Consumer;
