import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, InputAdornment, TableContainer } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';
import useRouter from 'hooks/useRouter';
import TableEmpty from 'components/Table/TableEmpty';
import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';
interface Props {
  table: UseTablePagination<DriverModel>;
  onEdit: (id: number) => () => void;
}

const DriverTable: FC<Props> = props => {
  const { history } = useRouter();
  const { table, onEdit } = props;

  const handleOpenDetailClick = (id: number) => {
    history.push(`/driver/${id}`);
  };

  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={table.order}
            orderBy={table.orderBy}
            onRequestSort={(event, property) => table.onOrder(property)}
            height='unset'
            headers={[
              { id: 'id', label: 'ID', sort: true },
              { id: 'displayName', label: 'Display Name', sort: true },
              { id: 'email', label: 'Email', sort: true },
              { id: 'contactNumber', label: 'Phone', sort: true },
              { id: 'vehicleNumber', label: 'Vehicle No', sort: true },
              {
                id: 'actions',
                label: 'Action',
                align: 'center'
              }
            ]}
          />
        </TableHead>
        <TableHead>
          <HeaderRow
            height='0px'
            headers={[
              {
                id: 'findId',
                isInputText: true,
                value: table.query('queryId'),
                handleInputChange: value => table.setQuery({ queryId: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findDisplayName',
                isInputText: true,
                value: table.query('queryName'),
                handleInputChange: value => table.setQuery({ queryName: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findEmail',
                isInputText: true,
                value: table.query('queryEmail'),
                handleInputChange: value => table.setQuery({ queryEmail: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findContactNumber',
                isInputText: true,
                value: table.query('queryContact'),
                handleInputChange: value => table.setQuery({ queryContact: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findVehicle',
                isInputText: true,
                value: table.query('queryVehicleNumber'),
                handleInputChange: value => table.setQuery({ queryVehicleNumber: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'action'
              }
            ]}
          />
        </TableHead>
        <TableBody>
          {table.loading ? (
            <TableLoading col={6} />
          ) : table.data && table.data.length > 0 ? (
            table.data.map((value, index) => (
              <BodyRow index={index} key={index} driver={value} handleOpenDetailClick={handleOpenDetailClick} onEdit={onEdit(value.id)} />
            ))
          ) : (
            <TableEmpty col={6} />
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={table.count}
            rowsPerPage={table.perPage}
            page={table.page}
            onChangePage={table.onPageChange}
            onChangeRowsPerPage={table.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DriverTable;
