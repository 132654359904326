import React, { useState, useEffect, useRef, FC } from 'react';
import axios from 'axios';
import { Button, Box, Badge, ClickAwayListener } from '@material-ui/core';
import { JOBS_BASE_URL, JOBS_QUOTE_BASE_URL } from 'constants/url';
import JobTable from './components/JobTable';
import JobTemplate from 'components/Template/JobTemplate';
import clsx from 'clsx';
import useTablePagination from 'hooks/useTablePagination';
import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import PositionedPopper from 'components/PositionedPopper';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import ExportJob from './components/ExportJob';
import useStyles from './styles';
import { Autorenew } from '@material-ui/icons';

const JobPostedPage: FC = () => {
  const classes = useStyles();
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const completeClearRef = useRef<HTMLButtonElement | null>(null);
  const completeCloseRef = useRef<HTMLButtonElement | null>(null);

  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [dateBy, setDateBy] = useState('');
  const [totalQuote, setTotalQuote] = useState(0);

  const [completeAnchorEl, setCompleteAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openCompleteCalendar, setOpenCompleteCalendar] = useState(false);
  const [dateCompleteRange, setDateCompleteRange] = useState('');
  const [completeDateBy, setCompleteDateBy] = useState('');

  const table = useTablePagination<JobList>(JOBS_BASE_URL, {
    selector: data => data.rows,
    sort: {
      order: 'desc',
      orderBy: 'createdAt'
    }
  });

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateRange(removedTime.join(' ~ '));
    table.setQuery({ startDate: removedTime[0], endDate: removedTime[1] });
  };

  const handleCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCalendar(true);
  };

  const handleCompleteCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCompleteAnchorEl(event.currentTarget);
    setOpenCompleteCalendar(true);
  };

  const handleCompleteTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateCompleteRange(removedTime.join(' ~ '));
    table.setQuery({ completedStart: removedTime[0], completedEnd: removedTime[1] });
  };

  const getQuoteTotal = async() => {
    try {
      setLoadingQuote(true);
      const { data } = await axios.get(JOBS_QUOTE_BASE_URL);
      setTotalQuote(data?.total || 0);
    } catch (err) {
      console.error('err: ', err);
    } finally {
      setLoadingQuote(false);
    }
  }

  const refreshList = () => {
    table.clear();
    setDateRange('');
    setDateCompleteRange('');
    setDateBy('');
    setCompleteDateBy('');
    clearRef.current?.click();
    closeRef.current?.click();
    completeClearRef.current?.click();
    completeCloseRef.current?.click();
    getQuoteTotal()
  };

  useEffect(() => {
   getQuoteTotal()
  }, []);

  return (
    <JobTemplate
      title='Job List'
      subtitle='Display the data of either Job Owner OR Drivers'
      rightHead={
        <Box className={classes.boxButtonRight}>
          <Button variant='outlined' disableElevation color='primary' className={classes.iconButton} onClick={refreshList}>
            <Autorenew fontSize='small' />
          </Button>
          <Button
            variant='outlined'
            color={!!table.query('hasRequestQuote') ? 'primary' : 'default'}
            disabled={loadingQuote || totalQuote === 0}
            onClick={() => {
              table.setQuery({ jobType: JobType.TRIPWORKS, jobStatus: JobStatus.DRAFT, hasRequestQuote: 1 });
            }}
            className={clsx(classes.button, { [classes.buttonBackground]: !!table.query('hasRequestQuote') })}
          >
            <Badge badgeContent={totalQuote} color='primary' classes={{ badge: classes.badgePending }}>
              Request for Quote
            </Badge>
          </Button>
          <Button
            variant='contained'
            disableElevation
            color='primary'
            onClick={() => setOpenExport(true)}
            style={{ textTransform: 'none', padding: '8px 16px 8px 16px' }}
          >
            Export Job
          </Button>
        </Box>
      }
    >
      <JobTable
        {...table}
        dateRange={dateRange}
        completeDateRange={dateCompleteRange}
        handleCalendar={handleCalendar}
        handleCompleteCalendar={handleCompleteCalendar}
      />
      <ClickAwayListener onClickAway={() => setOpenCalendar(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
        <div>
          <PositionedPopper
            open={openCalendar}
            anchorEl={anchorEl}
            placement='bottom'
            containerWidth={200}
            fadeTransition={350}
            keepMounted={openCalendar}
          >
            <DateTimeRangePicker
              mode='date'
              currentValue={dateRange}
              dateBy={dateBy}
              clearRef={clearRef}
              closeRef={closeRef}
              disabledCustomDate={dateBy !== '5'}
              setOpenPopper={setOpenCalendar}
              options={[
                { key: '1', label: 'Today' },
                { key: '2', label: 'Tomorrow' },
                { key: '3', label: 'This Week' },
                { key: '4', label: 'This Month' },
                { key: '5', label: 'Custom Date' }
              ]}
              onChange={handleTimeChange}
              onSelectedChange={value => setDateBy(value)}
              onClear={() => {
                setDateBy('');
              }}
            />
          </PositionedPopper>
        </div>
      </ClickAwayListener>

      <ClickAwayListener onClickAway={() => setOpenCompleteCalendar(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
        <div>
          <PositionedPopper
            open={openCompleteCalendar}
            anchorEl={completeAnchorEl}
            placement='bottom'
            containerWidth={200}
            fadeTransition={350}
            keepMounted={openCompleteCalendar}
          >
            <DateTimeRangePicker
              mode='date'
              currentValue={dateCompleteRange}
              dateBy={completeDateBy}
              clearRef={completeClearRef}
              closeRef={completeCloseRef}
              disabledCustomDate={completeDateBy !== '5'}
              setOpenPopper={setOpenCompleteCalendar}
              options={[
                { key: '1', label: 'Today' },
                { key: '2', label: 'Tomorrow' },
                { key: '3', label: 'This Week' },
                { key: '4', label: 'This Month' },
                { key: '5', label: 'Custom Date' }
              ]}
              onChange={handleCompleteTimeChange}
              onSelectedChange={value => setCompleteDateBy(value)}
              onClear={() => {
                setCompleteDateBy('');
              }}
            />
          </PositionedPopper>
        </div>
      </ClickAwayListener>

      <ExportJob open={openExport} onClose={() => setOpenExport(false)} />
    </JobTemplate>
  );
};

export default JobPostedPage;
