import { useState } from 'react';

type SortDirections = 'asc' | 'desc';

function useSort(defaultSortBy: string, defaultDirection: SortDirections = 'asc') {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortDir, setSortDir] = useState<SortDirections>(defaultDirection);

  const doSort = (event: React.MouseEvent<unknown>, property: string) => {
    setSortBy(property);
    if (sortBy === property) {
      if (sortDir === 'asc') setSortDir('desc'); else setSortDir('asc');
    } else {
      setSortDir('asc');
    }
  };

  return {
    sortBy,
    sortDir,
    doSort,
  };
}

export default useSort;
