import React from 'react';

export interface CurrentJobTabContextData {
  currentJobTab: number;
  setCurrentJobTab(tab: number): void;
}

export const CurrentJobTabContext = React.createContext<CurrentJobTabContextData>({ currentJobTab: 1, setCurrentJobTab: tab => {} });

export const CurrentJobTabProvider = CurrentJobTabContext.Provider;
export const CurrentJobTabConsumer = CurrentJobTabContext.Consumer;
