import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export interface MaterialImageCarouselProps {
  onClose: () => void;
  open: boolean;
  imageUrls: (string | undefined)[];
  selectedItem?: number;
}

const useStyles = makeStyles({
  carouselItemWrapper: {
    height: 500,
  },
  image: {
    objectFit: 'scale-down',
  },
});

export default function MaterialImageCarousel({ onClose, open, imageUrls, selectedItem }: MaterialImageCarouselProps) {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="carousel-dialog"
      open={open}
      fullWidth
      maxWidth="md"
    >
      {imageUrls ? (
        <Carousel showThumbs={false} dynamicHeight infiniteLoop selectedItem={selectedItem}>
          {imageUrls.map((url, index) => (
            <div key={`img-carousel_${index}`} className={classes.carouselItemWrapper}>
              <img
                src={url}
                alt={`material-slide-${index + 1}`}
                className={classes.image}
              />
            </div>
          ))}
        </Carousel>
      ) : null}
    </Dialog>
  );
}
