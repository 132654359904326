import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { formatCurrency } from 'utils';
import useStyles from './styles';
import axios, { CancelTokenSource } from 'axios';
import cardBase from 'images/icon/card-base.svg';
import { GET_DETAIL_MERCHANTS_BASE_URL } from 'constants/url';

const MerchantCardCredit = () => {
  const abort: CancelTokenSource = axios.CancelToken.source();
  const classes = useStyles();

  const [credit, setCredit] = useState({
    creditBalance: 0,
    totalUsedCredit: 0
  });

  const getMerchant = async () => {
    try {
      const { data } = await axios.get(GET_DETAIL_MERCHANTS_BASE_URL, { cancelToken: abort.token });
      setCredit({creditBalance: data.creditBalance || 0, totalUsedCredit: data.totalUsedCredit || 0});
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getMerchant()
    return () => {
      abort.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.cardBalance}>
        <div>
          <img src={cardBase} alt='card base' />
        </div>
        <Box flexGrow={1}>
          <Typography variant='h5' gutterBottom>
            Credit Used
          </Typography>

          <Typography variant='h1'>{formatCurrency(credit.totalUsedCredit)}</Typography>

          <Typography variant='h6' color={'error'} style={{ fontWeight: 500 }}>
            Credit Balance {formatCurrency(credit.creditBalance)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default MerchantCardCredit;
