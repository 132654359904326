import React from 'react';
import Modal from 'components/Modal';
import { Box, Divider, ListItemText, MenuItem, TextField } from '@material-ui/core';
import useStyles from './style';
import Role from 'typings/enum/Role';
import { snackCaseToString } from 'utils';
import { UseForm } from 'hooks/useForm';
import { UseValidation } from 'hooks/useValidation';

export type SupervisorForm = {
  id: number;
  displayName: string;
  contactNumber: string;
  role: string;
  email: string;
};

export interface SupervisorModalFormProps {
  open: boolean;
  form: UseForm<SupervisorForm>;
  serverError: {
    process: boolean;
    email: boolean;
    contactNumber: boolean;
    emailMessage: string;
    contactNumberMessage: string;
  };
  disabledConfirm?: boolean;
  validation: UseValidation<SupervisorForm>;
  serverValidate: (key: 'contactNumber' | 'email') => Promise<void>;
  onConfirm: () => void;
  onCancel: () => void;
}

const items = [
  {
    value: Role.SUPERVISOR_TIER_001,
    text: 'Supervisor can complete job in progress, create additional supervisor and create new job'
  },
  { divider: true },
  {
    value: Role.SUPERVISOR_TIER_002,
    text: 'Supervisor can complete job in progress and create new job'
  },
  { divider: true },
  {
    value: Role.SUPERVISOR_TIER_003,
    text: 'Supervisor can complete job in progress'
  }
];

const SupervisorModalForm = ({ open, form, disabledConfirm,serverError, serverValidate, validation, onConfirm, onCancel }: SupervisorModalFormProps) => {
  const classes = useStyles();
  const isNew = form.data.id === 0;

  return (
    <Modal
      title={isNew ? 'Create New Supervisor' : ' Supervisor'}
      open={open}
      loading={form.processing}
      maxWidth='xs'
      fullWidth
      isNew={isNew}
      disableConfirm={disabledConfirm}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onClose={onCancel}
    >
      <Box display='flex' flexDirection='column' className={classes.box}>
        <TextField
          select
          variant='outlined'
          label='Choose Role'
          required
          value={form.data.role || ''}
          onChange={e => form.setData({ role: e.target.value as string })}
          SelectProps={{
            renderValue: (value: any) => snackCaseToString(`${value}`)
          }}
          error={validation.errors.role?.invalid}
          helperText={validation.errors.role?.message}
        >
          {items.map((val, i) =>
            val.divider ? (
              <Divider key={`opt-${i}`} />
            ) : (
              <MenuItem value={val.value} key={`opt-${i}`}>
                <ListItemText primary={snackCaseToString(`${val.value}`)} secondary={val.text} />
              </MenuItem>
            )
          )}
        </TextField>

        <TextField
          variant='outlined'
          label='Supervisor Name'
          required
          value={form.data.displayName || ''}
          onChange={e => form.setData({ displayName: e.currentTarget.value })}
          error={validation.errors.displayName?.invalid}
          helperText={validation.errors.displayName?.message}
        />

        <TextField
          variant='outlined'
          label='Email'
          value={form.data.email || ''}
          onChange={e => form.setData({ email: e.currentTarget.value })}
          onBlur={async () => {
            if (await validation.validate('email')) {
              serverValidate('email');
            }
          }}
          error={serverError.email || validation.errors.email?.invalid}
          helperText={serverError.emailMessage || validation.errors.email?.message}
        />

        <TextField
          variant='outlined'
          label='Phone No'
          required
          value={form.data.contactNumber || ''}
          onChange={e => {
          if(Number.isInteger(+e.currentTarget.value)){
            form.setData({ contactNumber: e.currentTarget.value })
          }
          }}
          onBlur={async () => {
            const check = await validation.validate('contactNumber');
            if (check) {
              serverValidate('contactNumber');
            }
          }}
          InputProps={{inputProps:{
            maxLength: 8
          }}}
          error={serverError.contactNumber || validation.errors.contactNumber?.invalid}
          helperText={serverError.contactNumberMessage || validation.errors.contactNumber?.message}
        />
      </Box>
    </Modal>
  );
};

export default SupervisorModalForm;
