import React, { FC, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Paper, Tabs, Tab, Box, Button } from '@material-ui/core';
import { dummyMerchant, dummyUser } from 'constants/dummy';
import { GET_DETAIL_MERCHANTS_BASE_URL } from 'constants/url';
import SupervisorsPage from 'pages/SupervisorsPage';
import DriversPage from 'pages/DriversPage';
import MainTemplate from 'components/Template/MainTemplate';
import MerchantInfo from './MerchantInfo';
import TabPanel, { a11yProps } from 'components/TabPanel';
import useStyles from './style';
import { Add } from '@material-ui/icons';

const SettingPage: FC = () => {
  const classes = useStyles();
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const [merchant, setMerchant] = useState<MerchantModel>(dummyMerchant);
  const [merchantUser, setMerchantUser] = useState<UserDetailsModel>(dummyUser);
  const [userStatus, setUserStatus] = useState<UserStatusHistoriesModel[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState({
    supervisor: false,
    driver: false
  });
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      const { data } = await axios.get(GET_DETAIL_MERCHANTS_BASE_URL, { cancelToken: cancelTokenSource.token });
      setMerchant(data);
      setMerchantUser(data.User);
      setUserStatus(data.User.UserStatus);
    } catch (err) {
      console.error('err: ', err);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <MainTemplate title='Settings' subtitle='All general settings' breadcrumb={false} refreshButton={false} primaryButton={false}>
      <Paper elevation={0} className={classes.cardTab}>
        <Box display='flex' flexDirection='row' justifyContent='space-between' className={classes.tabs}>
          <Box>
            <Tabs indicatorColor='primary' value={value} onChange={handleChangeTab}>
              <Tab label='General Information' {...a11yProps(0)} className={classes.tab} />
              <Tab label='Supervisors Account' {...a11yProps(1)} className={classes.tab} />
              <Tab label='Drivers Account' {...a11yProps(2)} className={classes.tab} />
            </Tabs>
          </Box>

          <Box>
            {value === 1 && (
              <Button variant='contained' disableElevation color='primary' onClick={() => setOpenModal(state => ({ ...state, supervisor: true }))}>
                <Add className={classes.icon} /> Add New Supervisor
              </Button>
            )}

            {value === 2 && (
              <Button variant='contained' disableElevation color='primary' onClick={() => setOpenModal(state => ({ ...state, driver: true }))}>
                <Add className={classes.icon} />
                Add New Driver
              </Button>
            )}
          </Box>
        </Box>

        <TabPanel value={value} index={0}>
          <div className={classes.tabPanel}>
            <MerchantInfo {...merchant} userStatus={userStatus} merchantUser={merchantUser} isLoadingData={isLoadingData} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className={classes.tabPanelList}>
            <SupervisorsPage
              open={openModal.supervisor}
              onOpen={() => setOpenModal(state => ({ ...state, supervisor: true }))}
              onCloseModal={(callback?: () => void) => () => {
                setOpenModal(state => ({ ...state, supervisor: false }));
                if (callback) callback();
              }}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className={classes.tabPanelList}>
            <DriversPage
              open={openModal.driver}
              onOpen={() => setOpenModal(state => ({ ...state, driver: true }))}
              onCloseModal={(callback?: () => void) => () => {
                setOpenModal(state => ({ ...state, driver: false }));
                if (callback) callback();
              }}
            />
          </div>
        </TabPanel>
      </Paper>
    </MainTemplate>
  );
};

export default SettingPage;
