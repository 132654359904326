import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '16px',
    padding: '16px 24px 16px 24px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)',
    overflow: "hidden",
  },
  cardBalance: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '24px'
  },
  primaryButton: {
    padding: '8px 16px 8px 16px',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.25px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& button:nth-child(2)': {
      marginLeft: '8px'
    }
  }
}));

export default useStyles;
