import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    paddingTop: '32px'
  },
  tabPanelList: {
    paddingTop: '32px'
  },
  cardTab: {
    marginTop: '16px',
    padding: '16px 24px 16px 24px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.black.A100
  }
}));

export default useStyles;
