import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import CardContent from 'components/CardContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileModal from 'components/FileModal';
import useStyles from './style';

export interface MerchantInfoProps extends MerchantModel {
  isLoadingData: boolean;
  merchantUser: UserDetailsModel;
  userStatus: UserStatusHistoriesModel[];
}

const MerchantInfo = ({ merchantUser, userStatus, isLoadingData, ...props }: MerchantInfoProps) => {
  const classes = useStyles();
  const [openFile, setOpenFile] = useState(false);

  let additionalContacts: any = [[], []];

  if (!!props.AdditionalContacts?.length) {
    props.AdditionalContacts.forEach(value => {
      if (additionalContacts[0] && additionalContacts[0].length < 2) {
        additionalContacts[0].push({ title: value.value, value: () => (value.allowSend ? 'Enable receive email' : 'Unable receive email') });
      } else {
        additionalContacts[1].push({ title: value.value, value: () => (value.allowSend ? 'Enable receive email' : 'Unable receive email') });
      }
    });
  } else {
    additionalContacts = [[{ title: '', value: () => 'Additional contact not available' }]];
  }

  return (
    <>
      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Company Information'
        divider={true}
        data={props}
        column={[
          [
            { title: 'Company Name', value: data => data.companyName },
            {
              title: 'ACRA Company',
              value: data => (
                <Button variant='text' color='primary' size='small' className={classes.seeDocument} onClick={() => setOpenFile(true)}>
                  <VisibilityIcon className={classes.icon} /> See Document
                </Button>
              )
            },
            { title: 'UEN Number', value: data => data.companyUen || '-' }
          ],
          [
            { title: 'Phone Number', value: data => data.User?.companyPhone || '-' },
            { title: 'GST Number', value: data => data.companyGst || '-' },
            { title: 'Address', value: data => data.User!.address || '-' }
          ]
        ]}
      />

      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Personal Information'
        divider={true}
        data={props}
        column={[
          [
            { title: 'Display Name', value: (data: MerchantModel) => data.User!.displayName || '-' },
            { title: 'Email Address', value: (data: MerchantModel) => data.User!.email || '-' }
          ],
          [
            { title: 'Phone Number', value: (data: MerchantModel) => data.User!.contactNumber || '-' },
            { title: 'Receive Email', value: data => (data.allowSend ? 'Yes' : 'No') }
          ]
        ]}
      />

      <CardContent wrapperProps={{ className: classes.box }} title='Additional Contact' divider={true} data={props} column={additionalContacts} />

      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Bank Account'
        data={props}
        column={[
          [{ title: 'Bank Name', value: data => data.bankName || '-' }, { title: 'Account Number', value: data => data.bankAccountNumber || '-' }],
          [{ title: 'Account Name', value: data => data.bankAccountName || '-' }]
        ]}
      />

      <FileModal title='ACRA Company file' open={openFile} setOpen={setOpenFile} fileUrl={props.companyFileUrl || ''} fileName={props.companyFile} />
    </>
  );
};

export default MerchantInfo;
