import * as React from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export interface Props {
  open?: boolean;
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
  message?: React.ReactNode;
  variant?: 'error' | 'warning' | 'info' | 'success';
}

export default ({ open, onClose, message, variant }: Props) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={2000} open={open} onClose={onClose} message={message}>
      <Alert elevation={6} variant='filled' severity={variant}>
        {message}
      </Alert>
    </Snackbar>
  );
};
