import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    idLink: {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    managerChipRoot: {
      borderRadius: '5px',
      fontWeight: 500,
      fontSize: '12px'
    },
    managerChipLabel: {
      padding: '2px 6px 2px 6px'
    }
  }));

  export default useStyles;