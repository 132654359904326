const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const GET_TOKEN = `${BASE_URL}/access-token`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHECK_TOKEN_RESET_PASSWORD_URL = `${BASE_URL}/checkresettoken`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;
export const CHECK_USER_FORGOT_PASSWORD_URL = `${BASE_URL}/checkuserforgot`;
export const SETTINGS_URL = `${BASE_URL}/settings`;
export const APPLOG_BASE_URL = `${BASE_URL}/applog`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_CHECK_USER_URL = `${USER_BASE_URL}/check`;
export const GET_CURRENT_USER_PASSWORD_URL = `${USER_BASE_URL}/currentpassword`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const USER_VALIDATION_BASE_URL = (column: string) => `${USER_BASE_URL}/validation?column=${column}`;
export const DELETE_USER_URL = (userId: number[]) => `${USER_BASE_URL}/${userId}`;

export const MERCHANTS_BASE_URL = `${BASE_URL}/merchants`;
export const GET_DETAIL_MERCHANTS_BASE_URL = `${MERCHANTS_BASE_URL}/detail`;

export const DRIVERS_BASE_URL = `${BASE_URL}/drivers`;
export const INDIVIDUAL_DRIVER_URL = (id: number) => `${DRIVERS_BASE_URL}/${id}`;
export const DRIVERS_VALIDATION_BASE_URL = (column: string) => `${DRIVERS_BASE_URL}/validation?column=${column}`;
export const DRIVERS_PUT_VEHICLE_BASE_URL = (value: string) => `${DRIVERS_BASE_URL}/put-vehicle-file-url?file=${value}`;

export const JOBS_BASE_URL = `${BASE_URL}/jobs`;
export const JOBS_QUOTE_BASE_URL = `${JOBS_BASE_URL}/quote`
export const JOBS_EXPORT_BASE_URL = `${JOBS_BASE_URL}/export`
export const JOBS_TASK_BASE_URL = `${JOBS_BASE_URL}/tasks`;
export const JOBS_TASK_DRIVER_BASE_URL = `${JOBS_BASE_URL}/tasks/driver`;
export const INDIVIDUAL_JOB_URL = (id: number) => `${JOBS_BASE_URL}/${id}`;
export const EXPORT_JOB_URL = (id: number) => `${INDIVIDUAL_JOB_URL(id)}/export`;

export const INVOICES_BASE_URL = `${BASE_URL}/invoices`;
export const EXPORT_INVOICE_URL = (id: number) => `${INVOICES_BASE_URL}/${id}/pdf`;

export const BANKS_BASE_URL = `${BASE_URL}/banks`;

export const SUPERVISORS_BASE_URL = `${BASE_URL}/supervisors`;
export const SUPERVISORS_VALIDATION_BASE_URL = (column: string) => `${SUPERVISORS_BASE_URL}/validation?column=${column}`;

export const TRANSACTIONS_BASE_URL = `${BASE_URL}/transactions`;
export const GET_TRANSACTION_DETAIL = (id: number) => `${TRANSACTIONS_BASE_URL}/${id}`;
export const EXPORT_ALL_TRX = `${TRANSACTIONS_BASE_URL}/export-all`;
