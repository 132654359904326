enum TransactionPaymentMethod {
  PAYPAL = 'PAYPAL',
  PAYNOW = 'PAYNOW',
  CHEQUE = 'CHEQUE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  VIRTUAL_BANK_ACCOUNT = 'VIRTUAL_BANK_ACCOUNT',
  JUSTGO_WALLET = 'JUSTGO_WALLET',
  CREDIT_WALLET = 'CREDIT_WALLET',
}

export default TransactionPaymentMethod;
