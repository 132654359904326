import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { green } from '@material-ui/core/colors';
import { Theme, makeStyles, Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { INVOICES_BASE_URL } from 'constants/url';

interface Props {
  invoiceId: number;
  invoiceNo: string;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageFile: {
    maxWidth: '80%',
    margin: 'auto 0'
  },
  loadingRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  framePdf: {
    minHeight: '150px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const DoFileModal: FC<Props> = props => {
  const cancelToken = axios.CancelToken.source();
  const classes = useStyles();
  const { invoiceId, invoiceNo, open, handleClose } = props;
  const [loadingDo, setLoadingDo] = useState<boolean>(false);
  const [doUrl, setDoUrl] = useState<string>('');

  const getDO = async () => {
    try {
      setLoadingDo(true);
      const { data } = await axios.get(`${INVOICES_BASE_URL}/${invoiceId}/do`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream'
        },
        cancelToken: cancelToken.token
      });

      const objectBlob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(objectBlob);

      setDoUrl(url);
      setLoadingDo(false);
    } catch (error) {
      setLoadingDo(false);
    }
  };

  const handleDownload = () => {
    const linkDo = document.createElement('a');
    linkDo.href = doUrl;
    linkDo.setAttribute('download', `DO-${invoiceNo}.pdf`);
    document.body.appendChild(linkDo);
    linkDo.click();
  };

  useEffect(() => {
    if (!invoiceId || !open) {
      return;
    }

    getDO();

    return () => {
      cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setDoUrl('');
          cancelToken.cancel();
        }}
        fullWidth={true}
        maxWidth='md'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent style={{ padding: loadingDo ? '24px' : 0 }}>
          {loadingDo ? (
            <Box display={'flex'} justifyContent={'center'} flexDirection={'row'} style={{ gap: 8 }}>
              <Box>
                <CircularProgress color='secondary' size={25} />
              </Box>

              <Typography component={'div'} style={{ fontStyle: 'italic' }}>
                Loading DO...
              </Typography>
            </Box>
          ) : doUrl ? (
            <iframe title='do' className={classes.framePdf} style={{ width: '100%', minHeight: '500px' }} src={doUrl}></iframe>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setDoUrl('');
              cancelToken.cancel();
            }}
            color='primary'
          >
            Close
          </Button>
          <Button disabled={loadingDo} onClick={handleDownload} color='primary'>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DoFileModal;
