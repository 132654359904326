import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';

interface Props {
  type: string;
  minWidth?: string;
}

const useStyles = makeStyles({
  chip: {
    minWidth: (props: Props) => props.minWidth || '96px',
    backgroundColor: '#FEF1D2',
    color: '#F6891F',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',
    border: 'unset',
    height: 'auto'
  },
  credit: {
    minWidth: (props: Props) => props.minWidth || '96px',
    backgroundColor: '#D9F7ED',
    color: '#29696A',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',
    border: 'unset',
    height: 'auto'
  },
  label: {
    padding: '5px 10px 5px 10px'
  }
});

const jobPayment = {
  [TransactionPaymentMethod.JUSTGO_WALLET]: 'Justgo Wallet',
  [TransactionPaymentMethod.CREDIT_WALLET]: 'Credit'
};

export default function JobPayment(props: Props) {
  const classes = useStyles(props);
  const { type } = props;

  return (
    <Chip
      variant='outlined'
      label={jobPayment[type as keyof typeof jobPayment]}
      classes={{ label: classes.label }}
      className={clsx({
        [classes.chip]: type === TransactionPaymentMethod.JUSTGO_WALLET,
        [classes.credit]: type === TransactionPaymentMethod.CREDIT_WALLET
      })}
    />
  );
}
