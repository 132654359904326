import React, { FC, useState, useMemo, useCallback, useRef } from 'react';
import { makeStyles, Theme, Typography, InputAdornment, IconButton, Box, Paper, ClickAwayListener } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NumberFormat from 'react-number-format';

import TextFilter from 'components/TextFilter';
import SelectFilter from 'components/SelectFilter';
import TransactionExportButton from './TransactionExportButton';
import PositionedPopper from 'components/PositionedPopper';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import TrxStatusChip from 'components/TrxStatusChip';
import DataTable from 'components/DataTable';
import useFilter from 'hooks/useFilter';
import useSort from 'hooks/useSort';
import usePagination from 'hooks/usePagination';
import useFetch from 'hooks/useFetch';
import TransactionStatus from 'typings/enum/TransactionStatus';
import { formatId, incomingType, newLabelTrx, outgoingType, snackCaseToString, ucwords } from 'utils';
import { TRANSACTIONS_BASE_URL } from 'constants/url';
import { useApp } from 'contexts/AppContext';
import MainTemplate from 'components/Template/MainTemplate';
import TransactionType from 'typings/enum/TransactionType';
import clsx from 'clsx';
import MerchantCardCredit from './MerchantCardCredit';
import MerchantCardTotalBalance from './MerchantCardTotalBalance';

const useStyles = makeStyles((theme: Theme) => ({
  adornment: {
    color: theme.palette.subtitle.main,
    padding: 5
  },
  button: {
    border: '1.5px solid #C1C1C1',
    borderRadius: '5px',
    color: '#C1C1C1',
    fontWeight: 500,
    fontSize: '14px',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  buttonExport: {
    marginTop: '4px',
    padding: '6px 16px 6px 16px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '5px'
  },
  incoming: {
    color: theme.palette.success.main
  },
  outgoing: {
    color: theme.palette.error.main
  },
  optGroup: {
    lineHeight: '24px'
  },
  optIncome: {
    background: theme.palette.success.main,
    color: theme.palette.success.light
  },
  optOutcome: {
    lineHeight: '24px'
  }
}));

const TransactionsPage: FC = () => {
  const classes = useStyles();
  const { date } = useApp();
 
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [trxType, setTrxType] = useState('');
  const [trxOutType, setOutTrxType] = useState('');
  const { sortBy, sortDir, doSort: onRequestSort } = useSort('createdAt', 'desc');
  const { currentPage, rowsPerPage, count, changePage, changeRowsPerPage, setCount, setCurrentPage } = usePagination();
  const { filters, doFilter, doMultiFilter } = useFilter(
    {
      keyword: '',
      startDate: '',
      endDate: '',
      type: []
    },
    () => {
      setCurrentPage(0);
    }
  );
  const [dateBy, setDateBy] = useState('');
  const memoizedFilters = useMemo(() => filters, [filters]);
  const memoizedSetCount = useCallback(setCount, []);
  const memoizedChangePage = useCallback(changePage, []);
  const memoizedChangeRowsPerPage = useCallback(changeRowsPerPage, []);
  const { data, isLoading } = useFetch(
    TRANSACTIONS_BASE_URL,
    'transactions',
    memoizedFilters,
    currentPage,
    rowsPerPage,
    memoizedSetCount,
    sortBy,
    sortDir
  );

  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsCalendarOpen(true);
  };

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateFilterValue(removedTime.join(' ~ '));
    doMultiFilter({
      startDate: removedTime[0],
      endDate: removedTime[1]
    });
  };

  const refreshList = () => {
    doFilter('keyword', '');
    doFilter('startDate', '');
    doFilter('endDate', '');
    doFilter('type', '');
    setDateFilterValue('');
    setDateBy('');
    setTrxType('');
    setOutTrxType('');
    clearRef.current?.click();
    closeRef.current?.click();
  };

  const headers: HeaderProps[] = [
    {
      id: 'id',
      label: 'ID',
      sortable: true,
      width: '5%',
      render: (row: TransactionModel) => <Typography variant='subtitle2'>{formatId(row.id)}</Typography>
    },
    {
      id: 'createdAt',
      label: 'DateTime',
      sortable: true,
      render: (row: TransactionModel) => date(row.createdAt)
    },
    {
      id: 'type',
      label: 'Description',
      sortable: true,
      render: (row: TransactionModel) => {
        let name = '';
        let prefix = 'to';

        if (incomingType.includes(row.type as TransactionType)) {
          name = row.PaidMerchant?.companyName || '';
        }

        if (outgoingType.includes(row.type as TransactionType)) {
          name = row.PayingMerchant?.companyName || '';
          prefix = 'from';
        }

        return (
          <>
            <Typography variant='subtitle2'>{snackCaseToString(row.type)}</Typography>
            <Typography>
              {prefix} {name}'s wallet
            </Typography>
          </>
        );
      }
    },
    {
      id: 'paymentMethod',
      label: 'Payment Method',
      sortable: true,
      align: 'center',
      render: (row: TransactionModel) => (row.paymentMethod ? snackCaseToString(row.paymentMethod) : 'N/A')
    },
    {
      id: 'amount',
      label: 'Amount',
      sortable: true,
      align: 'right',
      render: (row: TransactionModel) => (
        <span
          style={{ fontWeight: 'bold' }}
          className={clsx({
            [classes.incoming]: incomingType.includes(row.type as TransactionType),
            [classes.outgoing]: outgoingType.includes(row.type as TransactionType)
          })}
        >
          {incomingType.includes(row.type as TransactionType) && '+'} {outgoingType.includes(row.type as TransactionType) && '-'}{' '}
          <NumberFormat value={row.amount} displayType='text' thousandSeparator={true} prefix='$' decimalScale={2} fixedDecimalScale={true} />
        </span>
      )
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true,
      align: 'center',
      render: (row: TransactionModel) => <TrxStatusChip status={row.status as TransactionStatus} label={row.status} />
    }
  ];

  const memoizedHeaders = useMemo(() => headers, [headers]);
  const memoizedData = useMemo(() => data, [data]);

  return (
    <MainTemplate
      title='Transactions History'
      subtitle='Displays data of all transactions that have been made'
      primaryButton={false}
      refreshButtonProps={{ onClick: refreshList }}
    >
      <Box display='flex' flexDirection={'column'} style={{ gap: '16px' }}>
        <Box display='flex' flexDirection={'row'} style={{ gap: '16px', marginBottom: '16px', marginTop: '16px' }}>
          <MerchantCardTotalBalance />
          <MerchantCardCredit />
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div style={{ width: '286px' }}>
            <TextFilter
              fullWidth={true}
              value={memoizedFilters.keyword}
              onChange={event => {
                doFilter('keyword', event.target.value);
              }}
              placeholder='Search by trx id or name'
              startAdornment={
                <InputAdornment className={classes.adornment} position='start'>
                  <SearchIcon fontSize='small' />
                </InputAdornment>
              }
            />
          </div>

          <div style={{ flexGrow: 1, display: 'flex', gap: '8px', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TextFilter
              value={dateFilterValue}
              onChange={() => {}}
              readOnly={true}
              placeholder='Filter by date'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendarClick}>
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
            />

            <SelectFilter
              label='Incoming Type'
              width='20%'
              value={trxType}
              onChange={event => {
                const value = event.target.value as string;
                if (value) {
                  doFilter('type', Array.from(new Set([...memoizedFilters.type.filter((v:string) => v !== trxType), value])));
                } else {
                  if(trxType){
                    doFilter('type', memoizedFilters.type.filter((v: string) => v !== trxType));
                  }
                }
                setTrxType(value);
              }}
              options={[
                ...incomingType
                  .filter(v => ![TransactionType.REFUND_EDIT_TASK_HOUR].includes(v))
                  .map(v => ({ value: v, label: snackCaseToString(ucwords(newLabelTrx(v))).replace('Gst', 'GST') }))
              ]}
            />

            <SelectFilter
              label='Outgoing Type'
              width='20%'
              value={trxOutType}
              onChange={event => {
                const value = event.target.value as string;
                if (value) {
                  doFilter('type',  Array.from(new Set([...memoizedFilters.type.filter((v:string) => v !== trxOutType), value])));
                } else {
                  
                  if(trxOutType){
                    doFilter('type', memoizedFilters.type.filter((v: string) => v !== trxOutType));
                  }
                }
                setOutTrxType(value);
              }}
              options={[
                ...outgoingType
                  .filter(v => ![TransactionType.REVERT_EDIT_TASK_HOUR].includes(v))
                  .map(v => ({ value: v, label: snackCaseToString(ucwords(newLabelTrx(v))).replace('Gst', 'GST') }))
              ]}
            />

            <div>
              <TransactionExportButton filters={memoizedFilters} sortBy={sortBy} sortDir={sortDir} className={classes.buttonExport} />
            </div>
          </div>
        </Box>

        <Paper elevation={0}>
          <DataTable
            isLoading={isLoading}
            headers={memoizedHeaders}
            data={memoizedData}
            count={count}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onChangePage={memoizedChangePage}
            onChangeRowsPerPage={memoizedChangeRowsPerPage}
            sortBy={sortBy}
            sortDir={sortDir}
            onRequestSort={onRequestSort}
            showFilterRow={false}
          />

          <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
            <div>
              <PositionedPopper open={isCalendarOpen} anchorEl={anchorEl} placement='bottom' containerWidth={200} fadeTransition={350} keepMounted>
                <DateTimeRangePicker
                  mode='date'
                  dateBy={dateBy}
                  clearRef={clearRef}
                  closeRef={closeRef}
                  disabledCustomDate={dateBy !== '5'}
                  setOpenPopper={setIsCalendarOpen}
                  options={[
                    { key: '1', label: 'Today' },
                    { key: '2', label: 'Tomorrow' },
                    { key: '3', label: 'This Week' },
                    { key: '4', label: 'This Month' },
                    { key: '5', label: 'Custom Date' }
                  ]}
                  onChange={handleTimeChange}
                  onSelectedChange={value => setDateBy(value)}
                  onClear={() => {
                    setDateBy('');
                  }}
                />
              </PositionedPopper>
            </div>
          </ClickAwayListener>
        </Paper>
      </Box>
    </MainTemplate>
  );
};

export default TransactionsPage;
