import React from 'react';
import { Chip, ChipProps, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  outlinedPrimary: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    backgroundColor: theme.palette.orange['100'],
    border: `1px solid ${theme.palette.orange['500']}`,
    borderRadius: '30px',
    height: 'auto'
  },
  label: {
    padding: '5px 15px 5px 15px'
  }
}));

const SupervisorChip = (props: ChipProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Chip
      variant='outlined'
      color='primary'
      classes={{
        outlinedPrimary: classes.outlinedPrimary,
        label: classes.label
      }}
      {...props}
    />
  );
};

export default SupervisorChip;
