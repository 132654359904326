import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  chipType: {
    minWidth: '150px'
  },
  mainText: {
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  subText: {
    color: theme.palette.black[50],
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  disableText: {
    textStyle: 'italic',
    color: '#7c7d7e'
  },
  clickableAction: {
    color: '#F6891F',
    cursor: 'pointer'
  },
  nonClickableAction: {
    color: '#DADADA',
    cursor: 'not-allowed'
  },
  price: {
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 600
  }
}));

export default useStyles;
