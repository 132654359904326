import { camelCaseToString } from './../utils/index';
// eslint-disable-next-line
import React, { useState } from 'react';
import * as yup from 'yup';
import _ from 'lodash';

export interface UseValidationProps<T> {
  data: T;
  schema: (yup: typeof import('yup')) => yup.AnyObjectSchema;
}

export interface UseValidation<T> {
  errors: {
    [key: string]: {
      invalid: boolean;
      message: string;
    };
  };
  validation: () => Promise<{
    isValid: boolean;
    error: {
      [key: string]: {
        invalid: boolean;
        message: string;
      };
    };
  }>;
  validate: (key: keyof T) => Promise<boolean>;
  clear: () => void;
  omit: (key: string[]) => void;
}

const useValidation = <T>({ data, schema }: UseValidationProps<T>) => {
  const [omitValue, setOmit] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: { invalid: boolean; message: string } }>(
    Object.keys(data as any).reduce((a, b) => ({ ...a, [b]: { invalid: false, message: '' } }), {})
  );

  const omit = (key: string[] = []) => {
    setOmit(key);
  };

  const validation = async () => {
    try {
      setErrors(Object.keys(data as any).reduce((a, b) => ({ ...a, [b]: { invalid: false, message: '' } }), {}));
      await schema(yup).validate(data, { abortEarly: false });
      return { isValid: true, error: {} };
    } catch (err:any) {
      let error = errors;
      if (err.inner.length > 0) {
        err.inner.map((value: any) => {
          error = { ...error, [value.path]: { invalid: true, message: value.message.replace(/_/g, ' ') } };
          return value;
        });
        setErrors(error);
      }
      return {
        isValid: Object.entries(_.omit(error, omitValue))
          .map(([k, v]) => v.invalid)
          .every(val => !val),
        error
      };
    }
  };

  const validate = async (key: keyof T) => {
    if(!omitValue.includes(key as string)){
      try {
        setErrors(state => ({ ...state, [key]: { invalid: false, message: '' } }));
        await schema(yup).validate({ [key]: data[key] }, { abortEarly: false });
        return true;
      } catch (err:any) {
        let valid = true;
        if (err.inner.length > 0) {
          err.inner.map((value: any) => {
            if (value.path === key) {
              setErrors(state => ({
                ...state,
                [value.path]: {
                  invalid: true,
                  message: camelCaseToString(value.message.replace(/_/g, ' '))
                }
              }));
             valid = false
            }
            return value
          });
        }
        return valid;
      }
    } 
    return true
  };

  const clear = () => {
    setErrors(Object.keys(data as any).reduce((a, b) => ({ ...a, [b]: { invalid: false, message: '' } }), {}));
  };

  return {
    errors: _.omit(errors, omitValue),
    validation,
    validate,
    clear,
    omit
  };
};

export default useValidation;
