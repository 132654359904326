export const dummySelect: Select = {
  id: 0,
  name: ''
};

export const dummySetting: SettingModel = {
  id: 0,
  code: '',
  label: '',
  value: '',
  type: '',
  remarks: '',
  isActive: false
};

export const dummyUserStatus: UserStatusHistoriesModel = {
  id: 0,
  UserId: 0,
  status: '',
  remarks: '',
  createdAt: new Date()
};

export const dummyUser: UserDetailsModel = {
  id: 0,
  loginName: '',
  displayName: '',
  email: '',
  contactNumber: '',
  role: '',
  status: '',
  active: false,
  isDeleted: false,
  UserStatus: [dummyUserStatus]
};

export const dummyMerchant: MerchantModel = {
  id: 0,
  UserId: 0,
  merchantType: '',
  companyName: '',
  companyUen: '',
  companyGst: '',
  companyFile: '',
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  balance: 0,
  totalSpent: 0,
  User: dummyUser,
  AdditionalContacts: []
};

export const dummyDriver: DriverModel = {
  id: 0,
  UserId: 0,
  MerchantId: 0,
  vehicleNumber: '',
  vehicleLogCard: '',
  individualCompanyName: '',
  individualCompanyUen: '',
  individualCompanyFile: '',
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  totalBalance: 0,
  totalSpent: 0,
  User: dummyUser,
  Merchant: dummyMerchant
};

export const dummySupervisor: SupervisorModel = {
  id: 0,
  UserId: 0,
  MerchantId: 0,
  User: dummyUser,
  Merchant: dummyMerchant
};

export const dummyJob: JobModel = {
  id: 0,
  MerchantId: 0,
  jobType: '',
  jobStatus: '',
  jobTitle: '',
  jobDate: '',
  jobTime: '',
  price: 0,
  hours: 0,
  totalTrips: 0,
  totalVehicles: 0,
  totalAmount: 0,
  materials: '',
  siteAddress: '',
  postalCode: '',
  disctrict: '',
  lampPost: '',
  remarks: '',
  latitude: 0,
  longitude: 0,
  streetName: '',
  siteAddressDestination: '',
  postalCodeDestination: '',
  disctrictDestination: '',
  lampPostDestination: '',
  streetNameDestination: '',
  remarksDestination: '',
  scanLocation: '',
  pass: '',
  truckType: '',
  Merchant: dummyMerchant,
  createdAt: '',
  updatedAt: new Date(),
  MaterialImagesBucket: {
    image1Url: '',
    image2Url: '',
    image3Url: ''
  }
};

export const dummyJobTask: JobTask = {
  id: 0,
  taskEarn: 0,
  taskHour: 0,
  JobId: 0,
  DriverId: 0,
  SupervisorId: 0,
  jobTaskStatus: '',
  completedDate: '',
  completedTime: '',
  additionalHour: 0,
  additionalAmount: 0,
  additionalStatus: '',
  additionalRemarks: '',
  additionalBy: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  Driver: dummyDriver,
  Supervisor: dummySupervisor,
  JobNotes: []
};

export const dummyInvoice: InvoiceModel = {
  id: 0,
  JobId: 0,
  toUserId: 0,
  fromUserId: 0,
  invoiceNo: '',
  invoiceDate: new Date(),
  dueDate: new Date(),
  subTotal: 0,
  gstAmount: 0,
  paidAmount: 0,
  Job: dummyJob,
  ToUser: dummyUser,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const dummyJobAdditionalItems: JobAdditionalItem = {
  price: 0,
  totalPrice: 0,
  id: 0,
  name: '',
  totalItem: 0,
  jobAdditionalId: 0
};

export const dummyJobDetail: JobDetail = {
  price: 0,
  totalAmount: 0,
  totalGstAmount: 0,
  materialRemarks: '',
  materialWeight: 0,
  hoistHeight: 0,
  overtimePrice: 0,
  lengthCargo: 0,
  heightCargo: 0,
  widthCargo: 0,
  liftingDistance: 0,
  equipmentWeight: 0,
  id: 0,
  jobType: '',
  jobStatus: '',
  jobTitle: '',
  jobDate: '',
  jobTime: '',
  hours: 0,
  totalTrips: 0,
  totalVehicles: 0,
  materials: '',
  siteAddress: '',
  remarks: '',
  siteAddressDestination: '',
  remarksDestination: '',
  truckType: '',
  pass: '',
  scanLocation: '',
  oneDayFinish: false,
  materialWeightType: '',
  hasRequestQuote: false,
  numberPiece: 0,
  JobTasks: [],
  JobAdditionals: [],
  lampPost: '',
  lampPostDestination: '',
  streetName: '',
  streetNameDestination: '',
  totalTask: 0,
  totalDriver: 0,
  totalDriverCancel: 0,
  totalCompletedTask: 0,
  total: 0,
  useCredit: false
};
