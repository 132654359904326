import React from 'react';
import {
  Box,
  BoxProps,
  CardHeader,
  CardHeaderProps,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemTextProps,
  makeStyles,
  styled,
  Theme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

export type ListColumnProps<T> = {
  title: string;
  value: string | ((data: T) => any);
  skeletonWidth?: string;
  visibility?: boolean;
} & Omit<ListItemTextProps, 'primary' | 'secondary'>;

export interface CardContentProps<T> {
  visible?: boolean;
  /**
   * Type for Data
   */
  data: T;
  /**
   * Card Title
   */
  title: string;
  /**
   * For Show Divider under card header
   */
  divider?: boolean;
  /**
   * Loading state
   */
  loading: boolean;
  /**
   * Array for column show data
   */
  column: ListColumnProps<T>[][];
  /**
   * List Item Text Props
   * https://mui.com/material-ui/api/list-item-text/
   */
  listItemTextProps?: Omit<ListItemTextProps, 'primary' | 'secondary'>;
  headerProps?: CardHeaderProps;
  wrapperProps?: BoxProps;
}

const Stack = styled(Box)(({ theme }) => ({}));

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: 0
  },
  gutters: {
    paddingLeft: 0
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    paddingLeft: 0
  },
  value: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    paddingLeft: 0,
    color: theme.palette.black.A100
  },
  visible: {
    display: 'none'
  }
}));

const CardContent = <T,>({ visible, data, title, divider, loading, column, listItemTextProps, wrapperProps, headerProps }: CardContentProps<T>) => {
  const classes = useStyles();

  return visible === undefined || visible ? (
    <Box {...wrapperProps}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h4' }} className={classes.cardHeader} {...headerProps} />

      <Stack display={'flex'} flexDirection='row' justifyContent='space-between'>
        {column.map((col: ListColumnProps<T>[], i: number) => (
          <div key={`list-${i}`} style={{ flex: 1 }}>
            <List>
              {col.map(({ title, value, skeletonWidth, visibility, ...props }: ListColumnProps<T>, j: number) => {
                const textProps = {};
                if (listItemTextProps) Object.assign(textProps, listItemTextProps);
                if (props) Object.assign(textProps, props);
                return (
                  <ListItem
                    key={`col-${i}-${j}`}
                    classes={{
                      gutters: classes.gutters
                    }}
                    className={clsx({ [classes.visible]: typeof visibility === undefined ? false : visibility })}
                  >
                    <ListItemText
                      primary={title}
                      primaryTypographyProps={{ className: classes.title, variant: 'inherit', gutterBottom: true }}
                      secondary={loading ? <Skeleton width={skeletonWidth} /> : typeof value === 'string' ? value || '-' : value(data) || '-'}
                      secondaryTypographyProps={{
                        variant: 'inherit',
                        className: classes.value
                      }}
                      {...textProps}
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        ))}
      </Stack>

      {divider && <Divider />}
    </Box>
  ) : null;
};

export default CardContent;

CardContent.defaultProps = {
  shadow: true,
  column: [[]],
  loading: false
};
