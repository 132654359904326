import { useState, useMemo } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface Props<T> {
  defaultValue: T;
}

export interface UseForm<T> {
  data: T;
  processing: boolean;
  setData: (value: { [P in keyof T]?: T[P] }) => void;
  reformat: (value: { [P in keyof T]?: T[P] | undefined; }) => void
  clearData: () => void;
  post: (
    url: string,
    options?: {
      validation?: () => Promise<{ isValid: boolean; error: any }>;
      config?: AxiosRequestConfig;
    }
  ) => Promise<AxiosResponse<any>>;
  put: (
    url: string,
    options?: {
      validation?: () => Promise<{ isValid: boolean; error: any }>;
      config?: AxiosRequestConfig;
    }
  ) => Promise<AxiosResponse<any>>;
}

const useForm = <T = any>({ defaultValue }: Props<T>): UseForm<T> => {
  const [data, setData] = useState<{ [P in keyof T]?: T[P] }>(defaultValue);
  const [processing, setProcessing] = useState(false);

  const post = async (url: string, options?: { validation?: () => Promise<{ isValid: boolean; error: any }>; config?: AxiosRequestConfig }) => {
    setProcessing(true);
    if (options && options.validation) {
      const check = await options.validation();
      if (!check.isValid) {
        setProcessing(false);
        throw new Error(JSON.stringify({ errors: check.error }));
      }
    }
    return axios.post(url, data, options?.config).finally(() => setProcessing(false));
  };

  const put = async (url: string, options?: { validation?: () => Promise<{ isValid: boolean; error: any }>; config?: AxiosRequestConfig }) => {
    setProcessing(true);
    if (options && options.validation) {
      const check = await options.validation();
      if (!check.isValid) {
        setProcessing(false);
        throw new Error(JSON.stringify({ errors: check.error }));
      }
    }
    return axios.put(url, data, options?.config).finally(() => setProcessing(false));
  };


  const reformat = (value: { [P in keyof T]?: T[P]}) => {
    setData(state => ({...state, ...value}));
  };

  const clearData = () => {
    setData(defaultValue);
  };

  const value = useMemo(() => data as T, [data]);

  return {
    data: value,
    processing,
    reformat,
    clearData,
    setData: value => setData(state => ({ ...state, ...value })),
    post,
    put
  };
};

export default useForm;
