import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import FileModal from 'components/FileModal';
import CardContent from 'components/CardContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from './style';
interface Props {
  driver: DriverModel;
  isLoadingData: boolean;
}

const DriverInformation: FC<Props> = props => {
  const classes = useStyles();

  const [openFile, setOpenFile] = useState<boolean>(false);
  const [titleFile, setTitleFile] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentFileUrl, setCurrentFileUrl] = useState<string>('');

  const handleOpenVehicleFile = () => {
    setOpenFile(true);
    setTitleFile('Vehicle log card file');
    setCurrentFile(props.driver.vehicleLogCard);
    setCurrentFileUrl(props.driver.vehicleFileUrl || '');
  };

  return (
    <>
      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Personal Information'
        divider={true}
        data={props}
        column={[
          [{ title: 'Driver Name', value: data => data.driver.User.displayName }, { title: 'Email Address', value: data => data.driver.User.email }],
          [{ title: 'Phone No', value: data => data.driver.User.contactNumber }, { title: 'Address', value: data => data.driver.User.address }]
        ]}
      />

      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Vehicle Information'
        divider={false}
        data={props}
        column={[
          [{ title: 'Vehicle No', value: data => data.driver.vehicleNumber }],
          [
            {
              title: 'Vehicle Log Card',
              value: data =>
                data.driver.vehicleLogCard ? (
                  <Button variant='text' color='primary' size='small' className={classes.seeDocument} onClick={handleOpenVehicleFile}>
                    <VisibilityIcon className={classes.icon} /> See Document
                  </Button>
                ) : (
                  ''
                )
            }
          ]
        ]}
      />

      <FileModal title={titleFile} open={openFile} setOpen={setOpenFile} fileUrl={currentFileUrl} fileName={currentFile} />
    </>
  );
};

export default DriverInformation;
