import React, { FC, useRef, useState } from 'react';
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import TableEmpty from 'components/Table/TableEmpty';
import TableLoading from 'components/Table/TableLoading';
import useTablePagination from 'hooks/useTablePagination';
import JobStatus from 'typings/enum/JobStatus';
import JobType from 'typings/enum/JobType';
import * as ApiUrl from 'constants/url';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import PositionedPopper from 'components/PositionedPopper';
import TruckType from 'typings/enum/TruckType';
import { jobTypeLabel, truckTypeLabel, ucwords } from 'utils';

const jobType: Select[] = [
  { id: JobType.DAYWORKS, name: jobTypeLabel(JobType.DAYWORKS) },
  { id: JobType.TRIPWORKS, name: jobTypeLabel(JobType.TRIPWORKS) },
  { id: JobType.OU_WORKS, name: jobTypeLabel(JobType.OU_WORKS) }
];

const taskStatus: Select[] = [
  { id: JobStatus.AVAILABLE, name: ucwords(JobStatus.AVAILABLE) },
  { id: JobStatus.ASSIGNED, name: ucwords(JobStatus.ASSIGNED) },
  { id: JobStatus.IN_PROGRESS, name: ucwords(JobStatus.IN_PROGRESS) },
  { id: JobStatus.COMPLETED, name: ucwords(JobStatus.COMPLETED) },
  { id: JobStatus.CANCELLED, name: ucwords(JobStatus.CANCELLED) },
];

const truckType: Select[] = [
  { id: TruckType.TIPPER, name: truckTypeLabel(TruckType.TIPPER) },
  { id: TruckType.TMA, name: truckTypeLabel(TruckType.TMA) },
  { id: TruckType.LORRY_CRANE, name: truckTypeLabel(TruckType.LORRY_CRANE) },
  { id: TruckType.TRAILER, name: truckTypeLabel(TruckType.TRAILER) }
];

interface Props {
  driverId: number;
}

const JobDriverTable: FC<Props> = ({ driverId }) => {
  const table = useTablePagination<TaskDriver>(ApiUrl.JOBS_TASK_DRIVER_BASE_URL, {
    selector: resp => resp.rows,
    params: {
      did: driverId
    }
  });

  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [dateBy, setDateBy] = useState('');

  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    const [startDate, endDate] = splitted;
    table.setQuery({ startDate, endDate });
    setDateRange(removedTime.join(' ~ '));
  };

  return (
    <TableContainer style={{ boxShadow: 'none' }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            height={'0px'}
            order={table.order}
            orderBy={table.orderBy}
            onRequestSort={(event, property) => table.onOrder(property)}
            headers={[
              { id: 'id', label: 'ID', pT: '16px', pB: '16px' },
              { id: 'companyName', label: 'Job Title/Remark', pT: '16px', pB: '16px' },
              { id: 'type', label: 'Type', align: 'center', pT: '16px', pB: '16px' },
              { id: 'truckType', label: 'Vehicle Type', align: 'center', pT: '16px', pB: '16px' },
              { id: 'time', label: 'Time', align: 'center', pT: '16px', pB: '16px' },
              { id: 'status', label: 'Task Status', pT: '16px', pB: '16px' },
              { id: 'amount', label: 'NET Earn', pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap' } } }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findId',
                isInputText: true,
                value: table.query('id'),
                handleInputChange: value => table.setQuery({ id: value }),
                pT: '0px',
                pB: '0px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                ),
                cellProps: {
                  style: { minWidth: '150px' }
                }
              },
              {
                id: 'findCompanyName',
                isInputText: true,
                value: table.query('companyName'),
                handleInputChange: value => table.setQuery({ companyName: value }),
                pT: '0px',
                pB: '0px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                )
              },
              {
                id: 'findType',
                label: 'Select Type',
                value: table.query('type'),
                isSelect: true,
                selectOption: jobType,
                handleOptionChange: value => table.setQuery({ type: value })
              },
              {
                id: 'findTruckType',
                label: 'Select Type',
                value: table.query('truckType'),
                isSelect: true,
                selectOption: truckType,
                handleOptionChange: value => table.setQuery({ truckType: value }),
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },
              {
                id: 'findJobTime',
                isInputText: true,
                value: dateRange,
                readOnly: true,
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: { minWidth: '200px' }
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendarClick}>
                      <DateRangeIcon />
                    </IconButton>
                  </InputAdornment>
                )
              },

              {
                id: 'findStatus',
                isSelect: true,
                value: table.query('status'),
                selectOption: taskStatus,
                handleOptionChange: value => table.setQuery({ status: value }),
                pT: '0px',
                pB: '0px',
                label: 'Select Status'
              },
              {
                id: 'findAmount',
                isInputText: true,
                value: table.query('amount'),
                handleInputChange: value => table.setQuery({ amount: value }),
                pT: '0px',
                pB: '0px',
                endAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' color='disabled' />
                  </InputAdornment>
                ),
                cellProps: {
                  style: { minWidth: '150px' }
                }
              }
            ]}
          />
        </TableHead>

        <TableBody>
          {table.loading ? (
            <TableLoading col={8} />
          ) : table.data.length > 0 ? (
            table.data.map((value, index) => <BodyRow index={index} key={index} task={value} />)
          ) : (
            <TableEmpty col={8} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={[5, 10, 15]}
              count={table.count}
              rowsPerPage={table.perPage}
              page={table.page}
              onChangePage={table.onPageChange}
              onChangeRowsPerPage={table.onRowsChange}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
      >
        <div>
          <PositionedPopper open={open} anchorEl={anchorEl} placement='bottom' containerWidth={200} fadeTransition={350} keepMounted={open}>
            <DateTimeRangePicker
              mode='date'
              currentValue={dateRange}
              dateBy={dateBy}
              clearRef={clearRef}
              closeRef={closeRef}
              disabledCustomDate={dateBy !== '5'}
              setOpenPopper={setOpen}
              options={[
                { key: '1', label: 'Today' },
                { key: '2', label: 'Tomorrow' },
                { key: '3', label: 'This Week' },
                { key: '4', label: 'This Month' },
                { key: '5', label: 'Custom Date' }
              ]}
              onSelectedChange={value => setDateBy(value)}
              onClear={() => {
                setDateBy('');
                setDateRange('');
              }}
              onChange={handleTimeChange}
            />
          </PositionedPopper>
        </div>
      </ClickAwayListener>
    </TableContainer>
  );
};

export default JobDriverTable;
